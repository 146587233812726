import { captureException, setUser } from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { CacheKey, CacheKeyLookup } from 'components/core/api/cachekeys';
import { shouldSkipContextRequests } from 'components/core/context/common';
import { Profile } from 'components/core/interfaces';
import { useRouter } from 'next/router';
import { useAuthenticatedClient } from 'services/client';
import { queryOptions } from 'services/client';
import { getCurrentProfile, getOriginalProfile } from 'services/profiles/queries';

// TODO: RENAME THIS AWFUL NAME
export const useMe = () => {
  const router = useRouter();
  const options = {
    ...queryOptions,
    enabled: !shouldSkipContextRequests(router),
  };

  const client = useAuthenticatedClient();
  const cacheKey = CacheKeyLookup[CacheKey.ME]();
  const queryCurrentProfile = useQuery<Profile>(cacheKey, () => getCurrentProfile(client), options);

  if (queryCurrentProfile.error) {
    // Note: this will only trigger after 3 retries
    captureException(queryCurrentProfile.error);
  }

  // Set the user to the current profile right after the queryCurrentProfile is made just in case originalUser fails
  setUser({
    id: `${queryCurrentProfile.data?.id}`,
    email: queryCurrentProfile.data?.email,
    // NOTE: we used to set the username field
    // but this value is no longer available via the API
  });

  const originalUserCacheKey = CacheKeyLookup[CacheKey.ORIGINAL_USER]();
  const queryOriginalUser = useQuery<Profile | undefined>(originalUserCacheKey, () => getOriginalProfile(client), options);
  if (queryOriginalUser.error) {
    // note: this will only trigger after 3 retries
    captureException(queryOriginalUser.error);
  }

  // Sets the user to the original user and adds the impersonating info
  if (queryOriginalUser.data) {
    setUser({
      id: `${queryOriginalUser.data.id}`,
      email: queryOriginalUser.data.email,
      impersonating: {
        id: `${queryCurrentProfile.data?.id}`,
        email: queryCurrentProfile.data?.email,
      },
      // NOTE: we used to set the username field
      // but this value is no longer available via the API
    });
  }

  return { profile: queryCurrentProfile, actualProfile: queryOriginalUser };
};
