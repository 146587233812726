import { useMe } from 'components/core/api/queries/getMe';
import { LDContext, LDProvider } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';

export const LaunchDarklyProvider = ({ children }: { children: ReactNode }) => {
  const { profile: user } = useMe();
  const launchDarklyClientId = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID || 'clientId not found';

  // Because `user` may not be available on the first render, we need to set an initial context
  // See more about `anonymous` contexts here: https://docs.launchdarkly.com/home/observability/anonymous-contexts
  const initialContext: LDContext = {
    kind: 'user',
    anonymous: true,
  };

  const context: LDContext = {};

  return (
    <LDProvider clientSideID={launchDarklyClientId} context={user.data ? context : initialContext}>
      {children}
    </LDProvider>
  );
};
