import { WelcomeThemeType } from 'components/core/context/ThemeContext';

const tailwindcssColors = require('tailwindcss/colors');

export type ThemeNames = 'default' | 'neutral' | 'earth' | 'owl';
export type ColorSections = 'salary' | 'equity' | 'signing_bonus' | 'benefits';
export type ColorSubSections = 'background' | 'text' | 'bar';
export type ColorsKey = ColorSections | `${ColorSections}_${ColorSubSections}`;
// TODO - Flesh out this type
export type DaisyUIColors = 'primary' | 'secondary' | 'accent';
export type DaisyUIThemes = Record<ThemeNames, Record<string | DaisyUIColors, string>>;

export type ColorsType = Record<ColorsKey, string>;

export const colors: Record<ThemeNames, ColorsType> = {
  default: {
    salary: 'rgba(173, 163, 255,0.5)',
    salary_background: 'rgba(173, 163, 255,1)',
    salary_text: 'rgba(173, 163, 255,1)',
    salary_bar: 'rgba(173, 163, 255,1)',

    equity: 'rgba(128, 214, 158,0.5)',
    equity_background: 'rgba(128, 214, 158,1)',
    equity_text: 'rgba(128, 214, 158,1)',
    equity_bar: 'rgba(128, 214, 158,1)',

    signing_bonus: 'rgba(255, 159, 159,0.5)',
    signing_bonus_background: 'rgba(255, 159, 159,1)',
    signing_bonus_text: 'rgba(255, 159, 159,1)',
    signing_bonus_bar: 'rgba(255, 159, 159,1)',

    benefits: 'rgba(147, 197, 253,0.5)',
    benefits_background: 'rgba(147, 197, 253, 1)',
    benefits_text: 'rgba(147, 197, 253,1)',
    benefits_bar: 'rgba(147, 197, 253,1)',
  },
  neutral: {
    salary: 'rgba(105, 106, 109, 0.5)',
    salary_background: 'rgba(105, 106, 109, 1)',
    salary_text: 'rgba(105, 106, 109, 1)',
    salary_bar: 'rgba(105, 106, 109, 1)',

    equity: 'rgba(43, 46, 50, 0.5)',
    equity_background: 'rgba(43, 46, 50, 1)',
    equity_text: 'rgba(43, 46, 50, 1)',
    equity_bar: 'rgba(43, 46, 50, 1)',

    signing_bonus: 'rgba(191, 193, 194, 0.5)',
    signing_bonus_background: 'rgba(191, 193, 194, 1)',
    signing_bonus_text: 'rgba(191, 193, 194, 1)',
    signing_bonus_bar: 'rgba(191, 193, 194, 1)',

    benefits: 'rgba(228, 229, 229, 0.5)',
    benefits_background: 'rgba(228, 229, 229, 1)',
    benefits_text: 'rgba(228, 229, 229, 1)',
    benefits_bar: 'rgba(228, 229, 229, 1)',
  },
  earth: {
    salary: 'rgba(113, 31, 80, 0.5)',
    salary_background: 'rgba(113, 31, 80, 1)',
    salary_text: 'rgba(113, 31, 80, 1)',
    salary_bar: 'rgba(113, 31, 80, 1)',

    equity: 'rgba(235, 144, 144, 0.5)',
    equity_background: 'rgba(235, 144, 144, 1)',
    equity_text: 'rgba(235, 144, 144, 1)',
    equity_bar: 'rgba(235, 144, 144, 1)',

    signing_bonus: 'rgba(105, 171, 147, 0.5)',
    signing_bonus_background: 'rgba(105, 171, 147, 1)',
    signing_bonus_text: 'rgba(105, 171, 147, 1)',
    signing_bonus_bar: 'rgba(105, 171, 147, 1)',

    benefits: 'rgba(55, 89, 77, 0.5)',
    benefits_background: 'rgba(55, 89, 77, 1)',
    benefits_text: 'rgba(55, 89, 77, 1)',
    benefits_bar: 'rgba(55, 89, 77, 1)',
  },
  // OWL organization specific color set
  owl: {
    salary: 'rgba(237,23,51,0.4)',
    salary_background: 'rgba(0, 0, 0, 1)',
    salary_text: 'rgba(0, 0, 0, 1)',
    salary_bar: 'rgba(237,23,51,0.4)',

    equity: 'rgba(237,23,51,0.5)',
    equity_background: 'rgba(0, 0, 0, 1)',
    equity_text: 'rgba(0, 0, 0, 1)',
    equity_bar: 'rgba(237,23,51,0.5)',

    signing_bonus: 'rgba(237,23,51,0.6)',
    signing_bonus_background: 'rgba(0, 0, 0, 1)',
    signing_bonus_text: 'rgba(0, 0, 0, 1)',
    signing_bonus_bar: 'rgba(237,23,51,0.6)',

    benefits: 'rgba(237,23,51,0.6)',
    benefits_background: 'rgba(0, 0, 0, 1)',
    benefits_text: 'rgba(0, 0, 0, 1)',
    benefits_bar: 'rgba(237,23,51,0.6)',
  },
};

// all of the themes listed here need to be included in the safelist.txt file
// at the root of the repository.
export const welcomeThemes: Record<string, WelcomeThemeType> = {
  default: {
    welcome: 'bg-gradient-to-b from-violet-800 to-violet-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  neutral: {
    welcome: 'bg-complete-black-600',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-complete-gray-800 to-complete-black-600',
    scenario_card: 'bg-gradient-to-r from-complete-gray-600 to-complete-blue-400',
    scenario_card_divider: 'bg-gradient-to-r from-complete-gray-700 to-complete-blue-600',
  },
  earth: {
    welcome: 'bg-gradient-to-r from-complete-green-600 to-complete-green-500',
    offer_page: 'bg-gradient-to-b from-complete-white-100 via-complete-white-100 via-complete-white-100 to-complete-green-500',
    scenario_card: 'bg-gradient-to-r from-complete-red-200 to-complete-yellow-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-red-300 to-complete-yellow-200',
  },
  blue: {
    welcome: 'bg-gradient-to-b from-blue-800 to-blue-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  blueB: {
    welcome: 'bg-gradient-to-b from-[#0029F5] to-blue-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  cyan: {
    welcome: 'bg-gradient-to-b from-cyan-800 to-cyan-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  red: {
    welcome: 'bg-gradient-to-b from-red-800 to-red-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  peach: {
    welcome: 'bg-gradient-to-b from-red-200 to-red-50',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  green: {
    welcome: 'bg-gradient-to-b from-emerald-800 to-emerald-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  purple: {
    welcome: 'bg-gradient-to-b from-violet-800 to-violet-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  gray: {
    welcome: 'bg-gradient-to-b from-gray-800 to-gray-500',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  pink: {
    welcome: 'bg-gradient-to-b from-pink-600 to-pink-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
  orange: {
    welcome: 'bg-gradient-to-b from-orange-600 to-orange-400',
    offer_page: 'bg-gradient-to-b from-complete-gray-100 via-complete-gray-100 via-short-blue to-short-purple',
    scenario_card: 'bg-gradient-to-r from-complete-green-200 to-complete-green-100',
    scenario_card_divider: 'bg-gradient-to-r from-complete-green-300 to-complete-blue-300',
  },
};

export const adminColors = {
  'admin-primary': tailwindcssColors.violet,
  'admin-accent': tailwindcssColors.indigo,
  'admin-info': tailwindcssColors.blue,
  'admin-success': tailwindcssColors.green,
  'admin-celebration': tailwindcssColors.amber,
  'admin-warning': tailwindcssColors.orange,
  'admin-danger': tailwindcssColors.red,
  'admin-accent-secondary': tailwindcssColors.pink,
};
