export enum CacheKey {
  BENEFITS = 'BENEFITS',
  BONUS = 'BONUS',
  BONUS_LIST = 'BONUSES_LIST',
  CHANGELOG = 'CHANGELOG',
  // TODO: Update to COMPETING_OFFER_LIST
  COMPETING_OFFERS = 'COMPETING_OFFERS',
  DOCUMENT_TEMPLATE_LIST = 'DOCUMENT_TEMPLATE_LIST',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEE_LIST = 'EMPLOYEE_LIST',
  GENERIC_MESSAGE = 'GENERIC_MESSAGE',
  NOTIFICATION_CONFIG = 'NOTIFICATION_CONFIG',
  OFFER = 'OFFER',
  OFFER_SHALLOW = 'OFFER_SHALLOW',
  OFFER_GQL = 'OFFER_GQL',
  OFFER_GQL_TEAM = 'OFFER_GQL_TEAM',
  OFFER_TEAM = 'OFFER_TEAM',
  OFFER_ANALYTICS = 'OFFER_ANALYTICS',
  OFFER_LIST = 'OFFER_LIST',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_SHALLOW = 'ORGANIZATION_SHALLOW',
  ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS',
  ORGANIZATION_USERS = 'ORGANIZATION_USERS',
  ME = 'ME',
  ORIGINAL_USER = 'ORIGINAL_USER',
  // TODO: Update to ROLE_LIST
  ROLES = 'ROLES',
  ROLE = 'ROLE',
  LINK_PREVIEWS = 'LINK_PREVIEWS',
  TEMPLATES = 'TEMPLATES',
}

export const CacheKeyLookup = {
  [CacheKey.BENEFITS]: () => [CacheKey.BENEFITS],
  [CacheKey.BONUS]: ({ id }: { id: number }) => [CacheKey.BONUS, id],
  [CacheKey.BONUS_LIST]: () => [CacheKey.BONUS_LIST],
  [CacheKey.CHANGELOG]: () => [CacheKey.CHANGELOG],
  [CacheKey.COMPETING_OFFERS]: ({ offerId }: { offerId: number }) => [CacheKey.COMPETING_OFFERS, offerId],
  [CacheKey.DOCUMENT_TEMPLATE_LIST]: () => [CacheKey.DOCUMENT_TEMPLATE_LIST],
  [CacheKey.EMPLOYEE]: ({ profileId }: { profileId: string }) => [CacheKey.EMPLOYEE, profileId],
  [CacheKey.EMPLOYEE_LIST]: () => [CacheKey.EMPLOYEE_LIST],
  [CacheKey.GENERIC_MESSAGE]: () => [CacheKey.GENERIC_MESSAGE],
  [CacheKey.NOTIFICATION_CONFIG]: () => [CacheKey.NOTIFICATION_CONFIG],
  [CacheKey.OFFER]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER, uuid],
  [CacheKey.OFFER_SHALLOW]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER_SHALLOW, uuid],
  // should consolidate OFFER_GQL and OFFER_GQL_TEAM
  [CacheKey.OFFER_GQL]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER_GQL, uuid],
  [CacheKey.OFFER_GQL_TEAM]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER_GQL_TEAM, uuid],
  [CacheKey.OFFER_TEAM]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER_TEAM, uuid],
  [CacheKey.OFFER_ANALYTICS]: ({ uuid }: { uuid: string }) => [CacheKey.OFFER_ANALYTICS, uuid],
  [CacheKey.OFFER_LIST]: () => [CacheKey.OFFER_LIST],
  [CacheKey.ORGANIZATION]: () => [CacheKey.ORGANIZATION],
  [CacheKey.ORGANIZATION_SHALLOW]: () => [CacheKey.ORGANIZATION_SHALLOW],
  [CacheKey.ORGANIZATION_SETTINGS]: () => [CacheKey.ORGANIZATION_SETTINGS],
  [CacheKey.ORGANIZATION_USERS]: () => [CacheKey.ORGANIZATION_USERS],
  [CacheKey.ME]: () => [CacheKey.ME],
  [CacheKey.ORIGINAL_USER]: () => [CacheKey.ORIGINAL_USER],
  [CacheKey.ROLES]: () => [CacheKey.ROLES],
  [CacheKey.ROLE]: (id: number) => [CacheKey.ROLE, String(id)],
  [CacheKey.LINK_PREVIEWS]: () => [CacheKey.LINK_PREVIEWS],
  [CacheKey.TEMPLATES]: () => [CacheKey.TEMPLATES],
};
