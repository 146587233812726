import { datadogRum } from '@datadog/browser-rum';
import { useMe } from 'components/core/api/queries/getMe';
import { shouldSkipContextRequests } from 'components/core/context/common';
import { ADMIN_GROUP } from 'components/core/enums';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export interface AuthenticationContextType {
  isAuthenticated: boolean;
  isCompensationPlanAdmin?: boolean;
}

const defaultState = {
  isAuthenticated: false,
  isCompensationPlanAdmin: false,
};

export const AuthenticationContext = createContext<AuthenticationContextType>(defaultState);

export const AuthenticationContextProvider = ({ children }: { children: ReactNode }) => {
  const isDisabledRoute = shouldSkipContextRequests(useRouter());
  const [currentAuthentication, setCurrentAuthentication] = useState<AuthenticationContextType>(defaultState);
  const { profile: user } = useMe();

  useEffect(() => {
    if (isDisabledRoute) {
      return;
    }
    if (user.isSuccess && !currentAuthentication.isAuthenticated) {
      setCurrentAuthentication({
        isAuthenticated: true,
        // TODO: Swap this out with a more specific permission check
        // We have generally checked whether the requesting user has the
        // 'ADD_SCENARIO_PERM' permission on the backend. In the name of
        // expediency, we're just checking whether the user is in the ADMIN_GROUP
        // group.
        isCompensationPlanAdmin: user.data?.group === ADMIN_GROUP,
      });
    }
  }, [currentAuthentication.isAuthenticated, isDisabledRoute, user]);

  // This isn't necessarily the best place to put this, but it works!
  // When we initialize the LaunchDarklyProvider, DatadogRum, etc., we don't
  // have the user's data yet, so we need to wait until we do and then identify.
  const ldClient = useLDClient();
  useEffect(() => {
    if (!user.data) return;
    const { id, email, organization, group } = user.data;
    if (ldClient && ldClient.getContext().anonymous) {
      ldClient?.identify({
        kind: 'user',
        key: id.toString(),
        email: email,
        organization: organization.identifier,
      });
    }
    datadogRum.setUser({
      id: id.toString(),
      email: email,
      organization: organization.identifier,
      group: group,
    });
  }, [user.data, ldClient]);

  return <AuthenticationContext.Provider value={currentAuthentication}>{children}</AuthenticationContext.Provider>;
};

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}
