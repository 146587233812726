module.exports = {
    "scalars": [
        2,
        5,
        8,
        11,
        17,
        22,
        43,
        47,
        50,
        61,
        91,
        92,
        99,
        108,
        111,
        113,
        126,
        152,
        154,
        160,
        161,
        162,
        163,
        165,
        195,
        236,
        253,
        254,
        310,
        327,
        330,
        339,
        340,
        356,
        362,
        393,
        402,
        405,
        419,
        420,
        440,
        459,
        498,
        512
    ],
    "types": {
        "AdditionalStructuredItemsInput": {
            "compensationPlanning": [
                105
            ],
            "__typename": [
                8
            ]
        },
        "AggregateReferenceInput": {
            "benchmarkImportId": [
                459
            ],
            "weight": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "Float": {},
        "AggregateReferenceType": {
            "weight": [
                2
            ],
            "benchmarkImport": [
                41
            ],
            "__typename": [
                8
            ]
        },
        "ApplicationType": {
            "id": [
                459
            ],
            "job": [
                264
            ],
            "offer": [
                287
            ],
            "__typename": [
                8
            ]
        },
        "ApproversSetting": {},
        "ArchiveOfferTemplatePayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "AssignIneligibilityReasonsInput": {
            "ineligibleEmployees": [
                211
            ],
            "scenarioId": [
                459
            ],
            "reasons": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "String": {},
        "AssignIneligibilityReasonsPayload": {
            "on_AssignIneligibilityReasonsResponse": [
                10
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "AssignIneligibilityReasonsResponse": {
            "scenarioId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "AuditLogAction": {},
        "AuditLogActor": {
            "displayName": [
                8
            ],
            "profile": [
                365
            ],
            "__typename": [
                8
            ]
        },
        "AuditLogIndividualUpdate": {
            "fieldVal": [
                8
            ],
            "oldVal": [
                8
            ],
            "newVal": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "AuditLogSummary": {
            "items": [
                15
            ],
            "__typename": [
                8
            ]
        },
        "AuditLogSummaryItem": {
            "actor": [
                12
            ],
            "action": [
                11
            ],
            "timestamp": [
                163
            ],
            "contextValue": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "AuditLogType": {
            "id": [
                17
            ],
            "recordId": [
                459
            ],
            "oldRecordId": [
                459
            ],
            "op": [
                8
            ],
            "ts": [
                163
            ],
            "tableOid": [
                17
            ],
            "tableSchema": [
                8
            ],
            "tableName": [
                8
            ],
            "record": [
                254
            ],
            "oldRecord": [
                254
            ],
            "auditUpdate": [
                18
            ],
            "authUid": [
                459
            ],
            "authRole": [
                459
            ],
            "profile": [
                365
            ],
            "__typename": [
                8
            ]
        },
        "Int": {},
        "AuditLogUpdateType": {
            "description": [
                8
            ],
            "title": [
                8
            ],
            "ts": [
                163
            ],
            "userId": [
                459
            ],
            "updateType": [
                8
            ],
            "values": [
                13
            ],
            "__typename": [
                8
            ]
        },
        "AuthUserInfoType": {
            "profileId": [
                17
            ],
            "lastSignInAt": [
                163
            ],
            "lastActiveAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "BandInputIdentifier": {
            "id": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "BandInputPartialType": {
            "levelId": [
                17
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "isDraftBand": [
                22
            ],
            "locations": [
                273
            ],
            "__typename": [
                8
            ]
        },
        "Boolean": {},
        "BandInputType": {
            "id": [
                459
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "minInCents": [
                17
            ],
            "targetInCents": [
                17
            ],
            "maxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "description": [
                8
            ],
            "locations": [
                273
            ],
            "targetPercentage": [
                165
            ],
            "hiringRange": [
                247
            ],
            "__typename": [
                8
            ]
        },
        "BandInputTypeListInput": {
            "set": [
                23
            ],
            "add": [
                23
            ],
            "remove": [
                23
            ],
            "__typename": [
                8
            ]
        },
        "BandLocationSearchFilter": {
            "location": [
                272
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BandLocationSearchType": {
            "bands": [
                28
            ],
            "__typename": [
                8
            ]
        },
        "BandTargetCompType": {
            "target": [
                17
            ],
            "currencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BandType": {
            "id": [
                459
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "currencyCode": [
                8
            ],
            "locations": [
                240
            ],
            "targetPercentage": [
                165
            ],
            "minInCents": [
                17
            ],
            "targetInCents": [
                17
            ],
            "maxInCents": [
                17
            ],
            "description": [
                8
            ],
            "hiringRange": [
                248
            ],
            "__typename": [
                8
            ]
        },
        "BandTypeV3": {
            "id": [
                459
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "minInCents": [
                17
            ],
            "targetInCents": [
                17
            ],
            "maxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "targetPercentage": [
                17
            ],
            "description": [
                8
            ],
            "locations": [
                240
            ],
            "hiringRange": [
                248
            ],
            "__typename": [
                8
            ]
        },
        "BandV2Type": {
            "id": [
                459
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "minInCents": [
                17
            ],
            "targetInCents": [
                17
            ],
            "maxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "description": [
                8
            ],
            "locations": [
                240
            ],
            "targetPercentage": [
                17
            ],
            "hiringRange": [
                248
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDataPercentile": {
            "percentile": [
                17
            ],
            "value": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDataPercentileInput": {
            "percentile": [
                17
            ],
            "value": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDistribution": {
            "type": [
                43
            ],
            "distribution": [
                31
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDistributionInput": {
            "type": [
                43
            ],
            "distribution": [
                32
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDistributions": {
            "distributions": [
                33
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkDistributionsInput": {
            "distributions": [
                34
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkFilters": {
            "location": [
                8
            ],
            "capitalRaised": [
                8
            ],
            "numEmployees": [
                8
            ],
            "valuation": [
                8
            ],
            "revenue": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkImportFilterUpdateInput": {
            "flatfileImportId": [
                459
            ],
            "filters": [
                39
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkImportFilters": {
            "location": [
                8
            ],
            "capitalRaised": [
                8
            ],
            "numEmployees": [
                8
            ],
            "valuation": [
                8
            ],
            "revenue": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkImportInputIdentifier": {
            "id": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkImportType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "organization": [
                194
            ],
            "createdAt": [
                163
            ],
            "importedAt": [
                163
            ],
            "dataSource": [
                8
            ],
            "benchmarkDistributions": [
                35
            ],
            "jobFamily": [
                8
            ],
            "jobTitle": [
                8
            ],
            "jobLevel": [
                8
            ],
            "currencyCode": [
                8
            ],
            "metadata": [
                42
            ],
            "filters": [
                37
            ],
            "importSource": [
                237
            ],
            "aggregateReferences": [
                3
            ],
            "isAggregate": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkMetadata": {
            "notes": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BenchmarkType": {},
        "BenefitPackageEditInputPartial": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "benefits": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "BenefitPackageInput": {
            "name": [
                8
            ],
            "organization": [
                17
            ],
            "benefits": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "BenefitPackageType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "benefits": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "BonusCategory": {},
        "BonusInput": {
            "id": [
                17
            ],
            "organizationId": [
                17
            ],
            "title": [
                8
            ],
            "type": [
                8
            ],
            "valueCents": [
                17
            ],
            "payoutFrequency": [
                8
            ],
            "description": [
                8
            ],
            "detailDescription": [
                8
            ],
            "bonusCurrency": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BonusType": {
            "id": [
                17
            ],
            "organization": [
                350
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "deletedAt": [
                163
            ],
            "type": [
                8
            ],
            "title": [
                8
            ],
            "payoutFrequency": [
                8
            ],
            "description": [
                8
            ],
            "detailDescription": [
                8
            ],
            "valueCents": [
                17
            ],
            "bonusCurrency": [
                8
            ],
            "offer": [
                324
            ],
            "__typename": [
                8
            ]
        },
        "BudgetUnitDescriptor": {},
        "BulkEmployeeCompaRatios": {
            "employeeCompaRatios": [
                208
            ],
            "__typename": [
                8
            ]
        },
        "BulkEmployeeCompaRatiosInput": {
            "employeeCompaRatios": [
                209
            ],
            "__typename": [
                8
            ]
        },
        "BulkPublishLevelsResponse": {
            "successfullyPublished": [
                17
            ],
            "successfullyDeleted": [
                17
            ],
            "errors": [
                367
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdateEmployeesInput": {
            "employeeIds": [
                459
            ],
            "objectValueUuid": [
                459
            ],
            "objectValueInt": [
                17
            ],
            "objectValueString": [
                8
            ],
            "objectType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdateEmployeesResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdatePayGeoInput": {
            "employeeIds": [
                459
            ],
            "location": [
                272
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdatePayGeoResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdateScenarioEmployeeApproversPayload": {
            "on_ScenarioEmployeeApproverBulkResponse": [
                407
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "BulkUpdateTotalRewardsStatementPayload": {
            "on_TotalRewardsStatementInputPartialBulkResponse": [
                455
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CandidateMessageInput": {
            "offerId": [
                17
            ],
            "kind": [
                61
            ],
            "user": [
                64
            ],
            "message": [
                63
            ],
            "__typename": [
                8
            ]
        },
        "CandidateMessageKind": {},
        "CandidateMessageResponse": {
            "message": [
                291
            ],
            "__typename": [
                8
            ]
        },
        "CandidateMessageType": {
            "id": [
                17
            ],
            "content": [
                8
            ],
            "videoUrl": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CandidateMessageUserType": {
            "organizationId": [
                17
            ],
            "id": [
                17
            ],
            "group": [
                8
            ],
            "fullName": [
                8
            ],
            "title": [
                8
            ],
            "email": [
                8
            ],
            "imagePath": [
                8
            ],
            "links": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CandidateOfferAcceptInput": {
            "offerUuid": [
                459
            ],
            "onboarding": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CandidateOfferAcceptResponse": {
            "state": [
                327
            ],
            "__typename": [
                8
            ]
        },
        "CandidateType": {
            "id": [
                459
            ],
            "remoteId": [
                8
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "company": [
                8
            ],
            "title": [
                8
            ],
            "isPrivate": [
                22
            ],
            "canEmail": [
                22
            ],
            "locations": [
                8
            ],
            "phoneNumbers": [
                358
            ],
            "urls": [
                458
            ],
            "tags": [
                8
            ],
            "attachments": [
                459
            ],
            "emailAddresses": [
                204
            ],
            "applications": [
                4
            ],
            "remoteCreatedAt": [
                163
            ],
            "remoteUpdatedAt": [
                163
            ],
            "lastInteractionAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "CartaAccessTokenInput": {
            "state": [
                459
            ],
            "code": [
                8
            ],
            "redirectUri": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CartaAccessTokenSuccess": {
            "message": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CartaAuthorizationData": {
            "authUrl": [
                8
            ],
            "state": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "CartaAuthorizationInput": {
            "redirectUri": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CityInputType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CityType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "country": [
                130
            ],
            "__typename": [
                8
            ]
        },
        "CityTypeCountryTypeContinentType": {
            "on_CityType": [
                73
            ],
            "on_CountryType": [
                130
            ],
            "on_ContinentType": [
                128
            ],
            "on_Place": [
                359
            ],
            "__typename": [
                8
            ]
        },
        "CommentCreateInput": {
            "employeeId": [
                459
            ],
            "content": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CommentDeleteInput": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CommentUpdateInput": {
            "id": [
                17
            ],
            "content": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CommissionScenarioInputPartial": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "revenueCents": [
                17
            ],
            "commissionCents": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CommissionScenarioInputPartialRelatedObjectInput": {
            "add": [
                78
            ],
            "set": [
                78
            ],
            "remove": [
                78
            ],
            "__typename": [
                8
            ]
        },
        "CommissionScenarioType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "revenueCents": [
                17
            ],
            "commissionCents": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CommissionStructureInput": {
            "name": [
                8
            ],
            "externalLink": [
                8
            ],
            "description": [
                8
            ],
            "descriptionDetail": [
                8
            ],
            "organization": [
                17
            ],
            "onTargetCommissionCents": [
                17
            ],
            "commissionScenarios": [
                78
            ],
            "__typename": [
                8
            ]
        },
        "CommissionStructureInputIdentifier": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CommissionStructureInputPartial": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "externalLink": [
                8
            ],
            "description": [
                8
            ],
            "descriptionDetail": [
                8
            ],
            "onTargetCommissionCents": [
                17
            ],
            "commissionScenarios": [
                79
            ],
            "__typename": [
                8
            ]
        },
        "CommissionStructureType": {
            "id": [
                17
            ],
            "organization": [
                350
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "name": [
                8
            ],
            "externalLink": [
                8
            ],
            "description": [
                8
            ],
            "descriptionDetail": [
                8
            ],
            "onTargetCommissionCents": [
                17
            ],
            "commissionScenarios": [
                80
            ],
            "__typename": [
                8
            ]
        },
        "CompChartRangeLimit": {
            "startYear": [
                17
            ],
            "endYear": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompChartRangeLimitInput": {
            "startYear": [
                17
            ],
            "endYear": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompPlanConfig": {
            "reasons": [
                8
            ],
            "customBudgetSetting": [
                152
            ],
            "approversSetting": [
                5
            ],
            "allEligible": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "CompaRatioInput": {
            "employeeId": [
                459
            ],
            "levelId": [
                17
            ],
            "bandType": [
                8
            ],
            "bandName": [
                8
            ],
            "payGeoLocationId": [
                459
            ],
            "currentSalaryInCents": [
                17
            ],
            "adjustmentSalaryRateInCents": [
                17
            ],
            "salaryPayPeriod": [
                8
            ],
            "currencyCode": [
                8
            ],
            "employeeStakeholderUuid": [
                459
            ],
            "securityUuids": [
                459
            ],
            "equityUnits": [
                17
            ],
            "adjustmentEquityShares": [
                17
            ],
            "currentVariableComp": [
                17
            ],
            "adjustmentVariableComp": [
                17
            ],
            "currentBonus": [
                17
            ],
            "adjustmentBonus": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompaRatioResponse": {
            "employeeId": [
                459
            ],
            "jobFamilyName": [
                8
            ],
            "levelName": [
                8
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "min": [
                17
            ],
            "max": [
                17
            ],
            "target": [
                17
            ],
            "targetPercentage": [
                165
            ],
            "midpoint": [
                2
            ],
            "compaRatio": [
                2
            ],
            "adjustedCompaRatio": [
                2
            ],
            "currencyCode": [
                8
            ],
            "payGeoLocation": [
                240
            ],
            "payRateInCents": [
                17
            ],
            "adjustmentRateInCents": [
                17
            ],
            "targetVariableComp": [
                2
            ],
            "roleChangeCompaRatioData": [
                383
            ],
            "__typename": [
                8
            ]
        },
        "CompensationBonusType": {
            "id": [
                459
            ],
            "organization": [
                194
            ],
            "employee": [
                194
            ],
            "amountCents": [
                17
            ],
            "bonusCategory": [
                47
            ],
            "effectiveDate": [
                163
            ],
            "description": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioAccessType": {},
        "CompensationChangeScenarioAdjustmentType": {},
        "CompensationChangeScenarioContributor": {
            "profile": [
                365
            ],
            "accessType": [
                91
            ],
            "isAdmin": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioInputType": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "notes": [
                8
            ],
            "financialYear": [
                17
            ],
            "financialQuarter": [
                17
            ],
            "salaryBudgetCents": [
                2
            ],
            "equityBudgetShares": [
                2
            ],
            "state": [
                8
            ],
            "deadline": [
                163
            ],
            "implementedAt": [
                163
            ],
            "archivedAt": [
                163
            ],
            "effectiveDate": [
                163
            ],
            "reasons": [
                8
            ],
            "approversSetting": [
                5
            ],
            "customBudgetSetting": [
                152
            ],
            "allEligible": [
                22
            ],
            "launchedAt": [
                163
            ],
            "adminMessage": [
                8
            ],
            "cycleClosedAt": [
                163
            ],
            "adjustmentsImportedAt": [
                163
            ],
            "shareWithManagers": [
                22
            ],
            "meritMatrixId": [
                459
            ],
            "suggestedAdjustmentType": [
                440
            ],
            "promotionTargetPercent": [
                17
            ],
            "performanceRatingSchemeId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioInviteInput": {
            "id": [
                459
            ],
            "profileId": [
                17
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioInvitePayload": {
            "on_CompensationChangeScenarioInviteResponse": [
                97
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioInviteResponse": {
            "compensationChangeScenario": [
                98
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeScenarioType": {
            "uuid": [
                459
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "organization": [
                350
            ],
            "state": [
                8
            ],
            "name": [
                8
            ],
            "notes": [
                8
            ],
            "financialYear": [
                17
            ],
            "financialQuarter": [
                17
            ],
            "salaryBudgetCents": [
                2
            ],
            "equityBudgetShares": [
                2
            ],
            "deletedAt": [
                163
            ],
            "deadline": [
                163
            ],
            "implementedAt": [
                163
            ],
            "archivedAt": [
                163
            ],
            "effectiveDate": [
                163
            ],
            "compPlanConfig": [
                87
            ],
            "launchedAt": [
                163
            ],
            "adminMessage": [
                8
            ],
            "cycleClosedAt": [
                163
            ],
            "adjustmentsImportedAt": [
                163
            ],
            "shareWithManagers": [
                22
            ],
            "vestingSchedule": [
                511
            ],
            "exchangeRates": [
                232
            ],
            "hasEquityBudget": [
                22
            ],
            "hasVariableCommission": [
                22
            ],
            "sharedWith": [
                93
            ],
            "approver": [
                395
            ],
            "__typename": [
                8
            ]
        },
        "CompensationChangeUnitType": {},
        "CompensationPackageBonusType": {
            "id": [
                17
            ],
            "createdAt": [
                163
            ],
            "bonus": [
                49
            ],
            "compensationPackage": [
                100
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPackageInput": {
            "id": [
                17
            ],
            "annualSalaryCents": [
                17
            ],
            "equityUnitType": [
                8
            ],
            "equityValueCents": [
                2
            ],
            "equityPercentBps": [
                17
            ],
            "equityUnits": [
                17
            ],
            "cashCurrency": [
                8
            ],
            "vestingSchedule": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "commissionStructure": [
                17
            ],
            "hourlyWageCents": [
                17
            ],
            "commissionCents": [
                17
            ],
            "commissionCurrency": [
                8
            ],
            "commissionSplit": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPackageType": {
            "id": [
                17
            ],
            "organization": [
                350
            ],
            "offer": [
                324
            ],
            "commissionStructure": [
                84
            ],
            "bonuses": [
                100
            ],
            "bonus": [
                49
            ],
            "isDefault": [
                22
            ],
            "index": [
                17
            ],
            "name": [
                8
            ],
            "internalName": [
                8
            ],
            "description": [
                8
            ],
            "annualSalaryCents": [
                17
            ],
            "signingBonusCents": [
                17
            ],
            "equityPercentBps": [
                17
            ],
            "equityUnits": [
                17
            ],
            "equityUnitType": [
                8
            ],
            "equityValueCents": [
                2
            ],
            "annualBonusPercentBps": [
                17
            ],
            "bonusValueCents": [
                17
            ],
            "bonusDescription": [
                8
            ],
            "bonusDescriptionDetail": [
                8
            ],
            "cashCurrency": [
                8
            ],
            "vestingSchedule": [
                8
            ],
            "bonusTitle": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "hourlyWageCents": [
                17
            ],
            "commissionCents": [
                17
            ],
            "commissionCurrency": [
                8
            ],
            "commissionSplit": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPhilosophyInput": {
            "philosophy": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPhilosophyType": {
            "uuid": [
                459
            ],
            "publicUuid": [
                459
            ],
            "philosophy": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPlanningAdditionalStructuredItemsInput": {
            "successionPlan": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPlanningColumnPreset": {
            "customColumns": [
                158
            ],
            "defaultColumns": [
                158
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPlanningCustomColumnData": {
            "verboseTenureDisplay": [
                8
            ],
            "successionPlan": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CompensationPlanningCustomColumns": {},
        "CompensationScenarioAdjustment": {
            "createdAt": [
                163
            ],
            "adjustmentType": [
                92
            ],
            "cohort": [
                115
            ],
            "salaryChange": [
                17
            ],
            "equityChange": [
                17
            ],
            "vestingSchedule": [
                511
            ],
            "equityChangeValue": [
                17
            ],
            "bonusCents": [
                17
            ],
            "salaryChangeUnit": [
                99
            ],
            "equityChangeUnit": [
                99
            ],
            "equityUnitType": [
                111
            ],
            "vestingStartDate": [
                163
            ],
            "variableCommissionChange": [
                17
            ],
            "variableCommissionJson": [
                509
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioAdjustmentV8": {
            "cohortId": [
                459
            ],
            "createdAt": [
                163
            ],
            "compType": [
                8
            ],
            "changeAmount": [
                17
            ],
            "variableCompPayPeriod": [
                8
            ],
            "variableCompEndDate": [
                163
            ],
            "variableCompPaymentType": [
                8
            ],
            "equityUnitType": [
                8
            ],
            "vestingScheduleId": [
                459
            ],
            "vestingStartDate": [
                163
            ],
            "vestingDescription": [
                8
            ],
            "vestingDuration": [
                17
            ],
            "vestingFrequency": [
                8
            ],
            "hasCliff": [
                22
            ],
            "vestingEvents": [
                2
            ],
            "securityVestingEvents": [
                427
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioCohortEquityUnitType": {},
        "CompensationScenarioCohortInputType": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "scenario": [
                459
            ],
            "members": [
                461
            ],
            "salaryChangeCount": [
                17
            ],
            "salaryChangeBps": [
                17
            ],
            "equityChangeCount": [
                17
            ],
            "equityChangeBps": [
                17
            ],
            "vestingSchedule": [
                8
            ],
            "bonusCents": [
                17
            ],
            "policyNotes": [
                8
            ],
            "kind": [
                113
            ],
            "equityUnitType": [
                111
            ],
            "vestingStartDate": [
                163
            ],
            "variableCommissionJson": [
                508
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioCohortKind": {},
        "CompensationScenarioCohortMember": {
            "uuid": [
                459
            ],
            "employee": [
                222
            ],
            "currentYearlySalaryCents": [
                17
            ],
            "currentTotalEquityShares": [
                17
            ],
            "proposedAdjustments": [
                109
            ],
            "proposedTotalYearlySalaryCents": [
                17
            ],
            "proposedTotalEquityShares": [
                17
            ],
            "customColumns": [
                107
            ],
            "currentVariableCommissionCents": [
                17
            ],
            "newVariableCommissionCents": [
                17
            ],
            "employeeCompaRatios": [
                208
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioCohortType": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "scenario": [
                98
            ],
            "members": [
                222,
                {
                    "filters": [
                        210
                    ]
                }
            ],
            "payCurrency": [
                8
            ],
            "salaryChangeCount": [
                17
            ],
            "salaryChangeBps": [
                17
            ],
            "equityChangeCount": [
                17
            ],
            "vestingSchedule": [
                511
            ],
            "equityChangeBps": [
                17
            ],
            "totalSalaryChangeCents": [
                17
            ],
            "totalEquityChangeShares": [
                17
            ],
            "bonusCents": [
                17
            ],
            "policyNotes": [
                8
            ],
            "kind": [
                113
            ],
            "equityUnitType": [
                111
            ],
            "vestingStartDate": [
                163
            ],
            "variableCommissionJson": [
                509
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioDataDumpInput": {
            "compensationChangeScenarioId": [
                8
            ],
            "data": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioDataDumpType": {
            "id": [
                8
            ],
            "compensationChangeScenarioId": [
                8
            ],
            "data": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioDataDumpV2Type": {
            "id": [
                8
            ],
            "compensationChangeScenarioId": [
                8
            ],
            "data": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioIndex": {
            "uuid": [
                459
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "implementedAt": [
                163
            ],
            "archivedAt": [
                163
            ],
            "effectiveDate": [
                163
            ],
            "name": [
                8
            ],
            "notes": [
                8
            ],
            "financialYear": [
                17
            ],
            "financialQuarter": [
                17
            ],
            "deadline": [
                163
            ],
            "compPlanConfig": [
                87
            ],
            "allTeamMembers": [
                114
            ],
            "cohorts": [
                115
            ],
            "employees": [
                222
            ],
            "scenarioEmployees": [
                421
            ],
            "sharedWith": [
                93
            ],
            "globalBudgets": [
                399
            ],
            "salaryCurrentSpendCents": [
                17
            ],
            "salaryBudgetCents": [
                2
            ],
            "equityCurrentSpendShares": [
                17
            ],
            "equityBudgetShares": [
                2
            ],
            "vestingSchedule": [
                511
            ],
            "exchangeRates": [
                232
            ],
            "hasEquityBudget": [
                22
            ],
            "hasVariableCommission": [
                22
            ],
            "totalVariableCompBudgetCents": [
                17
            ],
            "totalVariableCompSpendCents": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompensationScenarioIndexPhoenixRising": {
            "uuid": [
                459
            ],
            "compScenarioComputeTime": [
                8
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "implementedAt": [
                163
            ],
            "archivedAt": [
                163
            ],
            "effectiveDate": [
                163
            ],
            "launchedAt": [
                163
            ],
            "name": [
                8
            ],
            "notes": [
                8
            ],
            "financialYear": [
                17
            ],
            "financialQuarter": [
                17
            ],
            "deadline": [
                163
            ],
            "compPlanConfig": [
                87
            ],
            "unifiedEmployees": [
                513
            ],
            "globalBudgets": [
                396
            ],
            "salaryCurrentSpendCents": [
                17
            ],
            "salaryBudgetCents": [
                2
            ],
            "equityCurrentSpendShares": [
                17
            ],
            "equityBudgetShares": [
                17
            ],
            "vestingSchedule": [
                511
            ],
            "exchangeRates": [
                232
            ],
            "hasEquityBudget": [
                22
            ],
            "hasVariableCommission": [
                22
            ],
            "totalVariableCompBudgetCents": [
                17
            ],
            "totalVariableCompSpendCents": [
                17
            ],
            "totalCustomBonusBudgetCents": [
                17
            ],
            "totalGlobalBonusBudgetCents": [
                17
            ],
            "totalBonusBudgetSpendCents": [
                17
            ],
            "budgetRollups": [
                360
            ],
            "hasMeritMatrix": [
                22
            ],
            "meritMatrixId": [
                459
            ],
            "promotionTargetPercent": [
                17
            ],
            "performanceRatingSchemeId": [
                459
            ],
            "meritMatrixPerformanceRatingSchemeId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "CompetingOfferInput": {
            "id": [
                17
            ],
            "companyName": [
                8
            ],
            "annualSalaryCents": [
                17
            ],
            "equityValueCents": [
                17
            ],
            "notes": [
                8
            ],
            "position": [
                8
            ],
            "offer": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "CompetingOfferType": {
            "id": [
                17
            ],
            "createdAt": [
                163
            ],
            "companyName": [
                8
            ],
            "annualSalaryCents": [
                17
            ],
            "equityValueCents": [
                17
            ],
            "notes": [
                8
            ],
            "updatedAt": [
                163
            ],
            "position": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "Contact": {
            "name": [
                8
            ],
            "email": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ContactInput": {
            "name": [
                8
            ],
            "email": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ContactInputListInput": {
            "set": [
                124
            ],
            "add": [
                124
            ],
            "remove": [
                124
            ],
            "__typename": [
                8
            ]
        },
        "ContentType": {},
        "ContinentInputType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ContinentType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CountryInputType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CountryType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "continent": [
                128
            ],
            "__typename": [
                8
            ]
        },
        "CreateAggregateBenchmarkImportInput": {
            "name": [
                8
            ],
            "aggregateReferences": [
                1
            ],
            "jobFamily": [
                8
            ],
            "jobTitle": [
                8
            ],
            "jobLevel": [
                8
            ],
            "currencyCode": [
                8
            ],
            "benchmarkDistributions": [
                36
            ],
            "notes": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CreateAggregateBenchmarkImportPayload": {
            "on_BenchmarkImportType": [
                41
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateApprovalEmployeePayload": {
            "on_ScenarioEmployeeApproverType": [
                410
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateBandInputType": {
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "minInCents": [
                17
            ],
            "targetInCents": [
                17
            ],
            "maxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "description": [
                8
            ],
            "locations": [
                273
            ],
            "hiringRange": [
                247
            ],
            "__typename": [
                8
            ]
        },
        "CreateBandPayload": {
            "on_BandType": [
                28
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateBenefitPackagePayload": {
            "on_BenefitPackageType": [
                46
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateCommissionStructurePayload": {
            "on_CommissionStructureType": [
                84
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateCompensationPhilosophyPayload": {
            "on_CompensationPhilosophyType": [
                104
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateDocumentManagementConfigPayload": {
            "on_DocumentManagementConfigType": [
                198
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateEmployeeCommentPayload": {
            "on_EmployeeCommentType": [
                206
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateFlatfileImportPayload": {
            "on_FlatfileSpaceCreationResponse": [
                238
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateJobFamilyPayload": {
            "on_JobFamilyType": [
                259
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateOfferPayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateOfferTemplatePayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateOrganizationImportSourcePayload": {
            "on_OrganizationImportSourceResponse": [
                346
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateOrganizationPayload": {
            "on_OrganizationType": [
                351
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreatePerformanceRatingPayload": {
            "on_PerformanceRatingType": [
                357
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateRolePayload": {
            "on_RoleType": [
                386
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateScenarioBudgetPayload": {
            "on_ScenarioBudgetType": [
                399
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateScenarioEmployeeCommentPayload": {
            "on_ScenarioEmployeeCommentType": [
                413
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CreateUserPayload": {
            "on_ProfileType": [
                365
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "CustomBudgetSetting": {},
        "CustomColumnsType": {
            "value": [
                8
            ],
            "columnName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "CustomerRequestKind": {},
        "DataSyncStatusType": {
            "model": [
                8
            ],
            "fieldNames": [
                8
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "workEmail": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DataSyncTaskType": {
            "id": [
                459
            ],
            "resolvedAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "status": [
                8
            ],
            "lastShown": [
                163
            ],
            "description": [
                8
            ],
            "resolvedStatusesThroughWorkflow": [
                22
            ],
            "lastVerifiedAt": [
                163
            ],
            "dataSyncStatuses": [
                155
            ],
            "__typename": [
                8
            ]
        },
        "DataSyncTaskTypeMergeHealthTaskTypeLocationMismatchTaskType": {
            "on_DataSyncTaskType": [
                156
            ],
            "on_MergeHealthTaskType": [
                286
            ],
            "on_LocationMismatchTaskType": [
                275
            ],
            "on_TaskType": [
                443
            ],
            "__typename": [
                8
            ]
        },
        "DataTableColumn": {
            "key": [
                8
            ],
            "label": [
                8
            ],
            "type": [
                160
            ],
            "behavior": [
                161
            ],
            "columnDefinition": [
                108
            ],
            "__typename": [
                8
            ]
        },
        "DataTableColumnPreset": {
            "compensationPlanning": [
                106
            ],
            "__typename": [
                8
            ]
        },
        "DataTableColumnType": {},
        "DataTableColumnVisibility": {},
        "Date": {},
        "DateTime": {},
        "DatetimeFilterLookup": {
            "exact": [
                163
            ],
            "iExact": [
                163
            ],
            "contains": [
                163
            ],
            "iContains": [
                163
            ],
            "inList": [
                163
            ],
            "gt": [
                163
            ],
            "gte": [
                163
            ],
            "lt": [
                163
            ],
            "lte": [
                163
            ],
            "startsWith": [
                163
            ],
            "iStartsWith": [
                163
            ],
            "endsWith": [
                163
            ],
            "iEndsWith": [
                163
            ],
            "range": [
                163
            ],
            "isNull": [
                22
            ],
            "regex": [
                8
            ],
            "iRegex": [
                8
            ],
            "nExact": [
                163
            ],
            "nIExact": [
                163
            ],
            "nContains": [
                163
            ],
            "nIContains": [
                163
            ],
            "nInList": [
                163
            ],
            "nGt": [
                163
            ],
            "nGte": [
                163
            ],
            "nLt": [
                163
            ],
            "nLte": [
                163
            ],
            "nStartsWith": [
                163
            ],
            "nIStartsWith": [
                163
            ],
            "nEndsWith": [
                163
            ],
            "nIEndsWith": [
                163
            ],
            "nRange": [
                163
            ],
            "nIsNull": [
                22
            ],
            "nRegex": [
                8
            ],
            "nIRegex": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "Decimal": {},
        "DeleteBandPayload": {
            "on_BandType": [
                28
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteBenchmarkImportPayload": {
            "on_BenchmarkImportType": [
                41
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCommentPayload": {
            "on_DeleteCommentResponse": [
                169
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCommentResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCommissionStructurePayload": {
            "on_CommissionStructureType": [
                84
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCompensationChangeCohortInput": {
            "uuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCompensationChangeCohortTempInput": {
            "uuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCompensationChangeCohortTempPayload": {
            "on_CompensationScenarioCohortType": [
                115
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteCompensationChangeScenarioInput": {
            "uuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteDocumentManagementConfigInput": {
            "id": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteDocumentManagementConfigPayload": {
            "on_DeleteDocumentManagementConfigResponse": [
                177
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteDocumentManagementConfigResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "DeleteFlatfileImportInput": {
            "flatfileImportId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteFlatfileImportPayload": {
            "on_DeleteFlatfileImportResponse": [
                180
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteFlatfileImportResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "DeleteJobFamilyPayload": {
            "on_JobFamilyType": [
                259
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteMessageUserPayload": {
            "on_MessageUserType": [
                293
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteOfferPayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteOfferTemplatePayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteSecuritiesInput": {
            "securityIntegrationId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DeleteSecuritiesSuccess": {
            "message": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DeleteTotalRewardsStatementPayload": {
            "on_TotalRewardsStatementType": [
                456
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteUserPayload": {
            "on_DeleteUserResponse": [
                189
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DeleteUserResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "DisableManagerViewPayload": {
            "on_DisableManagerViewResponse": [
                191
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DisableManagerViewResponse": {
            "message": [
                8
            ],
            "failures": [
                8
            ],
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "DismissScenarioEmployeeAlertInput": {
            "alertId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "DismissScenarioEmployeeAlertPayload": {
            "on_ScenarioEmployeeAlertType": [
                404
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DjangoModelType": {
            "pk": [
                195
            ],
            "__typename": [
                8
            ]
        },
        "ID": {},
        "DocumentManagementConfigInput": {
            "signers": [
                125
            ],
            "carbonCopies": [
                125
            ],
            "subject": [
                8
            ],
            "status": [
                8
            ],
            "documentName": [
                8
            ],
            "documentType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DocumentManagementConfigInputPartial": {
            "id": [
                459
            ],
            "signers": [
                125
            ],
            "carbonCopies": [
                125
            ],
            "subject": [
                8
            ],
            "status": [
                8
            ],
            "documentName": [
                8
            ],
            "documentType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DocumentManagementConfigType": {
            "id": [
                459
            ],
            "signers": [
                123
            ],
            "carbonCopies": [
                123
            ],
            "subject": [
                8
            ],
            "status": [
                8
            ],
            "documentName": [
                8
            ],
            "documentType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DocumentManagementConfigurations": {
            "configs": [
                198
            ],
            "__typename": [
                8
            ]
        },
        "DuplicateOfferInput": {
            "offerId": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "DuplicateOfferPayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "DuplicateOfferTemplateInput": {
            "offerTemplateId": [
                17
            ],
            "offerTemplateName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "DuplicateOfferTemplatePayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "EmailAddress": {
            "emailAddressType": [
                8
            ],
            "value": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeAdditionalCalculatedData": {
            "equityCurrentUnits": [
                17
            ],
            "equityCurrentValue": [
                17
            ],
            "equityBandCurrencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeCommentType": {
            "id": [
                17
            ],
            "author": [
                365
            ],
            "employeeId": [
                459
            ],
            "content": [
                8
            ],
            "createdAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeCommissionsType": {
            "allCommissions": [
                252
            ],
            "activeCommissions": [
                252
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeCompaRatios": {
            "employeeId": [
                459
            ],
            "compaRatios": [
                89
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeCompaRatiosInput": {
            "employeeId": [
                459
            ],
            "compaRatios": [
                88
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeFilter": {
            "id": [
                459
            ],
            "workEmail": [
                8
            ],
            "mustBeActive": [
                22
            ],
            "AND": [
                210
            ],
            "OR": [
                210
            ],
            "NOT": [
                210
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeIneligibilityInput": {
            "employeeId": [
                459
            ],
            "ineligibilityReason": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeInputPartial": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "company": [
                459
            ],
            "displayFullName": [
                8
            ],
            "employmentStatus": [
                8
            ],
            "startDate": [
                163
            ],
            "workEmail": [
                8
            ],
            "personalEmail": [
                8
            ],
            "otherEmails": [
                8
            ],
            "hiddenAt": [
                163
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "benefitsPackage": [
                17
            ],
            "location": [
                272
            ],
            "managerId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeLocationSearchInputType": {
            "newLocations": [
                272
            ],
            "band": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeLocationSearchType": {
            "employees": [
                215
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeLocationType": {
            "id": [
                459
            ],
            "displayFullName": [
                8
            ],
            "location": [
                240
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeLocationsType": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "displayFullName": [
                8
            ],
            "workEmail": [
                8
            ],
            "workLocation": [
                278
            ],
            "location": [
                240
            ],
            "__typename": [
                8
            ]
        },
        "EmployeePlanningActivityLogSummary": {
            "items": [
                218
            ],
            "__typename": [
                8
            ]
        },
        "EmployeePlanningActivityLogSummaryItem": {
            "actor": [
                12
            ],
            "onBehalfOf": [
                12
            ],
            "action": [
                11
            ],
            "timestamp": [
                163
            ],
            "value": [
                17
            ],
            "prevValue": [
                17
            ],
            "valueStr": [
                8
            ],
            "prevValueStr": [
                8
            ],
            "payCurrency": [
                8
            ],
            "comment": [
                413
            ],
            "__typename": [
                8
            ]
        },
        "EmployeePublicType": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "company": [
                459
            ],
            "displayFullName": [
                8
            ],
            "initials": [
                8
            ],
            "employmentStatus": [
                8
            ],
            "startDate": [
                163
            ],
            "currentJobTitle": [
                8
            ],
            "role": [
                385
            ],
            "workEmail": [
                8
            ],
            "manager": [
                219
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeReportingToManagerType": {
            "id": [
                459
            ],
            "displayFullName": [
                8
            ],
            "isShared": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeTeamMember": {
            "id": [
                8
            ],
            "displayFullName": [
                8
            ],
            "currentJobTitle": [
                8
            ],
            "isManager": [
                22
            ],
            "isYou": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeType": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "company": [
                459
            ],
            "displayFullName": [
                8
            ],
            "initials": [
                8
            ],
            "employmentStatus": [
                8
            ],
            "startDate": [
                163
            ],
            "organization": [
                350
            ],
            "benefitsPackage": [
                46
            ],
            "hrisEmployeeNumber": [
                8
            ],
            "employmentSet": [
                228
            ],
            "currentJobTitle": [
                8
            ],
            "currentPayRateCents": [
                17
            ],
            "importSource": [
                237
            ],
            "role": [
                386
            ],
            "tier": [
                8
            ],
            "currentEmployment": [
                228
            ],
            "workLocation": [
                278
            ],
            "manager": [
                219
            ],
            "workEmail": [
                8
            ],
            "personalEmail": [
                8
            ],
            "compensationbonusSet": [
                90
            ],
            "hiddenAt": [
                163
            ],
            "performanceRatings": [
                357
            ],
            "totalRewardsStatements": [
                456
            ],
            "location": [
                240
            ],
            "totalUnitsGranted": [
                17
            ],
            "totalUnvestedUnits": [
                17
            ],
            "totalVestedUnits": [
                17
            ],
            "totalSoldDonatedTransferredUnits": [
                17
            ],
            "profile": [
                365
            ],
            "payLocation": [
                8
            ],
            "individualAdjustments": [
                115
            ],
            "stakeholder": [
                438
            ],
            "additionalCalculatedData": [
                205
            ],
            "comments": [
                206
            ],
            "bands": [
                28
            ],
            "employeeVariableCompAndBonuses": [
                223
            ],
            "employeeCommissions": [
                207
            ],
            "totalCompTarget": [
                445
            ],
            "equityTarget": [
                27
            ],
            "isAutomaticallyCreated": [
                22
            ],
            "ceoMinusOneEmployeeName": [
                8
            ],
            "ceoMinusTwoEmployeeName": [
                8
            ],
            "lastRoleChangeDate": [
                163
            ],
            "lastSecuritiesIngestionDate": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "EmployeeVariableCompAndBonusType": {
            "variableCompAndBonusCurrentYearAnnualizedRate": [
                17
            ],
            "variableCompAndBonusCurrencyCode": [
                8
            ],
            "lastVariableCompBonusEffectiveDate": [
                163
            ],
            "lastVariableCompBonusAmountCents": [
                17
            ],
            "lastVariableCompBonusCurrency": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "EmployeesAvailableToUserType": {
            "employeesAvailableToUser": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "EmploymentCompScenarioType": {
            "id": [
                459
            ],
            "jobTitle": [
                8
            ],
            "payRateInCents": [
                17
            ],
            "payCurrency": [
                8
            ],
            "effectiveDate": [
                163
            ],
            "annualizedPayRate": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "EmploymentDataclassType": {
            "jobTitle": [
                8
            ],
            "payRateInCents": [
                17
            ],
            "payPeriod": [
                8
            ],
            "payFrequency": [
                8
            ],
            "payCurrency": [
                8
            ],
            "effectiveDate": [
                8
            ],
            "employmentType": [
                8
            ],
            "annualizedPayRate": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "EmploymentRoleInfo": {
            "roleId": [
                17
            ],
            "roleTitle": [
                8
            ],
            "roleFamily": [
                8
            ],
            "levelNum": [
                17
            ],
            "jobTrack": [
                8
            ],
            "jobTrackLevelDisplay": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "EmploymentType": {
            "id": [
                459
            ],
            "employee": [
                219
            ],
            "jobTitle": [
                8
            ],
            "payRateInCents": [
                17
            ],
            "payPeriod": [
                8
            ],
            "payFrequency": [
                8
            ],
            "payCurrency": [
                8
            ],
            "effectiveDate": [
                163
            ],
            "employmentType": [
                8
            ],
            "annualizedPayRate": [
                17
            ],
            "mergeId": [
                8
            ],
            "hasRoleChanged": [
                22
            ],
            "roleInfo": [
                227
            ],
            "__typename": [
                8
            ]
        },
        "EnableManagerViewPayload": {
            "on_EnableManagerViewResponse": [
                230
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "EnableManagerViewResponse": {
            "message": [
                8
            ],
            "failures": [
                8
            ],
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "EquityCardDataType": {
            "certificateIdentifier": [
                8
            ],
            "issueDate": [
                8
            ],
            "vestingStartDate": [
                8
            ],
            "issuedSharesQuantity": [
                17
            ],
            "totalValueUsdCents": [
                17
            ],
            "monthsSinceVestingStarted": [
                17
            ],
            "numSharesVested": [
                17
            ],
            "percentageVestedInt": [
                17
            ],
            "sharePriceUsdCents": [
                17
            ],
            "exercisePriceUsdCents": [
                17
            ],
            "vestingDuration": [
                17
            ],
            "vestingFrequency": [
                8
            ],
            "hasCliff": [
                22
            ],
            "cliffDuration": [
                17
            ],
            "cliffDurationUnit": [
                8
            ],
            "events": [
                2
            ],
            "vestingEvents": [
                427
            ],
            "__typename": [
                8
            ]
        },
        "ExchangeRatesType": {
            "id": [
                459
            ],
            "exchangeRateDate": [
                162
            ],
            "rates": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "FieldMappingType": {
            "organizationDefinedTargets": [
                254
            ],
            "linkedAccountDefinedTargets": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "FlatfileImportFilter": {
            "importType": [
                236
            ],
            "mostRecent": [
                22
            ],
            "inProgress": [
                22
            ],
            "AND": [
                234
            ],
            "OR": [
                234
            ],
            "NOT": [
                234
            ],
            "__typename": [
                8
            ]
        },
        "FlatfileImportInput": {
            "organization": [
                459
            ],
            "type": [
                236
            ],
            "userAssignedToSpace": [
                8
            ],
            "scenario": [
                459
            ],
            "benchmarkDataSource": [
                8
            ],
            "performanceRatingSchemeId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "FlatfileImportKind": {},
        "FlatfileImportType": {
            "id": [
                459
            ],
            "scenario": [
                119
            ],
            "organization": [
                459
            ],
            "type": [
                236
            ],
            "userAssignedToSpace": [
                365
            ],
            "spaceId": [
                8
            ],
            "workbookId": [
                8
            ],
            "sheetId": [
                8
            ],
            "processedAt": [
                163
            ],
            "csvUrl": [
                8
            ],
            "deletedAt": [
                163
            ],
            "performanceRatingScheme": [
                355
            ],
            "__typename": [
                8
            ]
        },
        "FlatfileSpaceCreationResponse": {
            "spaceId": [
                8
            ],
            "accessToken": [
                8
            ],
            "environmentId": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "GenerateStatementPayload": {
            "on_TotalRewardsStatementGenerationResponse": [
                452
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "GeoLocationType": {
            "id": [
                459
            ],
            "city": [
                73
            ],
            "country": [
                130
            ],
            "continent": [
                128
            ],
            "__typename": [
                8
            ]
        },
        "GrantAccessInput": {
            "profileIds": [
                17
            ],
            "includeInvite": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "GrantAccessResponse": {
            "grantAccessResponses": [
                244
            ],
            "__typename": [
                8
            ]
        },
        "GrantUserAccessPayload": {
            "on_GrantAccessResponse": [
                242
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "GrantUserAccessResponse": {
            "profileId": [
                17
            ],
            "success": [
                22
            ],
            "message": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "HideUnhideEmployeesPayload": {
            "on_updateHiddenAtForEmployeesResponse": [
                515
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "HiringManagerType": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "email": [
                8
            ],
            "accessRole": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "HiringRangeInputType": {
            "hiringMinInCents": [
                17
            ],
            "hiringTargetInCents": [
                17
            ],
            "hiringHighInCents": [
                17
            ],
            "hiringMaxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "HiringRangeType": {
            "id": [
                459
            ],
            "hiringMinInCents": [
                17
            ],
            "hiringTargetInCents": [
                17
            ],
            "hiringHighInCents": [
                17
            ],
            "hiringMaxInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ImpersonateUserInput": {
            "toBeImpersonatedUserEmail": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ImpersonateUserPayload": {
            "on_ImpersonateUserResponse": [
                251
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "ImpersonateUserResponse": {
            "redirectUrl": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "IndividualEmployeeCommissionType": {
            "id": [
                459
            ],
            "payRateInCents": [
                17
            ],
            "currencyCode": [
                8
            ],
            "effectiveDate": [
                163
            ],
            "terminationDate": [
                163
            ],
            "endDate": [
                163
            ],
            "commissionType": [
                8
            ],
            "paymentType": [
                8
            ],
            "payPeriod": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "InviteType": {},
        "JSON": {},
        "JobFamilyInput": {
            "name": [
                8
            ],
            "description": [
                8
            ],
            "targetPercentileBps": [
                17
            ],
            "includeDefaultLevel": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyInputPartial": {
            "name": [
                8
            ],
            "description": [
                8
            ],
            "targetPercentileBps": [
                17
            ],
            "includeDefaultLevel": [
                22
            ],
            "uuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyInputType": {
            "uuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyLevelHiringRangePermission": {
            "jobFamilyId": [
                459
            ],
            "roleIds": [
                17
            ],
            "shareHiringRanges": [
                22
            ],
            "shareFamily": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyType": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "roles": [
                386
            ],
            "rolesSummary": [
                389
            ],
            "targetPercentileBps": [
                17
            ],
            "numberOfRoles": [
                17
            ],
            "hasNewLevelsToPublish": [
                22
            ],
            "isSharedWith": [
                365
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyTypeV3": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "targetPercentileBps": [
                17
            ],
            "roles": [
                387
            ],
            "lastUpdatedAt": [
                163
            ],
            "rolesSummary": [
                389
            ],
            "__typename": [
                8
            ]
        },
        "JobFamilyV2Type": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "targetPercentileBps": [
                17
            ],
            "roles": [
                388
            ],
            "hasNewLevelsToPublish": [
                22
            ],
            "rolesSummary": [
                389
            ],
            "isSharedWith": [
                365
            ],
            "lastUpdatedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "JobTrackInputType": {
            "id": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "JobTrackType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "abbreviation": [
                8
            ],
            "rank": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "JobType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "code": [
                8
            ],
            "status": [
                8
            ],
            "confidential": [
                22
            ],
            "hiringManagers": [
                246
            ],
            "recruiters": [
                373
            ],
            "offices": [
                334
            ],
            "__typename": [
                8
            ]
        },
        "LastSyncTimeType": {
            "lastSyncedTime": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "LevelInputType": {
            "id": [
                17
            ],
            "title": [
                8
            ],
            "level": [
                8
            ],
            "jobCode": [
                8
            ],
            "jobFamily": [
                8
            ],
            "description": [
                8
            ],
            "jobTrack": [
                8
            ],
            "track": [
                262
            ],
            "levelNum": [
                17
            ],
            "isHidden": [
                22
            ],
            "jobRoleTitles": [
                8
            ],
            "benchmarkImport": [
                459
            ],
            "benchmarkPercentileBps": [
                17
            ],
            "equityBenchmarkPercentileBps": [
                17
            ],
            "notes": [
                8
            ],
            "bands": [
                24
            ],
            "shouldBeDisplayed": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "LevelsMetadataType": {
            "numberOfFamilies": [
                17
            ],
            "numberOfRoles": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "LevelsResult": {
            "roles": [
                386
            ],
            "families": [
                259
            ],
            "__typename": [
                8
            ]
        },
        "LevelsSearchFilter": {
            "query": [
                8
            ],
            "familyUuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "LevelsSearchType": {
            "exactMatch": [
                268
            ],
            "synonymMatch": [
                268
            ],
            "__typename": [
                8
            ]
        },
        "LinkProfileToEmployeePayload": {
            "on_ProfileType": [
                365
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "LocationInputType": {
            "city": [
                72
            ],
            "country": [
                129
            ],
            "continent": [
                127
            ],
            "__typename": [
                8
            ]
        },
        "LocationInputTypeListInput": {
            "set": [
                272
            ],
            "add": [
                272
            ],
            "remove": [
                272
            ],
            "__typename": [
                8
            ]
        },
        "LocationMismatchEmployeeType": {
            "id": [
                459
            ],
            "employee": [
                216
            ],
            "dismissedAt": [
                163
            ],
            "createdAt": [
                163
            ],
            "resolvedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "LocationMismatchTaskType": {
            "id": [
                459
            ],
            "resolvedAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "status": [
                8
            ],
            "lastShown": [
                163
            ],
            "description": [
                8
            ],
            "lastVerifiedAt": [
                163
            ],
            "employeeStates": [
                274
            ],
            "__typename": [
                8
            ]
        },
        "LocationSearchFilter": {
            "query": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "LocationSearchType": {
            "places": [
                74
            ],
            "__typename": [
                8
            ]
        },
        "LocationType": {
            "uuid": [
                459
            ],
            "name": [
                8
            ],
            "city": [
                8
            ],
            "state": [
                8
            ],
            "country": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ManagerEmployeeTreeResponse": {
            "manager": [
                219
            ],
            "profileId": [
                17
            ],
            "employees": [
                220
            ],
            "hasManagerViewEnabled": [
                22
            ],
            "lastSignInAt": [
                163
            ],
            "lastActiveAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "ManagerViewInput": {
            "managerReport": [
                281
            ],
            "sendEmailInvites": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "ManagerViewInputEmployee": {
            "managerId": [
                459
            ],
            "employeeIds": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "ManyToManyInput": {
            "add": [
                195
            ],
            "remove": [
                195
            ],
            "set": [
                195
            ],
            "__typename": [
                8
            ]
        },
        "MarkAllEligibleInput": {
            "employeeIds": [
                459
            ],
            "scenarioId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "MarkAllEligiblePayload": {
            "on_MarkAllEligibleResponse": [
                285
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "MarkAllEligibleResponse": {
            "scenarioId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "MergeHealthTaskType": {
            "id": [
                459
            ],
            "resolvedAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "status": [
                8
            ],
            "lastShown": [
                163
            ],
            "description": [
                8
            ],
            "issueType": [
                8
            ],
            "lastVerifiedAt": [
                163
            ],
            "importSource": [
                347
            ],
            "__typename": [
                8
            ]
        },
        "MergeOfferType": {
            "id": [
                459
            ],
            "application": [
                459
            ],
            "creator": [
                459
            ],
            "startDate": [
                8
            ],
            "remoteId": [
                8
            ],
            "remoteCreatedAt": [
                8
            ],
            "closedAt": [
                8
            ],
            "sentAt": [
                8
            ],
            "status": [
                8
            ],
            "fieldMappings": [
                233
            ],
            "remoteData": [
                375
            ],
            "__typename": [
                8
            ]
        },
        "MeritMatrixType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "MessageInput": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "MessageInputListInput": {
            "set": [
                289
            ],
            "add": [
                289
            ],
            "remove": [
                289
            ],
            "__typename": [
                8
            ]
        },
        "MessageType": {
            "id": [
                17
            ],
            "authorFullName": [
                8
            ],
            "offer": [
                324
            ],
            "user": [
                293
            ],
            "content": [
                8
            ],
            "createdAt": [
                163
            ],
            "videoUrl": [
                8
            ],
            "offerOmitted": [
                194
            ],
            "__typename": [
                8
            ]
        },
        "MessageUserInputIdentifier": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "MessageUserType": {
            "id": [
                17
            ],
            "group": [
                8
            ],
            "fullName": [
                8
            ],
            "title": [
                8
            ],
            "email": [
                8
            ],
            "imagePath": [
                8
            ],
            "links": [
                8
            ],
            "messages": [
                291
            ],
            "genericMessages": [
                291
            ],
            "__typename": [
                8
            ]
        },
        "Mutation": {
            "updateScenarioBudget": [
                489,
                {
                    "input": [
                        398,
                        "ScenarioBudgetInputPartial!"
                    ]
                }
            ],
            "notifyApprovalEmployee": [
                305,
                {
                    "input": [
                        304,
                        "NotifyApprovalEmployeeInput!"
                    ]
                }
            ],
            "createApprovalEmployee": [
                133,
                {
                    "input": [
                        408,
                        "ScenarioEmployeeApproverInput!"
                    ]
                }
            ],
            "updateApprovalEmployee": [
                462,
                {
                    "input": [
                        409,
                        "ScenarioEmployeeApproverInputPartial!"
                    ]
                }
            ],
            "createPerformanceRating": [
                147,
                {
                    "input": [
                        353,
                        "PerformanceRatingInput!"
                    ]
                }
            ],
            "updatePerformanceRating": [
                484,
                {
                    "input": [
                        354,
                        "PerformanceRatingInputPartial!"
                    ]
                }
            ],
            "dismissScenarioEmployeeAlert": [
                193,
                {
                    "input": [
                        192,
                        "DismissScenarioEmployeeAlertInput!"
                    ]
                }
            ],
            "updateEmployee": [
                470,
                {
                    "input": [
                        212,
                        "EmployeeInputPartial!"
                    ]
                }
            ],
            "updateEmployeesLocation": [
                471,
                {
                    "input": [
                        56,
                        "BulkUpdatePayGeoInput!"
                    ]
                }
            ],
            "createCompensationChangeScenarioV2": [
                98,
                {
                    "input": [
                        94,
                        "CompensationChangeScenarioInputType!"
                    ]
                }
            ],
            "bulkUpdateScenarioEmployeeApprovers": [
                58,
                {
                    "input": [
                        406,
                        "ScenarioEmployeeApproverBulkInputType!"
                    ]
                }
            ],
            "updateCompensationChangeScenarioV2": [
                512,
                {
                    "input": [
                        94,
                        "CompensationChangeScenarioInputType!"
                    ]
                }
            ],
            "persistCompensationChangeScenarioData": [
                512,
                {
                    "input": [
                        116,
                        "CompensationScenarioDataDumpInput!"
                    ]
                }
            ],
            "persistCompensationChangeScenarioDataV2": [
                512,
                {
                    "input": [
                        116,
                        "CompensationScenarioDataDumpInput!"
                    ]
                }
            ],
            "updateScenarioApprover": [
                395,
                {
                    "input": [
                        394,
                        "ScenarioApproverInput!"
                    ]
                }
            ],
            "updateScenarioEmployee": [
                421,
                {
                    "input": [
                        415,
                        "ScenarioEmployeeInputPartial!"
                    ]
                }
            ],
            "deleteCompensationChangeScenario": [
                337,
                {
                    "input": [
                        174,
                        "DeleteCompensationChangeScenarioInput!"
                    ]
                }
            ],
            "createCompensationChangeCohort": [
                115,
                {
                    "input": [
                        112,
                        "CompensationScenarioCohortInputType!"
                    ]
                }
            ],
            "updateCompensationChangeCohort": [
                115,
                {
                    "input": [
                        112,
                        "CompensationScenarioCohortInputType!"
                    ]
                }
            ],
            "deleteCompensationChangeCohort": [
                337,
                {
                    "input": [
                        171,
                        "DeleteCompensationChangeCohortInput!"
                    ]
                }
            ],
            "deleteCompensationChangeCohortTemp": [
                173,
                {
                    "input": [
                        172,
                        "DeleteCompensationChangeCohortTempInput!"
                    ]
                }
            ],
            "scenarioEmployeeInvite": [
                417,
                {
                    "input": [
                        416,
                        "ScenarioEmployeeInviteInput!"
                    ]
                }
            ],
            "compensationChangeScenarioInvite": [
                96,
                {
                    "input": [
                        95,
                        "CompensationChangeScenarioInviteInput!"
                    ]
                }
            ],
            "createScenarioBudget": [
                149,
                {
                    "input": [
                        397,
                        "ScenarioBudgetInput!"
                    ]
                }
            ],
            "markAllEligible": [
                284,
                {
                    "input": [
                        283,
                        "MarkAllEligibleInput!"
                    ]
                }
            ],
            "assignIneligibilityReasons": [
                9,
                {
                    "input": [
                        7,
                        "AssignIneligibilityReasonsInput!"
                    ]
                }
            ],
            "deleteBand": [
                166,
                {
                    "input": [
                        20,
                        "BandInputIdentifier!"
                    ]
                }
            ],
            "createBand": [
                135,
                {
                    "input": [
                        21,
                        "BandInputPartialType!"
                    ]
                }
            ],
            "bulkPublishLevels": [
                53
            ],
            "createRole": [
                148,
                {
                    "input": [
                        384,
                        "RoleInputType!"
                    ]
                }
            ],
            "updateRole": [
                486,
                {
                    "input": [
                        384,
                        "RoleInputType!"
                    ]
                }
            ],
            "updateRoles": [
                487,
                {
                    "input": [
                        266,
                        "[LevelInputType!]!"
                    ]
                }
            ],
            "publishRolesV2": [
                370,
                {
                    "input": [
                        368,
                        "PublishRolesInput!"
                    ]
                }
            ],
            "createJobFamily": [
                142,
                {
                    "input": [
                        255,
                        "JobFamilyInput!"
                    ]
                }
            ],
            "updateJobFamily": [
                474,
                {
                    "input": [
                        256,
                        "JobFamilyInputPartial!"
                    ]
                }
            ],
            "deleteJobFamily": [
                181,
                {
                    "input": [
                        256,
                        "JobFamilyInputPartial!"
                    ]
                }
            ],
            "updateJobTrack": [
                475,
                {
                    "input": [
                        457,
                        "TrackInputType!"
                    ]
                }
            ],
            "enableManagerView": [
                229,
                {
                    "input": [
                        280,
                        "ManagerViewInput!"
                    ]
                }
            ],
            "disableManagerView": [
                190,
                {
                    "input": [
                        280,
                        "ManagerViewInput!"
                    ]
                }
            ],
            "archiveOfferTemplate": [
                6,
                {
                    "offerTemplateId": [
                        17,
                        "Int!"
                    ]
                }
            ],
            "createOffer": [
                143,
                {
                    "input": [
                        312,
                        "OfferCreateInput!"
                    ]
                }
            ],
            "createOfferTemplate": [
                144,
                {
                    "input": [
                        328,
                        "OfferTemplateCreateInput!"
                    ]
                }
            ],
            "deleteOffer": [
                183,
                {
                    "input": [
                        314,
                        "OfferInputPartial!"
                    ]
                }
            ],
            "offerInvite": [
                316,
                {
                    "input": [
                        315,
                        "OfferInviteInput!"
                    ]
                }
            ],
            "deleteMessageUser": [
                182,
                {
                    "input": [
                        292,
                        "MessageUserInputIdentifier!"
                    ]
                }
            ],
            "updateOffer": [
                481,
                {
                    "input": [
                        314,
                        "OfferInputPartial!"
                    ]
                }
            ],
            "deleteCommissionStructure": [
                170,
                {
                    "input": [
                        82,
                        "CommissionStructureInputIdentifier!"
                    ]
                }
            ],
            "updateFullOffer": [
                473,
                {
                    "input": [
                        333,
                        "OfferUpdateInput!"
                    ]
                }
            ],
            "createCommissionStructure": [
                137,
                {
                    "input": [
                        81,
                        "CommissionStructureInput!"
                    ]
                }
            ],
            "updateCommissionStructure": [
                466,
                {
                    "input": [
                        83,
                        "CommissionStructureInputPartial!"
                    ]
                }
            ],
            "updateOfferTemplate": [
                482,
                {
                    "input": [
                        329,
                        "OfferTemplateInputPartial!"
                    ]
                }
            ],
            "deleteOfferTemplate": [
                184,
                {
                    "input": [
                        329,
                        "OfferTemplateInputPartial!"
                    ]
                }
            ],
            "duplicateOfferTemplate": [
                203,
                {
                    "input": [
                        202,
                        "DuplicateOfferTemplateInput!"
                    ]
                }
            ],
            "duplicateOffer": [
                201,
                {
                    "input": [
                        200,
                        "DuplicateOfferInput!"
                    ]
                }
            ],
            "submitCandidateMessage": [
                62,
                {
                    "input": [
                        60,
                        "CandidateMessageInput!"
                    ]
                }
            ],
            "sendDocument": [
                429,
                {
                    "input": [
                        428,
                        "SendDocumentInput!"
                    ]
                }
            ],
            "candidateOfferAccept": [
                66,
                {
                    "input": [
                        65,
                        "CandidateOfferAcceptInput!"
                    ]
                }
            ],
            "candidateOfferPackageSelect": [
                322,
                {
                    "input": [
                        321,
                        "OfferPackageSelectInput!"
                    ]
                }
            ],
            "candidateViewedOffer": [
                459,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "generateStatement": [
                239,
                {
                    "input": [
                        451,
                        "TotalRewardsStatementGenerationInput!"
                    ]
                }
            ],
            "setStatementVisibility": [
                434,
                {
                    "input": [
                        453,
                        "TotalRewardsStatementInputPartial!"
                    ]
                }
            ],
            "updateTotalRewardsStatement": [
                494,
                {
                    "input": [
                        453,
                        "TotalRewardsStatementInputPartial!"
                    ]
                }
            ],
            "bulkUpdateTotalRewardsStatement": [
                59,
                {
                    "input": [
                        454,
                        "TotalRewardsStatementInputPartialBulk!"
                    ]
                }
            ],
            "deleteTotalRewardsStatement": [
                187,
                {
                    "input": [
                        453,
                        "TotalRewardsStatementInputPartial!"
                    ]
                }
            ],
            "recordTotalRewardsAnalytics": [
                372,
                {
                    "input": [
                        450,
                        "TotalRewardsStatementAnalyticsInput!"
                    ]
                }
            ],
            "createEmployeeComment": [
                140,
                {
                    "input": [
                        75,
                        "CommentCreateInput!"
                    ]
                }
            ],
            "updateEmployees": [
                472,
                {
                    "input": [
                        54,
                        "BulkUpdateEmployeesInput!"
                    ]
                }
            ],
            "createScenarioEmployeeComment": [
                150,
                {
                    "input": [
                        412,
                        "ScenarioEmployeeCommentCreateInput!"
                    ]
                }
            ],
            "updateScenarioEmployeeComment": [
                490,
                {
                    "input": [
                        400,
                        "ScenarioCommentUpdateInput!"
                    ]
                }
            ],
            "updateComment": [
                465,
                {
                    "input": [
                        77,
                        "CommentUpdateInput!"
                    ]
                }
            ],
            "deleteComment": [
                168,
                {
                    "input": [
                        76,
                        "CommentDeleteInput!"
                    ]
                }
            ],
            "createCartaAuthorizationUrl": [
                70,
                {
                    "input": [
                        71,
                        "CartaAuthorizationInput!"
                    ]
                }
            ],
            "generateCartaAceessToken": [
                69,
                {
                    "input": [
                        68,
                        "CartaAccessTokenInput!"
                    ]
                }
            ],
            "deleteSecuritiesIntegration": [
                186,
                {
                    "input": [
                        185,
                        "DeleteSecuritiesInput!"
                    ]
                }
            ],
            "sendWelcomeEmail": [
                432,
                {
                    "input": [
                        431,
                        "SendWelcomeEmailInput!"
                    ]
                }
            ],
            "updateTableColumnSetting": [
                297,
                {
                    "input": [
                        441,
                        "TableColumnSettingUpdateInput!"
                    ]
                }
            ],
            "updateNotificationRecipient": [
                480,
                {
                    "input": [
                        301,
                        "NotificationRecipientInput!"
                    ]
                }
            ],
            "updateBenefitPackage": [
                464,
                {
                    "input": [
                        44,
                        "BenefitPackageEditInputPartial!"
                    ]
                }
            ],
            "deleteBenchmarkImport": [
                167,
                {
                    "input": [
                        40,
                        "BenchmarkImportInputIdentifier!"
                    ]
                }
            ],
            "updateProfile": [
                485,
                {
                    "input": [
                        363,
                        "ProfileInputPartial!"
                    ]
                }
            ],
            "linkProfileToEmployee": [
                271,
                {
                    "input": [
                        364,
                        "ProfileLinkEmployeeInput!"
                    ]
                }
            ],
            "createBenefitPackage": [
                136,
                {
                    "input": [
                        45,
                        "BenefitPackageInput!"
                    ]
                }
            ],
            "createDocumentManagementConfig": [
                139,
                {
                    "input": [
                        196,
                        "DocumentManagementConfigInput!"
                    ]
                }
            ],
            "updateDocumentManagementConfig": [
                469,
                {
                    "input": [
                        197,
                        "DocumentManagementConfigInputPartial!"
                    ]
                }
            ],
            "deleteDocumentManagementConfig": [
                176,
                {
                    "input": [
                        175,
                        "DeleteDocumentManagementConfigInput!"
                    ]
                }
            ],
            "createUser": [
                151,
                {
                    "createInput": [
                        501,
                        "UserCreateInput!"
                    ]
                }
            ],
            "deleteUser": [
                188,
                {
                    "deleteInput": [
                        502,
                        "UserDeleteInput!"
                    ]
                }
            ],
            "createOrganization": [
                146,
                {
                    "input": [
                        342,
                        "OrganizationCreateInput!"
                    ]
                }
            ],
            "updateOrganization": [
                483,
                {
                    "input": [
                        348,
                        "OrganizationInputPartial!"
                    ]
                }
            ],
            "createOrganizationImportSource": [
                145,
                {
                    "input": [
                        345,
                        "OrganizationImportSourceInputPartial!"
                    ]
                }
            ],
            "relinkOrganizationImportSource": [
                374,
                {
                    "input": [
                        345,
                        "OrganizationImportSourceInputPartial!"
                    ]
                }
            ],
            "createAggregateBenchmarkImport": [
                132,
                {
                    "input": [
                        131,
                        "CreateAggregateBenchmarkImportInput!"
                    ]
                }
            ],
            "updateBenchmarkImportFilters": [
                463,
                {
                    "input": [
                        38,
                        "BenchmarkImportFilterUpdateInput!"
                    ]
                }
            ],
            "createCompensationPhilosophy": [
                138,
                {
                    "input": [
                        103,
                        "CompensationPhilosophyInput!"
                    ]
                }
            ],
            "updateCompensationPhilosophy": [
                468,
                {
                    "input": [
                        467,
                        "UpdateCompensationPhilosophyInput!"
                    ]
                }
            ],
            "uploadOfferTemplateDocument": [
                500,
                {
                    "input": [
                        499,
                        "UploadOfferTemplateDocumentInput!"
                    ]
                }
            ],
            "createFlatfileImport": [
                141,
                {
                    "input": [
                        235,
                        "FlatfileImportInput!"
                    ]
                }
            ],
            "deleteFlatfileImport": [
                179,
                {
                    "input": [
                        178,
                        "DeleteFlatfileImportInput!"
                    ]
                }
            ],
            "notifyCustomerRequest": [
                308,
                {
                    "input": [
                        307,
                        "NotifyCustomerRequestInput!"
                    ]
                }
            ],
            "impersonateUser": [
                250,
                {
                    "input": [
                        249,
                        "ImpersonateUserInput!"
                    ]
                }
            ],
            "revertToOriginalUser": [
                377,
                {
                    "input": [
                        376,
                        "RevertToOriginalUserInput!"
                    ]
                }
            ],
            "updateUserPermissions": [
                496,
                {
                    "input": [
                        495,
                        "UpdateUserPermissionsInput!"
                    ]
                }
            ],
            "updateLevelsPermissions": [
                477,
                {
                    "input": [
                        476,
                        "UpdateLevelsPermissionsInput!"
                    ]
                }
            ],
            "grantUserAccess": [
                243,
                {
                    "input": [
                        241,
                        "GrantAccessInput!"
                    ]
                }
            ],
            "revokeUserAccess": [
                381,
                {
                    "input": [
                        379,
                        "RevokeAccessInput!"
                    ]
                }
            ],
            "updateTaskStatus": [
                493,
                {
                    "input": [
                        492,
                        "UpdateTaskStatusInputPartial!"
                    ]
                }
            ],
            "updateLocationMismatchTask": [
                479,
                {
                    "input": [
                        478,
                        "UpdateLocationMismatchTaskInput!"
                    ]
                }
            ],
            "hideUnhideEmployees": [
                245,
                {
                    "input": [
                        514,
                        "updateHiddenAtForEmployeesInput!"
                    ]
                }
            ],
            "__typename": [
                8
            ]
        },
        "NewTotalRewardsDataType": {
            "currentBaseSalaryCents": [
                17
            ],
            "currentBaseSalaryCurrency": [
                8
            ],
            "currentBaseSalaryIsNew": [
                22
            ],
            "baseSalaryDifference": [
                17
            ],
            "previousBaseSalaryCents": [
                17
            ],
            "previousBaseSalaryCurrency": [
                8
            ],
            "benefitPackageValueCents": [
                17
            ],
            "benefitPackageCurrency": [
                8
            ],
            "equityValueInCurrentCalendarYearCents": [
                17
            ],
            "totalCompensationCents": [
                17
            ],
            "currentJobFamilyUuid": [
                8
            ],
            "currentJobTitle": [
                8
            ],
            "currentRoleTitle": [
                8
            ],
            "currentRoleId": [
                17
            ],
            "previousRoleTitle": [
                8
            ],
            "previousRoleId": [
                17
            ],
            "salaryCardData": [
                391
            ],
            "teamMembers": [
                221
            ],
            "benefitPackage": [
                46
            ],
            "totalVestedEquityValueCents": [
                17
            ],
            "totalUnvestedEquityValueCents": [
                17
            ],
            "totalVestedEquityUnits": [
                17
            ],
            "totalUnvestedEquityUnits": [
                17
            ],
            "totalEquityValueCents": [
                17
            ],
            "equityCardData": [
                231
            ],
            "__typename": [
                8
            ]
        },
        "NewTotalRewardsStatementType": {
            "id": [
                459
            ],
            "employee": [
                219
            ],
            "isVisible": [
                22
            ],
            "createdAt": [
                163
            ],
            "totalRewardsData": [
                295
            ],
            "employeeLastViewedAt": [
                163
            ],
            "totalEmployeeViewCount": [
                17
            ],
            "averageSessionLengthSeconds": [
                17
            ],
            "adminMessage": [
                8
            ],
            "orgConfig": [
                449
            ],
            "__typename": [
                8
            ]
        },
        "NormalizedTableColumnType": {
            "tableName": [
                8
            ],
            "tableColumnName": [
                8
            ],
            "isVisible": [
                22
            ],
            "isEnabled": [
                22
            ],
            "locked": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "NotificationConfigurationInput": {
            "id": [
                17
            ],
            "type": [
                8
            ],
            "enabled": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "NotificationConfigurationInputListInput": {
            "set": [
                298
            ],
            "add": [
                298
            ],
            "remove": [
                298
            ],
            "__typename": [
                8
            ]
        },
        "NotificationConfigurationType": {
            "id": [
                17
            ],
            "type": [
                8
            ],
            "enabled": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "NotificationRecipientInput": {
            "id": [
                459
            ],
            "email": [
                8
            ],
            "notifications": [
                299
            ],
            "name": [
                8
            ],
            "position": [
                8
            ],
            "organization": [
                349
            ],
            "__typename": [
                8
            ]
        },
        "NotificationRecipientInputListInput": {
            "set": [
                301
            ],
            "add": [
                301
            ],
            "remove": [
                301
            ],
            "__typename": [
                8
            ]
        },
        "NotificationRecipientType": {
            "id": [
                459
            ],
            "email": [
                8
            ],
            "position": [
                8
            ],
            "name": [
                8
            ],
            "notifications": [
                300
            ],
            "__typename": [
                8
            ]
        },
        "NotifyApprovalEmployeeInput": {
            "scenarioEmployeeApproverId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "NotifyApprovalEmployeePayload": {
            "on_NotifyApprovalEmployeeResponse": [
                306
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "NotifyApprovalEmployeeResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "NotifyCustomerRequestInput": {
            "kind": [
                154
            ],
            "primaryObjectUuid": [
                459
            ],
            "additionalContext": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "NotifyCustomerRequestPayload": {
            "on_NotifyCustomerRequestResponse": [
                309
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "NotifyCustomerRequestResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "OfferAccessType": {},
        "OfferAnalyticsType": {
            "offerUuid": [
                459
            ],
            "pageViews": [
                323
            ],
            "lastViewed": [
                319
            ],
            "lastSync": [
                265
            ],
            "__typename": [
                8
            ]
        },
        "OfferCreateInput": {
            "candidateFullName": [
                8
            ],
            "candidateEmail": [
                8
            ],
            "organization": [
                17
            ],
            "benefitPackage": [
                336
            ],
            "expiresAt": [
                163
            ],
            "state": [
                327
            ],
            "createdBy": [
                17
            ],
            "position": [
                8
            ],
            "jobDescription": [
                8
            ],
            "location": [
                8
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "candidateUuid": [
                8
            ],
            "startDate": [
                163
            ],
            "documentVariables": [
                254
            ],
            "annualSalaryCents": [
                17
            ],
            "cashCurrency": [
                8
            ],
            "commissionCurrency": [
                8
            ],
            "equityUnits": [
                17
            ],
            "equityValueCents": [
                17
            ],
            "equityUnitType": [
                8
            ],
            "annualBonusCents": [
                17
            ],
            "documentConfiguration": [
                336
            ],
            "designatedSigner": [
                8
            ],
            "paygeo": [
                272
            ],
            "bonuses": [
                48
            ],
            "offerTemplateUuid": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "OfferFilter": {
            "uuid": [
                460
            ],
            "state": [
                327
            ],
            "expiresAt": [
                164
            ],
            "fuzzyCandidateSearch": [
                8
            ],
            "AND": [
                313
            ],
            "OR": [
                313
            ],
            "NOT": [
                313
            ],
            "__typename": [
                8
            ]
        },
        "OfferInputPartial": {
            "id": [
                17
            ],
            "state": [
                8
            ],
            "candidateFullName": [
                8
            ],
            "candidateEmail": [
                8
            ],
            "position": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "location": [
                8
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "recruiterPhone": [
                8
            ],
            "flexibility": [
                254
            ],
            "expiresAt": [
                163
            ],
            "startDate": [
                163
            ],
            "jobDescription": [
                8
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "benefitPackage": [
                17
            ],
            "benefitPackageType": [
                336
            ],
            "customization": [
                254
            ],
            "documentVariables": [
                254
            ],
            "compensationPackages": [
                101
            ],
            "offerSignedAt": [
                163
            ],
            "outcome": [
                8
            ],
            "rejectReasonType": [
                8
            ],
            "rejectReasonNote": [
                8
            ],
            "documentConfiguration": [
                336
            ],
            "paygeo": [
                272
            ],
            "offerRoleMetadata": [
                326
            ],
            "compensationType": [
                8
            ],
            "commissionCurrency": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OfferInviteInput": {
            "id": [
                17
            ],
            "profileId": [
                17
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "OfferInvitePayload": {
            "on_OfferInviteResponse": [
                317
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "OfferInviteResponse": {
            "offer": [
                332
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "OfferInvitee": {
            "access": [
                310
            ],
            "user": [
                507
            ],
            "__typename": [
                8
            ]
        },
        "OfferLastViewedType": {
            "offerUuid": [
                8
            ],
            "lastViewedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "OfferOrder": {
            "expiresAt": [
                340
            ],
            "__typename": [
                8
            ]
        },
        "OfferPackageSelectInput": {
            "uuid": [
                459
            ],
            "selectedPackageId": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OfferPackageSelectResponse": {
            "selectedPackageId": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OfferPageViewType": {
            "offerUuid": [
                8
            ],
            "page": [
                8
            ],
            "viewCount": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OfferPublicType": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OfferRoleMetadata": {
            "title": [
                8
            ],
            "salaryMin": [
                17
            ],
            "salaryTarget": [
                17
            ],
            "salaryMax": [
                17
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityMin": [
                17
            ],
            "equityTarget": [
                17
            ],
            "equityMax": [
                17
            ],
            "equityCurrencyCode": [
                8
            ],
            "levelNum": [
                17
            ],
            "jobTrack": [
                8
            ],
            "paygeo": [
                8
            ],
            "tier": [
                8
            ],
            "jobFamily": [
                8
            ],
            "generatedAt": [
                8
            ],
            "hiringSalaryMin": [
                17
            ],
            "hiringSalaryTarget": [
                17
            ],
            "hiringSalaryHigh": [
                17
            ],
            "hiringSalaryMax": [
                17
            ],
            "hiringSalaryCurrencyCode": [
                8
            ],
            "hiringEquityMin": [
                17
            ],
            "hiringEquityTarget": [
                17
            ],
            "hiringEquityHigh": [
                17
            ],
            "hiringEquityMax": [
                17
            ],
            "hiringEquityCurrencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OfferRoleMetadataInput": {
            "title": [
                8
            ],
            "salaryMin": [
                17
            ],
            "salaryTarget": [
                17
            ],
            "salaryMax": [
                17
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityMin": [
                17
            ],
            "equityTarget": [
                17
            ],
            "equityMax": [
                17
            ],
            "equityCurrencyCode": [
                8
            ],
            "levelNum": [
                17
            ],
            "jobTrack": [
                8
            ],
            "paygeo": [
                8
            ],
            "tier": [
                8
            ],
            "jobFamily": [
                8
            ],
            "hiringSalaryMin": [
                17
            ],
            "hiringSalaryTarget": [
                17
            ],
            "hiringSalaryHigh": [
                17
            ],
            "hiringSalaryMax": [
                17
            ],
            "hiringSalaryCurrencyCode": [
                8
            ],
            "hiringEquityMin": [
                17
            ],
            "hiringEquityTarget": [
                17
            ],
            "hiringEquityHigh": [
                17
            ],
            "hiringEquityMax": [
                17
            ],
            "hiringEquityCurrencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OfferStateType": {},
        "OfferTemplateCreateInput": {
            "templateName": [
                8
            ],
            "templateDescription": [
                8
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "position": [
                8
            ],
            "location": [
                8
            ],
            "jobDescription": [
                8
            ],
            "startDate": [
                163
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "recruiterPhone": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "cashCurrency": [
                8
            ],
            "vestingSchedule": [
                8
            ],
            "equityUnitType": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "commissionStructure": [
                17
            ],
            "benefitPackage": [
                17
            ],
            "benefitPackageType": [
                336
            ],
            "customization": [
                254
            ],
            "documentVariables": [
                254
            ],
            "compensationPackages": [
                101
            ],
            "bonuses": [
                48
            ],
            "paygeo": [
                272
            ],
            "commissionCurrency": [
                8
            ],
            "compensationType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OfferTemplateInputPartial": {
            "templateName": [
                8
            ],
            "templateDescription": [
                8
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "position": [
                8
            ],
            "location": [
                8
            ],
            "jobDescription": [
                8
            ],
            "startDate": [
                163
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "recruiterPhone": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "cashCurrency": [
                8
            ],
            "vestingSchedule": [
                8
            ],
            "equityUnitType": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "commissionStructure": [
                17
            ],
            "benefitPackage": [
                17
            ],
            "benefitPackageType": [
                336
            ],
            "customization": [
                254
            ],
            "documentVariables": [
                254
            ],
            "compensationPackages": [
                101
            ],
            "bonuses": [
                48
            ],
            "paygeo": [
                272
            ],
            "commissionCurrency": [
                8
            ],
            "compensationType": [
                8
            ],
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OfferTemplateState": {},
        "OfferTemplatesFilter": {
            "stateIn": [
                330
            ],
            "stateNotIn": [
                330
            ],
            "__typename": [
                8
            ]
        },
        "OfferType": {
            "id": [
                17
            ],
            "role": [
                386
            ],
            "organization": [
                351
            ],
            "messages": [
                291
            ],
            "benefitPackage": [
                46
            ],
            "commissionStructure": [
                84
            ],
            "compensationPackages": [
                102
            ],
            "bonuses": [
                49
            ],
            "uuid": [
                459
            ],
            "publicUuid": [
                459
            ],
            "documentConfiguration": [
                194
            ],
            "designatedSigner": [
                8
            ],
            "candidateFullName": [
                8
            ],
            "candidateEmail": [
                8
            ],
            "candidateLastViewedAt": [
                163
            ],
            "position": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "annualSalaryCents": [
                17
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "recruiterPhone": [
                8
            ],
            "location": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "state": [
                327
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "vestingSchedule": [
                8
            ],
            "equityPercentBps": [
                17
            ],
            "equityUnits": [
                17
            ],
            "equityUnitType": [
                8
            ],
            "equityValueCents": [
                2
            ],
            "annualBonusPercentBps": [
                17
            ],
            "flexibility": [
                254
            ],
            "expiresAt": [
                163
            ],
            "cashCurrency": [
                8
            ],
            "commissionCurrency": [
                8
            ],
            "customization": [
                254
            ],
            "startDate": [
                163
            ],
            "jobDescription": [
                8
            ],
            "offerSignedAt": [
                163
            ],
            "selectedPackageId": [
                102
            ],
            "onboarding": [
                254
            ],
            "selectedTemplateName": [
                8
            ],
            "documentVariables": [
                254
            ],
            "generatedDocumentName": [
                8
            ],
            "outcome": [
                8
            ],
            "rejectReasonType": [
                8
            ],
            "rejectReasonNote": [
                8
            ],
            "isTemplate": [
                22
            ],
            "templateName": [
                8
            ],
            "templateDescription": [
                8
            ],
            "competingOffers": [
                122
            ],
            "omittedGenericMessages": [
                291
            ],
            "paygeo": [
                240
            ],
            "createdFromTemplate": [
                332
            ],
            "offerRoleMetadata": [
                325
            ],
            "compensationType": [
                8
            ],
            "hourlyWageCents": [
                2
            ],
            "tier": [
                8
            ],
            "createdBy": [
                365
            ],
            "invitees": [
                318
            ],
            "roles": [
                386
            ],
            "__typename": [
                8
            ]
        },
        "OfferUpdateInput": {
            "id": [
                17
            ],
            "candidateFullName": [
                8
            ],
            "candidateEmail": [
                8
            ],
            "role": [
                17
            ],
            "tier": [
                8
            ],
            "position": [
                8
            ],
            "location": [
                8
            ],
            "jobDescription": [
                8
            ],
            "expiresAt": [
                163
            ],
            "startDate": [
                163
            ],
            "recruiterFullName": [
                8
            ],
            "recruiterEmail": [
                8
            ],
            "recruiterPhone": [
                8
            ],
            "managerFullName": [
                8
            ],
            "managerEmail": [
                8
            ],
            "managerPosition": [
                8
            ],
            "cashCurrency": [
                8
            ],
            "vestingSchedule": [
                8
            ],
            "equityUnitType": [
                8
            ],
            "earlyExercise": [
                22
            ],
            "commissionStructure": [
                17
            ],
            "benefitPackage": [
                17
            ],
            "benefitPackageType": [
                336
            ],
            "customization": [
                254
            ],
            "documentVariables": [
                254
            ],
            "compensationPackages": [
                101
            ],
            "bonuses": [
                48
            ],
            "messages": [
                290
            ],
            "competingOffers": [
                121
            ],
            "omittedGenericMessages": [
                335
            ],
            "selectedTemplateName": [
                8
            ],
            "documentConfiguration": [
                336
            ],
            "designatedSigner": [
                8
            ],
            "paygeo": [
                272
            ],
            "offerRoleMetadata": [
                326
            ],
            "compensationType": [
                8
            ],
            "commissionCurrency": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OfficeType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "location": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OmittedGenericOfferInput": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OneToManyInput": {
            "set": [
                195
            ],
            "__typename": [
                8
            ]
        },
        "OperationInfo": {
            "messages": [
                338
            ],
            "__typename": [
                8
            ]
        },
        "OperationMessage": {
            "kind": [
                339
            ],
            "message": [
                8
            ],
            "field": [
                8
            ],
            "code": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OperationMessageKind": {},
        "Ordering": {},
        "OrganizationConfigurationType": {
            "activeContractDate": [
                163
            ],
            "churnedDate": [
                163
            ],
            "disabledDate": [
                163
            ],
            "enableOffers": [
                22
            ],
            "enableTeamView": [
                22
            ],
            "enableLevels": [
                22
            ],
            "enableBenchmarking": [
                22
            ],
            "enableCompensationPlanning": [
                22
            ],
            "enableTotalRewards": [
                22
            ],
            "enableSalaryRangeTransparency": [
                22
            ],
            "enableEquityRangeTransparency": [
                22
            ],
            "enableTcRangeTransparency": [
                22
            ],
            "enableDocumentManager": [
                22
            ],
            "enableJobFamilySharing": [
                22
            ],
            "enableManagerView": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationCreateInput": {
            "companyName": [
                8
            ],
            "fullName": [
                8
            ],
            "email": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationFeatureFlags": {
            "unused": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationIdentificationType": {
            "identifier": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationImportSourceInputPartial": {
            "organization": [
                336
            ],
            "accountToken": [
                8
            ],
            "accountSource": [
                8
            ],
            "accountType": [
                8
            ],
            "accountName": [
                8
            ],
            "accountOriginId": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationImportSourceResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationImportSourceType": {
            "accountToken": [
                8
            ],
            "accountSource": [
                8
            ],
            "accountType": [
                8
            ],
            "accountName": [
                8
            ],
            "createdAt": [
                163
            ],
            "accountOriginId": [
                8
            ],
            "mergeHealthStatus": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationInputPartial": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "notificationConfigurations": [
                299
            ],
            "notificationRecipients": [
                302
            ],
            "docusignToken": [
                254
            ],
            "customization": [
                254
            ],
            "transparency": [
                254
            ],
            "compensationPhilosophy": [
                8
            ],
            "mergeAccountToken": [
                8
            ],
            "mergeHrisAccountToken": [
                8
            ],
            "sharesOutstanding": [
                17
            ],
            "strikePriceCents": [
                17
            ],
            "preferredSharePriceCents": [
                17
            ],
            "flexibility": [
                254
            ],
            "valuationCents": [
                17
            ],
            "exerciseWindow": [
                8
            ],
            "defaultVestingSchedule": [
                8
            ],
            "defaultEquityVestingSchedule": [
                336
            ],
            "designatedFlatfileOwnerEmail": [
                8
            ],
            "totalRewardsCustomization": [
                447
            ],
            "compChartRangeLimit": [
                86
            ],
            "defaultPerformanceRatingSchemeId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationInputType": {
            "id": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationPublicType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "uuid": [
                459
            ],
            "identifier": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "OrganizationType": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "uuid": [
                459
            ],
            "identifier": [
                8
            ],
            "welcomeImagePath": [
                8
            ],
            "overviewImagePath": [
                8
            ],
            "valuesImagePath": [
                8
            ],
            "customersImagePath": [
                8
            ],
            "transparency": [
                254
            ],
            "customization": [
                254
            ],
            "stock": [
                254
            ],
            "exerciseWindow": [
                8
            ],
            "compensationPhilosophy": [
                8
            ],
            "hasMergeAccountToken": [
                22
            ],
            "hasMergeHrisAccountToken": [
                22
            ],
            "hasDocusignToken": [
                22
            ],
            "defaultCurrencyCode": [
                8
            ],
            "commissionStructures": [
                84
            ],
            "notificationConfigurations": [
                300
            ],
            "notificationRecipients": [
                303
            ],
            "performanceratingschemeSet": [
                355
            ],
            "defaultVestingSchedule": [
                8
            ],
            "defaultEquityVestingSchedule": [
                511
            ],
            "onboarding": [
                254
            ],
            "flexibility": [
                254
            ],
            "dataTableColumnPreset": [
                159
            ],
            "totalRewardsCustomization": [
                446
            ],
            "compChartRangeLimit": [
                85
            ],
            "defaultPerformanceRatingScheme": [
                355
            ],
            "jobTracks": [
                263
            ],
            "sharesOutstanding": [
                2
            ],
            "strikePriceCents": [
                17
            ],
            "preferredSharePriceCents": [
                17
            ],
            "valuationCents": [
                2
            ],
            "config": [
                341
            ],
            "designatedFlatfileOwnerEmail": [
                8
            ],
            "benefitPackages": [
                46
            ],
            "users": [
                293
            ],
            "benchmarkingImports": [
                41
            ],
            "flatfileImports": [
                237
            ],
            "featureFlags": [
                343
            ],
            "mergeAccountToken": [
                8
            ],
            "docusignToken": [
                254
            ],
            "defaultVestingScheduleType": [
                511
            ],
            "hrisAccounts": [
                347
            ],
            "securityIntegrationConfigs": [
                423
            ],
            "__typename": [
                8
            ]
        },
        "PerformanceDescription": {
            "rating": [
                17
            ],
            "description": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "PerformanceRatingInput": {
            "employee": [
                459
            ],
            "organization": [
                17
            ],
            "rating": [
                17
            ],
            "effectiveDate": [
                163
            ],
            "performanceRatingScheme": [
                459
            ],
            "status": [
                356
            ],
            "__typename": [
                8
            ]
        },
        "PerformanceRatingInputPartial": {
            "id": [
                459
            ],
            "rating": [
                17
            ],
            "effectiveDate": [
                163
            ],
            "performanceRatingScheme": [
                459
            ],
            "status": [
                356
            ],
            "__typename": [
                8
            ]
        },
        "PerformanceRatingSchemeType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "organization": [
                350
            ],
            "scaleSize": [
                17
            ],
            "scaleStart": [
                17
            ],
            "scaleDescription": [
                392
            ],
            "__typename": [
                8
            ]
        },
        "PerformanceRatingStatus": {},
        "PerformanceRatingType": {
            "id": [
                459
            ],
            "employee": [
                219
            ],
            "organization": [
                350
            ],
            "rating": [
                17
            ],
            "effectiveDate": [
                163
            ],
            "performanceRatingScheme": [
                355
            ],
            "status": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "PhoneNumberType": {
            "phoneNumberType": [
                8
            ],
            "value": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "Place": {
            "id": [
                17
            ],
            "name": [
                8
            ],
            "on_CityType": [
                73
            ],
            "on_ContinentType": [
                128
            ],
            "on_CountryType": [
                130
            ],
            "__typename": [
                8
            ]
        },
        "PlannerBudgetRollup": {
            "plannerName": [
                8
            ],
            "plannerId": [
                17
            ],
            "salaryBudget": [
                17
            ],
            "salarySpend": [
                17
            ],
            "equityBudget": [
                17
            ],
            "equitySpend": [
                17
            ],
            "variableCompBudget": [
                17
            ],
            "variableCompSpend": [
                17
            ],
            "bonusBudget": [
                17
            ],
            "bonusSpend": [
                17
            ],
            "averageSalaryIncrease": [
                2
            ],
            "promotionsGiven": [
                17
            ],
            "employeeCount": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ProfileFilter": {
            "mustHaveEmployee": [
                22
            ],
            "mustHaveAuthUser": [
                22
            ],
            "AND": [
                361
            ],
            "OR": [
                361
            ],
            "NOT": [
                361
            ],
            "__typename": [
                8
            ]
        },
        "ProfileGroupType": {},
        "ProfileInputPartial": {
            "id": [
                17
            ],
            "group": [
                8
            ],
            "impersonatableUserIds": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ProfileLinkEmployeeInput": {
            "profileId": [
                17
            ],
            "employeeId": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "ProfileType": {
            "id": [
                17
            ],
            "email": [
                8
            ],
            "fullName": [
                8
            ],
            "imagePath": [
                8
            ],
            "organization": [
                350
            ],
            "group": [
                362
            ],
            "title": [
                8
            ],
            "hasAccessToApplication": [
                22
            ],
            "permissions": [
                506
            ],
            "impersonatableUserIds": [
                504
            ],
            "objectPermissions": [
                505
            ],
            "hasManagerViewEnabled": [
                22
            ],
            "groups": [
                503
            ],
            "employee": [
                219
            ],
            "__typename": [
                8
            ]
        },
        "ProfilesFilteredByAccessType": {
            "allowed": [
                365
            ],
            "notAllowed": [
                365
            ],
            "__typename": [
                8
            ]
        },
        "PublishRoleType": {
            "id": [
                17
            ],
            "title": [
                8
            ],
            "levelNum": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "PublishRolesInput": {
            "roles": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "PublishRolesResponse": {
            "publishedRoles": [
                17
            ],
            "successfullyPublished": [
                367
            ],
            "successfullyDeleted": [
                367
            ],
            "errors": [
                367
            ],
            "__typename": [
                8
            ]
        },
        "PublishRolesV2Payload": {
            "on_PublishRolesResponse": [
                369
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "Query": {
            "computeCompaRatio": [
                89,
                {
                    "input": [
                        88,
                        "CompaRatioInput!"
                    ]
                }
            ],
            "bulkComputeCompaRatio": [
                51,
                {
                    "input": [
                        52,
                        "BulkEmployeeCompaRatiosInput!"
                    ]
                }
            ],
            "listOfEmployees": [
                436
            ],
            "organizationTableColumnsByTable": [
                297,
                {
                    "input": [
                        442,
                        "TableColumnsInput!"
                    ]
                }
            ],
            "tableColumnsConfigurationByTable": [
                297,
                {
                    "input": [
                        442,
                        "TableColumnsInput!"
                    ]
                }
            ],
            "employee": [
                222,
                {
                    "filters": [
                        210,
                        "EmployeeFilter!"
                    ]
                }
            ],
            "employeesPublic": [
                219,
                {
                    "filters": [
                        210
                    ]
                }
            ],
            "me": [
                365
            ],
            "hiddenEmployees": [
                222
            ],
            "levelsMetadata": [
                267
            ],
            "messagesByOfferUuid": [
                291,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "offerByUUID": [
                332,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "offerTemplates": [
                332,
                {
                    "filter": [
                        331
                    ]
                }
            ],
            "organization": [
                351,
                {
                    "pk": [
                        17
                    ]
                }
            ],
            "jobFamilyByUuidV3": [
                260,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "totalRewardsStatement": [
                296,
                {
                    "id": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "totalRewardsStatements": [
                296
            ],
            "offers": [
                332,
                {
                    "filters": [
                        313
                    ],
                    "order": [
                        320
                    ]
                }
            ],
            "offer": [
                332,
                {
                    "pk": [
                        195,
                        "ID!"
                    ]
                }
            ],
            "candidate": [
                67,
                {
                    "id": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "profiles": [
                365,
                {
                    "filters": [
                        361
                    ]
                }
            ],
            "profilesByAccessStatus": [
                366,
                {
                    "filters": [
                        361
                    ]
                }
            ],
            "flatfileImports": [
                237,
                {
                    "filters": [
                        234
                    ]
                }
            ],
            "authUserLogins": [
                19,
                {
                    "filters": [
                        361
                    ]
                }
            ],
            "employeesAvailableToUser": [
                224,
                {
                    "filters": [
                        210
                    ]
                }
            ],
            "employees": [
                222,
                {
                    "filters": [
                        210
                    ]
                }
            ],
            "inactiveEmployees": [
                222
            ],
            "employeesAffectedByBandChange": [
                214,
                {
                    "input": [
                        213,
                        "EmployeeLocationSearchInputType!"
                    ]
                }
            ],
            "tasks": [
                444
            ],
            "meritMatrices": [
                288
            ],
            "profile": [
                365,
                {
                    "pk": [
                        17,
                        "Int!"
                    ]
                }
            ],
            "documentManagementConfigurations": [
                199
            ],
            "analyticsByOfferUuid": [
                311,
                {
                    "offerUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "auditLogEntriesByOfferUUID": [
                16,
                {
                    "offerUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "offerByPublicUUID": [
                332,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "offerByUUIDServerSide": [
                332,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "offerTemplateById": [
                332,
                {
                    "id": [
                        17,
                        "Int!"
                    ]
                }
            ],
            "offerTemplateByUuid": [
                332,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "originalUser": [
                365
            ],
            "organizationIdentifier": [
                344,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "jobFamilyByUuid": [
                259,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "jobFamilies": [
                259
            ],
            "roles": [
                386
            ],
            "rolesV2": [
                388
            ],
            "jobFamiliesV2": [
                261
            ],
            "jobFamilyByUuidV2": [
                261,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "rolesByJobFamilyUuid": [
                386,
                {
                    "jobFamilyUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenario": [
                98,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenarios": [
                98
            ],
            "compensationScenarioDataDump": [
                117,
                {
                    "scenarioUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenarioDataDumpV2": [
                118,
                {
                    "scenarioUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenarioDetail": [
                119,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenarioDetailPhoenixRising": [
                120,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationPhilosophy": [
                104,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "publicCompensationPhilosophy": [
                104,
                {
                    "publicUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "messagesByOfferUuidServerside": [
                291,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "genericMessages": [
                291
            ],
            "documentTemplates": [
                439
            ],
            "mostRecentRenderedOfferDocument": [
                439,
                {
                    "offerUuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "vestingSchedules": [
                511
            ],
            "compensationScenarioAuditLog": [
                14,
                {
                    "uuid": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "benefitPackage": [
                46,
                {
                    "benefitPackageId": [
                        17,
                        "Int!"
                    ]
                }
            ],
            "managerEmployeesTree": [
                279
            ],
            "levelsSearch": [
                270,
                {
                    "filter": [
                        269,
                        "LevelsSearchFilter!"
                    ]
                }
            ],
            "locations": [
                277,
                {
                    "filter": [
                        276,
                        "LocationSearchFilter!"
                    ]
                }
            ],
            "bandsByLocations": [
                26,
                {
                    "filter": [
                        25,
                        "BandLocationSearchFilter!"
                    ]
                }
            ],
            "scenarioEmployee": [
                421,
                {
                    "id": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "compensationScenarioEmployeeActivityLog": [
                217,
                {
                    "id": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "exchangeRateById": [
                232,
                {
                    "id": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "exchangeRatesByCompScenarioId": [
                232,
                {
                    "compScenarioId": [
                        459,
                        "UUID!"
                    ]
                }
            ],
            "latestExchangeRate": [
                232
            ],
            "__typename": [
                8
            ]
        },
        "RecordTotalRewardsAnalyticsPayload": {
            "on_TotalRewardsStatementType": [
                456
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "RecruiterType": {
            "id": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "email": [
                8
            ],
            "accessRole": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RelinkOrganizationImportSourcePayload": {
            "on_OrganizationImportSourceResponse": [
                346
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "RemoteType": {
            "path": [
                8
            ],
            "data": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "RevertToOriginalUserInput": {
            "userEmail": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RevertToOriginalUserPayload": {
            "on_RevertToOriginalUserResponse": [
                378
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "RevertToOriginalUserResponse": {
            "redirectUrl": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RevokeAccessInput": {
            "profileIds": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "RevokeAccessResponse": {
            "revokeAccessResponses": [
                382
            ],
            "__typename": [
                8
            ]
        },
        "RevokeUserAccessPayload": {
            "on_RevokeAccessResponse": [
                380
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "RevokeUserAccessResponse": {
            "profileId": [
                17
            ],
            "success": [
                22
            ],
            "message": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RoleChangeCompaRatio": {
            "jobFamilyName": [
                8
            ],
            "levelName": [
                8
            ],
            "bandName": [
                8
            ],
            "bandType": [
                8
            ],
            "min": [
                17
            ],
            "max": [
                17
            ],
            "target": [
                17
            ],
            "targetPercentage": [
                165
            ],
            "midpoint": [
                2
            ],
            "compaRatio": [
                2
            ],
            "adjustedCompaRatio": [
                2
            ],
            "currencyCode": [
                8
            ],
            "payGeoLocation": [
                240
            ],
            "targetVariableComp": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "RoleInputType": {
            "id": [
                17
            ],
            "title": [
                8
            ],
            "level": [
                8
            ],
            "jobCode": [
                8
            ],
            "jobFamily": [
                8
            ],
            "description": [
                8
            ],
            "lowerBoundAnnualSalaryCents": [
                17
            ],
            "upperBoundAnnualSalaryCents": [
                17
            ],
            "targetAnnualSalaryCents": [
                17
            ],
            "lowerBoundEquityValueCents": [
                17
            ],
            "upperBoundEquityValueCents": [
                17
            ],
            "targetEquityValueCents": [
                17
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityCurrencyCode": [
                8
            ],
            "jobTrack": [
                8
            ],
            "track": [
                262
            ],
            "levelNum": [
                17
            ],
            "isHidden": [
                22
            ],
            "tier": [
                8
            ],
            "jobRoleTitles": [
                8
            ],
            "organization": [
                349
            ],
            "family": [
                257
            ],
            "draftRole": [
                384
            ],
            "benchmarkImport": [
                459
            ],
            "benchmarkPercentileBps": [
                17
            ],
            "equityBenchmarkPercentileBps": [
                17
            ],
            "notes": [
                8
            ],
            "bands": [
                134
            ],
            "shouldBeDisplayed": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "RolePublicType": {
            "id": [
                17
            ],
            "title": [
                8
            ],
            "jobFamily": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RoleType": {
            "id": [
                17
            ],
            "uuid": [
                459
            ],
            "organization": [
                350
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "title": [
                8
            ],
            "level": [
                8
            ],
            "jobCode": [
                8
            ],
            "family": [
                259
            ],
            "jobFamily": [
                8
            ],
            "description": [
                8
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityCurrencyCode": [
                8
            ],
            "deletedAt": [
                163
            ],
            "tier": [
                8
            ],
            "jobTrack": [
                8
            ],
            "levelNum": [
                17
            ],
            "isHidden": [
                22
            ],
            "jobRoleTitles": [
                8
            ],
            "shouldBeDisplayed": [
                22
            ],
            "jobCodes": [
                8
            ],
            "jobTrackLevelDisplay": [
                8
            ],
            "jobTrackLevelTitleDisplay": [
                8
            ],
            "jobTrackLevelTitleFamilyDisplay": [
                8
            ],
            "trackId": [
                8
            ],
            "trackName": [
                8
            ],
            "bands": [
                28
            ],
            "draftRole": [
                386
            ],
            "benchmarkImport": [
                41
            ],
            "benchmarkPercentileBps": [
                17
            ],
            "equityBenchmarkPercentileBps": [
                17
            ],
            "lowerBoundAnnualSalaryCents": [
                17
            ],
            "upperBoundAnnualSalaryCents": [
                17
            ],
            "targetAnnualSalaryCents": [
                17
            ],
            "lowerBoundEquityValueCents": [
                17
            ],
            "upperBoundEquityValueCents": [
                17
            ],
            "targetEquityValueCents": [
                17
            ],
            "notes": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RoleTypeV3": {
            "id": [
                17
            ],
            "uuid": [
                459
            ],
            "organization": [
                350
            ],
            "family": [
                259
            ],
            "notes": [
                8
            ],
            "description": [
                8
            ],
            "jobTrack": [
                8
            ],
            "title": [
                8
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityCurrencyCode": [
                8
            ],
            "isHidden": [
                22
            ],
            "shouldBeDisplayed": [
                22
            ],
            "hasChangesToPublish": [
                22
            ],
            "draftRoleId": [
                17
            ],
            "jobRoleTitles": [
                8
            ],
            "lowerBoundAnnualSalaryCents": [
                17
            ],
            "upperBoundAnnualSalaryCents": [
                17
            ],
            "targetAnnualSalaryCents": [
                17
            ],
            "lowerBoundEquityValueCents": [
                17
            ],
            "upperBoundEquityValueCents": [
                17
            ],
            "targetEquityValueCents": [
                17
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "deletedAt": [
                163
            ],
            "benchmarkPercentileBps": [
                17
            ],
            "equityBenchmarkPercentileBps": [
                17
            ],
            "tier": [
                8
            ],
            "jobCode": [
                8
            ],
            "level": [
                8
            ],
            "jobFamily": [
                8
            ],
            "benchmarkImport": [
                41
            ],
            "levelNum": [
                17
            ],
            "jobTrackLevelTitleFamilyDisplay": [
                8
            ],
            "jobTrackLevelTitleDisplay": [
                8
            ],
            "jobTrackLevelDisplay": [
                8
            ],
            "trackRank": [
                17
            ],
            "trackName": [
                8
            ],
            "trackAbbreviation": [
                8
            ],
            "trackId": [
                8
            ],
            "bands": [
                29
            ],
            "draftRole": [
                387
            ],
            "__typename": [
                8
            ]
        },
        "RoleV2Type": {
            "id": [
                17
            ],
            "uuid": [
                459
            ],
            "organization": [
                350
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "title": [
                8
            ],
            "level": [
                8
            ],
            "jobCode": [
                8
            ],
            "family": [
                259
            ],
            "jobFamily": [
                8
            ],
            "description": [
                8
            ],
            "salaryCurrencyCode": [
                8
            ],
            "equityCurrencyCode": [
                8
            ],
            "deletedAt": [
                163
            ],
            "tier": [
                8
            ],
            "jobTrack": [
                8
            ],
            "levelNum": [
                17
            ],
            "isHidden": [
                22
            ],
            "jobRoleTitles": [
                8
            ],
            "shouldBeDisplayed": [
                22
            ],
            "bands": [
                30
            ],
            "draftRole": [
                388
            ],
            "benchmarkImport": [
                41
            ],
            "benchmarkPercentileBps": [
                17
            ],
            "equityBenchmarkPercentileBps": [
                17
            ],
            "lowerBoundAnnualSalaryCents": [
                17
            ],
            "upperBoundAnnualSalaryCents": [
                17
            ],
            "targetAnnualSalaryCents": [
                17
            ],
            "lowerBoundEquityValueCents": [
                17
            ],
            "upperBoundEquityValueCents": [
                17
            ],
            "targetEquityValueCents": [
                17
            ],
            "notes": [
                8
            ],
            "jobTrackLevelTitleFamilyDisplay": [
                8
            ],
            "jobTrackLevelTitleDisplay": [
                8
            ],
            "jobTrackLevelDisplay": [
                8
            ],
            "trackId": [
                459
            ],
            "trackRank": [
                17
            ],
            "trackName": [
                8
            ],
            "trackAbbreviation": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "RolesSummary": {
            "numberOfTracks": [
                17
            ],
            "numberOfRoles": [
                17
            ],
            "hasNewLevelsToPublish": [
                22
            ],
            "rolesSummaryByJobTrack": [
                390
            ],
            "__typename": [
                8
            ]
        },
        "RolesSummaryByJobTrack": {
            "trackId": [
                459
            ],
            "jobTrack": [
                8
            ],
            "jobTrackName": [
                8
            ],
            "jobTrackAbbr": [
                8
            ],
            "jobTrackRank": [
                17
            ],
            "count": [
                17
            ],
            "minLevel": [
                17
            ],
            "maxLevel": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "SalaryCardDataType": {
            "effectiveDate": [
                8
            ],
            "annualizedBaseSalaryCents": [
                17
            ],
            "jobTitle": [
                8
            ],
            "currentEmployment": [
                22
            ],
            "futureEmployment": [
                22
            ],
            "initialEmployment": [
                22
            ],
            "payRateInCents": [
                17
            ],
            "payPeriod": [
                8
            ],
            "payFrequency": [
                8
            ],
            "payCurrency": [
                8
            ],
            "differenceFromPreviousEmployment": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ScaleDescription": {
            "mappings": [
                352
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioApprovalValue": {},
        "ScenarioApproverInput": {
            "id": [
                459
            ],
            "firstViewComplete": [
                22
            ],
            "approvalType": [
                393
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioApproverType": {
            "id": [
                459
            ],
            "scenario": [
                98
            ],
            "profile": [
                365
            ],
            "approvalType": [
                393
            ],
            "firstViewComplete": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioBudgetCompType": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "quantity": [
                2
            ],
            "currencyCode": [
                8
            ],
            "unitDescriptor": [
                8
            ],
            "equityUnitType": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioBudgetInput": {
            "scenario": [
                459
            ],
            "name": [
                8
            ],
            "quantity": [
                2
            ],
            "currencyCode": [
                8
            ],
            "unitDescriptor": [
                50
            ],
            "scenarioEmployee": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioBudgetInputPartial": {
            "id": [
                459
            ],
            "name": [
                8
            ],
            "quantity": [
                2
            ],
            "unitDescriptor": [
                50
            ],
            "scenarioEmployee": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioBudgetType": {
            "id": [
                459
            ],
            "scenario": [
                98
            ],
            "name": [
                8
            ],
            "quantity": [
                2
            ],
            "currencyCode": [
                8
            ],
            "unitDescriptor": [
                50
            ],
            "scenarioEmployee": [
                421
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioCommentUpdateInput": {
            "scenarioEmployeeId": [
                459
            ],
            "id": [
                17
            ],
            "content": [
                8
            ],
            "notificationEmails": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeAbridgedCommentType": {
            "content": [
                8
            ],
            "authorName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeAccessType": {},
        "ScenarioEmployeeAlertCompScenarioType": {
            "id": [
                459
            ],
            "scenarioEmployeeId": [
                459
            ],
            "status": [
                8
            ],
            "type": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeAlertType": {
            "id": [
                459
            ],
            "scenarioEmployeeId": [
                459
            ],
            "status": [
                8
            ],
            "type": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApprovalValue": {},
        "ScenarioEmployeeApproverBulkInputType": {
            "approvalType": [
                405
            ],
            "scenarioId": [
                459
            ],
            "approvals": [
                409
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApproverBulkResponse": {
            "updatedApprovalsCount": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApproverInput": {
            "id": [
                459
            ],
            "profile": [
                17
            ],
            "profileId": [
                17
            ],
            "scenarioEmployee": [
                459
            ],
            "approvalType": [
                405
            ],
            "approverRank": [
                17
            ],
            "submittedBy": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApproverInputPartial": {
            "id": [
                459
            ],
            "approvalType": [
                405
            ],
            "approverRank": [
                17
            ],
            "submittedBy": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApproverType": {
            "id": [
                459
            ],
            "scenarioEmployee": [
                421
            ],
            "profile": [
                365
            ],
            "approvalType": [
                405
            ],
            "approverRank": [
                17
            ],
            "submittedBy": [
                365
            ],
            "updatedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeApproverTypeV8": {
            "id": [
                459
            ],
            "approvalType": [
                8
            ],
            "profileId": [
                17
            ],
            "approverRank": [
                17
            ],
            "submittedById": [
                17
            ],
            "profileFullName": [
                8
            ],
            "profileEmail": [
                8
            ],
            "submittedByFullName": [
                8
            ],
            "submittedByEmail": [
                8
            ],
            "updatedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeCommentCreateInput": {
            "scenarioEmployeeId": [
                459
            ],
            "content": [
                8
            ],
            "notificationEmails": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeCommentType": {
            "id": [
                17
            ],
            "author": [
                365
            ],
            "scenarioEmployeeId": [
                459
            ],
            "content": [
                8
            ],
            "createdAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeContributor": {
            "profile": [
                365
            ],
            "accessType": [
                402
            ],
            "isAdmin": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeInputPartial": {
            "employeeId": [
                459
            ],
            "scenarioId": [
                459
            ],
            "status": [
                420
            ],
            "promotionEligible": [
                419
            ],
            "proposedLevelId": [
                17
            ],
            "approvers": [
                408
            ],
            "customBudgetIneligibilityReason": [
                8
            ],
            "additionalData": [
                0
            ],
            "proposedTier": [
                8
            ],
            "proposedLocation": [
                272
            ],
            "proposedTitle": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeInviteInput": {
            "id": [
                459
            ],
            "employeeId": [
                459
            ],
            "scenarioId": [
                459
            ],
            "profileId": [
                17
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeInvitePayload": {
            "on_ScenarioEmployeeInviteResponse": [
                418
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeeInviteResponse": {
            "scenarioEmployee": [
                421
            ],
            "invite": [
                253
            ],
            "__typename": [
                8
            ]
        },
        "ScenarioEmployeePromotionEligible": {},
        "ScenarioEmployeeStatus": {},
        "ScenarioEmployeeType": {
            "id": [
                459
            ],
            "employee": [
                222
            ],
            "compensationChangeScenario": [
                98
            ],
            "promotionEligible": [
                419
            ],
            "status": [
                420
            ],
            "proposedLevel": [
                386
            ],
            "approvers": [
                410
            ],
            "scenariobudgetSet": [
                399
            ],
            "customBudgetIneligibilityReason": [
                8
            ],
            "proposedTier": [
                8
            ],
            "proposedLocation": [
                240
            ],
            "alerts": [
                404
            ],
            "proposedTitle": [
                8
            ],
            "suggestedAdjustmentCents": [
                17
            ],
            "suggestedAdjustmentType": [
                8
            ],
            "additionalData": [
                153
            ],
            "sharedWith": [
                414
            ],
            "comments": [
                413
            ],
            "__typename": [
                8
            ]
        },
        "SecurityDataclassType": {
            "vestingSchedule": [
                510
            ],
            "certificateIdentifier": [
                8
            ],
            "issueDate": [
                8
            ],
            "issuedQuantity": [
                17
            ],
            "sharePriceCents": [
                17
            ],
            "hasEarlyExercise": [
                22
            ],
            "isExercisable": [
                22
            ],
            "isVestable": [
                22
            ],
            "vestingStartDate": [
                8
            ],
            "acceptanceStatus": [
                8
            ],
            "status": [
                8
            ],
            "securityType": [
                8
            ],
            "createdAt": [
                8
            ],
            "awardType": [
                8
            ],
            "shareClass": [
                8
            ],
            "exercisePriceCents": [
                17
            ],
            "vestingEvents": [
                426
            ],
            "__typename": [
                8
            ]
        },
        "SecurityIntegrationConfigType": {
            "id": [
                459
            ],
            "integrationSource": [
                8
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "lastSyncAt": [
                163
            ],
            "isActive": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "SecurityType": {
            "uuid": [
                459
            ],
            "stakeholder": [
                438
            ],
            "vestingSchedule": [
                511
            ],
            "vestingScheduleName": [
                8
            ],
            "certificateIdentifier": [
                8
            ],
            "certificateName": [
                8
            ],
            "shareClass": [
                8
            ],
            "awardType": [
                8
            ],
            "issueDate": [
                163
            ],
            "issuedQuantity": [
                17
            ],
            "sharePriceCents": [
                17
            ],
            "hasEarlyExercise": [
                22
            ],
            "isExercisable": [
                22
            ],
            "isVestable": [
                22
            ],
            "exercisePriceCents": [
                17
            ],
            "vestingStartDate": [
                163
            ],
            "acceptanceStatus": [
                8
            ],
            "status": [
                8
            ],
            "securityType": [
                8
            ],
            "importSource": [
                237
            ],
            "createdAt": [
                163
            ],
            "soldQuantity": [
                17
            ],
            "donatedTransferredQuantity": [
                17
            ],
            "canceledQuantity": [
                17
            ],
            "vestingEvents": [
                427
            ],
            "ingestionSourceName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "SecurityTypeV8": {
            "issuedQuantity": [
                17
            ],
            "securityType": [
                8
            ],
            "vestingScheduleUuid": [
                459
            ],
            "vestingStartDate": [
                163
            ],
            "issueDate": [
                163
            ],
            "sharePriceCents": [
                17
            ],
            "vestingScheduleName": [
                8
            ],
            "vestingEvents": [
                427
            ],
            "__typename": [
                8
            ]
        },
        "SecurityVestingEventDataClassType": {
            "vestDate": [
                8
            ],
            "issuedQuantity": [
                17
            ],
            "soldQuantity": [
                17
            ],
            "donatedTransferredQuantity": [
                17
            ],
            "canceledQuantity": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "SecurityVestingEventType": {
            "vestDate": [
                8
            ],
            "issuedQuantity": [
                17
            ],
            "soldQuantity": [
                17
            ],
            "donatedTransferredQuantity": [
                17
            ],
            "canceledQuantity": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "SendDocumentInput": {
            "offerUuid": [
                459
            ],
            "isTest": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "SendDocumentPayload": {
            "on_SendDocumentResponse": [
                430
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "SendDocumentResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "SendWelcomeEmailInput": {
            "emails": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "SendWelcomeEmailPayload": {
            "on_SendWelcomeEmailResponse": [
                433
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "SendWelcomeEmailResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "SetStatementVisibilityPayload": {
            "on_TotalRewardsStatementType": [
                456
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "SimpleEmployeeType": {
            "id": [
                459
            ],
            "displayFullName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "SimpleEmployeeTypeList": {
            "employees": [
                435
            ],
            "__typename": [
                8
            ]
        },
        "StakeholderDataclassType": {
            "securitySet": [
                422
            ],
            "__typename": [
                8
            ]
        },
        "StakeholderType": {
            "uuid": [
                459
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "name": [
                8
            ],
            "email": [
                8
            ],
            "securitySet": [
                424
            ],
            "__typename": [
                8
            ]
        },
        "StorageObjectType": {
            "name": [
                8
            ],
            "id": [
                8
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "bucketId": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "SuggestedAdjustmentType": {},
        "TableColumnSettingUpdateInput": {
            "tableName": [
                8
            ],
            "tableColumnName": [
                8
            ],
            "isEnabled": [
                22
            ],
            "isVisible": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "TableColumnsInput": {
            "tableName": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "TaskType": {
            "id": [
                459
            ],
            "resolvedAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "status": [
                8
            ],
            "lastShown": [
                163
            ],
            "description": [
                8
            ],
            "on_DataSyncTaskType": [
                156
            ],
            "on_LocationMismatchTaskType": [
                275
            ],
            "on_MergeHealthTaskType": [
                286
            ],
            "__typename": [
                8
            ]
        },
        "TasksResponse": {
            "tasks": [
                157
            ],
            "__typename": [
                8
            ]
        },
        "TotalCompTargetType": {
            "target": [
                17
            ],
            "currencyCode": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsCustomization": {
            "headerColor": [
                8
            ],
            "showJobLadder": [
                22
            ],
            "showEmployeeInBand": [
                22
            ],
            "enableCompModeling": [
                22
            ],
            "adminMessage": [
                8
            ],
            "adminMessageUpdatedAt": [
                8
            ],
            "liteShowPerformanceRatings": [
                22
            ],
            "liteShowYtdCompChange": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsCustomizationInput": {
            "headerColor": [
                8
            ],
            "showJobLadder": [
                22
            ],
            "showEmployeeInBand": [
                22
            ],
            "enableCompModeling": [
                22
            ],
            "adminMessage": [
                8
            ],
            "adminMessageUpdatedAt": [
                8
            ],
            "liteShowPerformanceRatings": [
                22
            ],
            "liteShowYtdCompChange": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsData": {
            "currentCompensationCents": [
                17
            ],
            "currentJobTitle": [
                8
            ],
            "employments": [
                226
            ],
            "currentRoleId": [
                17
            ],
            "currentRoleTitle": [
                8
            ],
            "currentJobFamily": [
                8
            ],
            "currentAnnualBonusCents": [
                17
            ],
            "currentJobLevel": [
                8
            ],
            "currentLevelTier": [
                8
            ],
            "currentBenefitPackageId": [
                17
            ],
            "previousJobFamily": [
                8
            ],
            "previousJobTitle": [
                8
            ],
            "previousRoleId": [
                17
            ],
            "previousRoleTitle": [
                8
            ],
            "previousLevelTier": [
                8
            ],
            "previousCompensationCents": [
                17
            ],
            "previousAnnualBonusCents": [
                17
            ],
            "previousEquity": [
                17
            ],
            "previousJobLevel": [
                8
            ],
            "stakeholder": [
                437
            ],
            "adminMessage": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsOrgConfig": {
            "orgIdentifier": [
                8
            ],
            "compensationPhilosophy": [
                8
            ],
            "showJobLadder": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementAnalyticsInput": {
            "statementId": [
                459
            ],
            "sessionStartTime": [
                17
            ],
            "sessionEndTime": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementGenerationInput": {
            "employeeIds": [
                459
            ],
            "isVisible": [
                22
            ],
            "adminMessage": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementGenerationResponse": {
            "generatedStatements": [
                456
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementInputPartial": {
            "id": [
                459
            ],
            "isVisible": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementInputPartialBulk": {
            "updates": [
                453
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementInputPartialBulkResponse": {
            "updatedStatements": [
                456
            ],
            "__typename": [
                8
            ]
        },
        "TotalRewardsStatementType": {
            "id": [
                459
            ],
            "employee": [
                219
            ],
            "isVisible": [
                22
            ],
            "createdAt": [
                163
            ],
            "totalRewardsData": [
                448
            ],
            "employeeLastViewedAt": [
                163
            ],
            "totalEmployeeViewCount": [
                17
            ],
            "averageSessionLengthSeconds": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "TrackInputType": {
            "id": [
                459
            ],
            "abbreviation": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "URLType": {
            "urlType": [
                8
            ],
            "value": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UUID": {},
        "UUIDFilterLookup": {
            "exact": [
                459
            ],
            "iExact": [
                459
            ],
            "contains": [
                459
            ],
            "iContains": [
                459
            ],
            "inList": [
                459
            ],
            "gt": [
                459
            ],
            "gte": [
                459
            ],
            "lt": [
                459
            ],
            "lte": [
                459
            ],
            "startsWith": [
                459
            ],
            "iStartsWith": [
                459
            ],
            "endsWith": [
                459
            ],
            "iEndsWith": [
                459
            ],
            "range": [
                459
            ],
            "isNull": [
                22
            ],
            "regex": [
                8
            ],
            "iRegex": [
                8
            ],
            "nExact": [
                459
            ],
            "nIExact": [
                459
            ],
            "nContains": [
                459
            ],
            "nIContains": [
                459
            ],
            "nInList": [
                459
            ],
            "nGt": [
                459
            ],
            "nGte": [
                459
            ],
            "nLt": [
                459
            ],
            "nLte": [
                459
            ],
            "nStartsWith": [
                459
            ],
            "nIStartsWith": [
                459
            ],
            "nEndsWith": [
                459
            ],
            "nIEndsWith": [
                459
            ],
            "nRange": [
                459
            ],
            "nIsNull": [
                22
            ],
            "nRegex": [
                8
            ],
            "nIRegex": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UUIDRelatedObjectInput": {
            "add": [
                459
            ],
            "set": [
                459
            ],
            "remove": [
                459
            ],
            "__typename": [
                8
            ]
        },
        "UpdateApprovalEmployeePayload": {
            "on_ScenarioEmployeeApproverType": [
                410
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateBenchmarkImportFiltersPayload": {
            "on_OrganizationType": [
                351
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateBenefitPackagePayload": {
            "on_BenefitPackageType": [
                46
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateCommentPayload": {
            "on_ScenarioEmployeeCommentType": [
                413
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateCommissionStructurePayload": {
            "on_CommissionStructureType": [
                84
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateCompensationPhilosophyInput": {
            "uuid": [
                459
            ],
            "philosophy": [
                254
            ],
            "__typename": [
                8
            ]
        },
        "UpdateCompensationPhilosophyPayload": {
            "on_CompensationPhilosophyType": [
                104
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateDocumentManagementConfigPayload": {
            "on_DocumentManagementConfigType": [
                198
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateEmployeePayload": {
            "on_EmployeeType": [
                222
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateEmployeesLocationPayload": {
            "on_BulkUpdatePayGeoResponse": [
                57
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateEmployeesPayload": {
            "on_BulkUpdateEmployeesResponse": [
                55
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateFullOfferPayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateJobFamilyPayload": {
            "on_JobFamilyType": [
                259
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateJobTrackPayload": {
            "on_JobTrackType": [
                263
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateLevelsPermissionsInput": {
            "profileId": [
                17
            ],
            "levelsPermissionsSets": [
                258
            ],
            "__typename": [
                8
            ]
        },
        "UpdateLevelsPermissionsPayload": {
            "on_UpdateUserPermissionsResponse": [
                497
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateLocationMismatchTaskInput": {
            "taskId": [
                459
            ],
            "employeeStateIds": [
                459
            ],
            "status": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UpdateLocationMismatchTaskPayload": {
            "on_UpdateTaskStatus": [
                491
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateNotificationRecipientPayload": {
            "on_NotificationRecipientType": [
                303
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateOfferPayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateOfferTemplatePayload": {
            "on_OfferType": [
                332
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateOrganizationPayload": {
            "on_OrganizationType": [
                351
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdatePerformanceRatingPayload": {
            "on_PerformanceRatingType": [
                357
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateProfilePayload": {
            "on_ProfileType": [
                365
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateRolePayload": {
            "on_RoleType": [
                386
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateRolesPayload": {
            "on_UpdateRolesResponse": [
                488
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateRolesResponse": {
            "updatedRoles": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "UpdateScenarioBudgetPayload": {
            "on_ScenarioBudgetType": [
                399
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateScenarioEmployeeCommentPayload": {
            "on_ScenarioEmployeeCommentType": [
                413
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateTaskStatus": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "UpdateTaskStatusInputPartial": {
            "id": [
                459
            ],
            "status": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UpdateTaskStatusPayload": {
            "on_UpdateTaskStatus": [
                491
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateTotalRewardsStatementPayload": {
            "on_TotalRewardsStatementType": [
                456
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateUserPermissionsInput": {
            "profileId": [
                17
            ],
            "contentType": [
                126
            ],
            "permissionCodenames": [
                8
            ],
            "objectIds": [
                282
            ],
            "__typename": [
                8
            ]
        },
        "UpdateUserPermissionsPayload": {
            "on_UpdateUserPermissionsResponse": [
                497
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UpdateUserPermissionsResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "Upload": {},
        "UploadOfferTemplateDocumentInput": {
            "name": [
                8
            ],
            "file": [
                498
            ],
            "__typename": [
                8
            ]
        },
        "UploadOfferTemplateDocumentPayload": {
            "on_StorageObjectType": [
                439
            ],
            "on_OperationInfo": [
                337
            ],
            "__typename": [
                8
            ]
        },
        "UserCreateInput": {
            "fullName": [
                8
            ],
            "email": [
                8
            ],
            "title": [
                8
            ],
            "role": [
                362
            ],
            "sendEmailInvite": [
                22
            ],
            "employee": [
                459
            ],
            "impersonatableUserIds": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "UserDeleteInput": {
            "userEmail": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UserGroupType": {
            "name": [
                8
            ],
            "permissions": [
                506
            ],
            "__typename": [
                8
            ]
        },
        "UserImpersonationMappingType": {
            "actingUserProfile": [
                365
            ],
            "targetUserProfile": [
                365
            ],
            "grantedAt": [
                163
            ],
            "__typename": [
                8
            ]
        },
        "UserObjectPermissionType": {
            "name": [
                8
            ],
            "codename": [
                8
            ],
            "objectId": [
                8
            ],
            "contentType": [
                126
            ],
            "__typename": [
                8
            ]
        },
        "UserPermissionType": {
            "name": [
                8
            ],
            "codename": [
                8
            ],
            "__typename": [
                8
            ]
        },
        "UserType": {
            "username": [
                8
            ],
            "dateJoined": [
                163
            ],
            "profile": [
                365
            ],
            "__typename": [
                8
            ]
        },
        "VariableCommissionJsonInput": {
            "currencyCode": [
                8
            ],
            "effectiveDate": [
                163
            ],
            "payPeriod": [
                8
            ],
            "endDate": [
                163
            ],
            "paymentType": [
                8
            ],
            "payRateInCents": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "VariableCommissionJsonType": {
            "currencyCode": [
                8
            ],
            "effectiveDate": [
                163
            ],
            "payPeriod": [
                8
            ],
            "endDate": [
                163
            ],
            "paymentType": [
                8
            ],
            "payRateInCents": [
                17
            ],
            "__typename": [
                8
            ]
        },
        "VestingScheduleDataclassType": {
            "name": [
                8
            ],
            "description": [
                8
            ],
            "vestingDuration": [
                17
            ],
            "vestingFrequency": [
                8
            ],
            "hasCliff": [
                22
            ],
            "cliffDuration": [
                17
            ],
            "cliffDurationUnit": [
                8
            ],
            "events": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "VestingScheduleType": {
            "uuid": [
                459
            ],
            "createdAt": [
                163
            ],
            "updatedAt": [
                163
            ],
            "name": [
                8
            ],
            "description": [
                8
            ],
            "vestingDuration": [
                17
            ],
            "vestingFrequency": [
                8
            ],
            "hasCliff": [
                22
            ],
            "cliffDuration": [
                17
            ],
            "cliffDurationUnit": [
                8
            ],
            "events": [
                2
            ],
            "__typename": [
                8
            ]
        },
        "Void": {},
        "unifiedCompPlanEmployeeTypeV2": {
            "compPlanEligible": [
                22
            ],
            "employeeId": [
                459
            ],
            "firstName": [
                8
            ],
            "lastName": [
                8
            ],
            "displayFullName": [
                8
            ],
            "employeeWorkLocation": [
                8
            ],
            "workEmail": [
                8
            ],
            "managerId": [
                459
            ],
            "managerFirstName": [
                8
            ],
            "managerLastName": [
                8
            ],
            "managerDisplayFullName": [
                8
            ],
            "managerWorkEmail": [
                8
            ],
            "performanceRatingLabel": [
                8
            ],
            "performanceRatingEffectiveDate": [
                163
            ],
            "performanceRatingValue": [
                17
            ],
            "startDate": [
                163
            ],
            "roleId": [
                17
            ],
            "roleJobFamily": [
                8
            ],
            "roleJobFamilyId": [
                459
            ],
            "roleSalaryCurrencyCode": [
                8
            ],
            "roleLevel": [
                8
            ],
            "roleJobTrack": [
                8
            ],
            "roleLevelNum": [
                17
            ],
            "roleTitle": [
                8
            ],
            "roleTrackAbbr": [
                8
            ],
            "roleJobTrackLevelDisplay": [
                8
            ],
            "roleJobTrackLevelTitleFamilyDisplay": [
                8
            ],
            "roleJobTrackLevelTitleDisplay": [
                8
            ],
            "proposedTitle": [
                8
            ],
            "tier": [
                8
            ],
            "securities": [
                425
            ],
            "currentEmploymentId": [
                459
            ],
            "currentEmploymentPayRateCents": [
                17
            ],
            "currentEmploymentPayCurrency": [
                8
            ],
            "currentEmploymentEffectiveDate": [
                163
            ],
            "currentEmploymentAnnualizedPayRate": [
                17
            ],
            "employmentSet": [
                225
            ],
            "employeeCommissions": [
                207
            ],
            "employeeLocationId": [
                459
            ],
            "employeeLocationCityName": [
                8
            ],
            "employeeLocationCityId": [
                17
            ],
            "employeeLocationCountryName": [
                8
            ],
            "employeeLocationCountryId": [
                17
            ],
            "employeeLocationContinentName": [
                8
            ],
            "employeeLocationContinentId": [
                17
            ],
            "proposedAdjustments": [
                110
            ],
            "suggestedAdjustmentCents": [
                17
            ],
            "suggestedAdjustmentDescription": [
                8
            ],
            "suggestedAdjustmentType": [
                8
            ],
            "scenarioEmployeeId": [
                459
            ],
            "scenariobudgetSet": [
                396
            ],
            "proposedLocationId": [
                459
            ],
            "proposedLocationCityName": [
                8
            ],
            "proposedLocationCityId": [
                17
            ],
            "proposedLocationCountryName": [
                8
            ],
            "proposedLocationCountryId": [
                17
            ],
            "proposedLocationContinentName": [
                8
            ],
            "proposedLocationContinentId": [
                17
            ],
            "proposedFamilyUuid": [
                459
            ],
            "proposedFamilyName": [
                8
            ],
            "proposedTier": [
                8
            ],
            "proposedRoleId": [
                17
            ],
            "proposedRoleJobTrack": [
                8
            ],
            "proposedRoleLevelNum": [
                17
            ],
            "proposedRoleTitle": [
                8
            ],
            "proposedRoleTrackAbbr": [
                8
            ],
            "proposedRoleJobTrackLevelDisplay": [
                8
            ],
            "proposedRoleJobTrackLevelTitleDisplay": [
                8
            ],
            "promotionEligible": [
                8
            ],
            "approvers": [
                411
            ],
            "alerts": [
                403
            ],
            "sharedWithUser": [
                22
            ],
            "sharedWith": [
                93
            ],
            "customBudgetIneligibilityReason": [
                8
            ],
            "comments": [
                401
            ],
            "additionalData": [
                153
            ],
            "employeeCompaRatios": [
                208
            ],
            "rangePenetration": [
                8
            ],
            "currentVariableCompInCents": [
                17
            ],
            "newVariableCompInCents": [
                17
            ],
            "hasComments": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "updateHiddenAtForEmployeesInput": {
            "employeeIds": [
                459
            ],
            "hide": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "updateHiddenAtForEmployeesResponse": {
            "success": [
                22
            ],
            "__typename": [
                8
            ]
        },
        "PermDefinition": {
            "app": [
                8
            ],
            "permission": [
                8
            ],
            "__typename": [
                8
            ]
        }
    }
}