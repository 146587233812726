import { IOfferFull } from 'components/core/interfaces';
import { createContext, useContext } from 'react';
import { OfferMessageType } from 'services/offer/queries';

interface IOfferContext {
  offer: IOfferFull;
  // offerTeam: IOfferFull;
  messages: OfferMessageType[];
  lockPackage?: (package_id: number) => void;
}

// FIXME: fix types and merge offer with offerTeam
const offerContextDefaultValues: IOfferContext = {
  offer: {} as IOfferFull,
  // offerTeam: {} as IOfferFull, // this is a lie. the type is actually IOfferFullMessage
  messages: [] as OfferMessageType[],
  lockPackage: (_: number): void => {},
};

export const OfferContext = createContext<IOfferContext>(offerContextDefaultValues);

export function useOfferContext() {
  return useContext(OfferContext);
}
