import { captureException, withScope } from '@sentry/nextjs';
import { Mutation, MutationCache, MutationMeta } from '@tanstack/react-query';

type MetaWithAnalytics = {
  analytics: {
    model: string;
    action: 'create' | 'update' | 'delete';
  };
};

const isRecord = (x: unknown): x is Record<string, unknown> => typeof x === 'object' && x !== null;

const isAnalyticsMeta = (meta?: MutationMeta): meta is MetaWithAnalytics =>
  meta !== undefined && isRecord(meta.analytics) && !!meta.analytics?.model && !!meta.analytics?.action;

export const handleMutationOnSuccess = (
  _: unknown,
  variables: unknown,
  __: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
  const meta = mutation.meta;

  if (!meta || !isAnalyticsMeta(meta)) return;

  const { model, action } = meta.analytics;
  if (action !== 'update' && action !== 'create' && action !== 'delete') return;

  const event = `${model}-${action}`;
  const properties = action !== 'delete' && isRecord(variables) ? variables : {};

  global.analytics?.track(event, properties);
};

export const analyticsMutationCache = new MutationCache({
  onSuccess: handleMutationOnSuccess,

  onError: (error, _query, _context, mutation) => {
    withScope((scope) => {
      scope.setTag('kind', 'client-mutation-error');
      scope.setExtra('mutationState', mutation.state);
      scope.setExtra('mutationOptions', mutation.options);
      scope.setExtra('mutationId', mutation.mutationId);
      captureException(error);
    });
  },
});
