import { isOperationInfo } from 'services/generated';

export const raiseOnOperationInfo = (obj?: { __typename?: string } | null) => {
  if (isOperationInfo(obj)) {
    let errorString = 'Resolution failed: ';
    if (obj && 'messages' in obj && Array.isArray(obj.messages)) {
      errorString += obj.messages.map((message) => message.message).join(', ');
    }
    throw new Error(errorString);
  }
};
