import { useAuth } from '@clerk/nextjs';
import React, { createContext, useContext } from 'react';
import { createClient } from 'services/generated';

export function makeServerSideClient(headers: Record<string, string>) {
  return createClient({
    url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql/`,
    headers,
  });
}

export const queryOptions = {
  retry: 3, // allow 3 retries, since we have a non-standard setup for authentication
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime: Infinity,
};

// this only exists to make the typescript linter happy. It will never be
// accessible to consumers of the contexts.
const dummyClient = createClient({
  url: 'localhost:3000',
  headers: () => {
    throw 'DummyClient: this should never be called';
  },
});

const ClientContext = createContext({ client: dummyClient, phoenixClient: dummyClient });

export const useAuthenticatedClient = () => {
  return useContext(ClientContext).client;
};

export const useAuthenticatedPhoenixClient = () => {
  return useContext(ClientContext).phoenixClient;
};

export const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const { getToken } = useAuth();

  const makeHeaders = async (): Promise<Record<string, string>> => {
    const token = await getToken();
    if (token === null) {
      return {};
    }
    return { Authorization: `Bearer ${token}` };
  };

  const client = createClient({
    url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql/`,
    headers: makeHeaders,
  });

  const phoenixClient = createClient({
    url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v2/graphql/`,
    headers: makeHeaders,
  });

  const clients = { client, phoenixClient };

  return <ClientContext.Provider value={clients}>{children}</ClientContext.Provider>;
};
