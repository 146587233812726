import { FieldsSelection } from '@genql/runtime';
import { OPERATION_INFO_FRAGMENT } from 'services/common/fragments';
import { OrganizationType } from 'services/generated';
import { SHALLOW_JOB_FAMILY_FRAGMENT } from 'services/levels/queries';
import { BENCHMARK_IMPORT_FRAGMENT, SHALLOW_ORGANIZATION_FRAGMENT } from 'services/queries';
import { VESTING_SCHEDULE_FRAGMENT } from 'services/scenarios/fragments';

// Base Fragments
export const NOTIFICATION_CONFIGURATIONS_FRAGMENT = {
  id: true,
  type: true,
  enabled: true,
};

export const NOTIFICATION_RECIPIENTS_FRAGMENT = {
  id: true,
  email: true,
  name: true,
  position: true,
  notifications: {
    type: true,
  },
};

// Queries
export const GET_NOTIFICATION_CONFIGURATIONS_RETURN_FRAGMENT = {
  notificationConfigurations: NOTIFICATION_CONFIGURATIONS_FRAGMENT,
};

export const GET_NOTIFICATION_RECIPIENTS_RETURN_FRAGMENT = {
  notificationRecipients: NOTIFICATION_RECIPIENTS_FRAGMENT,
};

export const CREATE_ORGANIZATION_RETURN_FRAGMENT = {
  __typename: true,
  on_OperationInfo: OPERATION_INFO_FRAGMENT,
  on_OrganizationType: {
    id: true,
    name: true,
    identifier: true,
  },
};

// mutations
export const UPDATE_ORGANIZATION_RETURN_FRAGMENT = {
  __typename: true,
  on_OperationType: {
    id: true,
    notificationConfigurations: {
      additionalRecipients: true,
    },
  },
};

export const SHALLOW_COMMISSION_STRUCTURE_FRAGMENT = {
  id: true,
  name: true,
};

export const SHALLOW_BENEFIT_PACKAGE_FRAGMENT = {
  id: true,
  name: true,
};

export const PERFORMANCE_RATING_SCHEME_DESCRIPTION = {
  mappings: {
    rating: true,
    description: true,
  },
};
export const PERFORMANCE_RATING_SCHEME_FRAGMENT = {
  id: true,
  name: true,
  scaleSize: true,
  scaleStart: true,
  scaleDescription: PERFORMANCE_RATING_SCHEME_DESCRIPTION,
};

export const SHALLOW_USER_FRAGMENT = {
  id: true,
  email: true,
  fullName: true,
  title: true,
  group: true,
  __typename: true,
};

export const SERVERSIDE_ORGANIZATION_FRAGMENT = {
  id: true,
  uuid: true,
  name: true,
  identifier: true,
  mergeAccountToken: true,
};

export const AGGREGATED_BENCHMARK_FRAGMENT = {
  id: true,
  sourceBenchmarks: BENCHMARK_IMPORT_FRAGMENT,
  jobFamilies: SHALLOW_JOB_FAMILY_FRAGMENT,

  notes: true,
};

export const FLATFILE_IMPORT_FRAGMENT = {
  id: true,
  userAssignedToSpace: {
    id: true,
    fullName: true,
    email: true,
  },
  type: true,
  scenario: {
    uuid: true,
    name: true,
  },
  spaceId: true,
  workbookId: true,
  sheetId: true,
  processedAt: true,
  csvUrl: true,
};

export const FLATFILE_INVITE_RESPONSE = {
  success: true,
  __typename: true,
};

export const ORGANIZATION_HRIS_ACCOUNTS_FRAGMENT = {
  accountToken: true,
  accountSource: true,
  accountType: true,
  accountName: true,
  createdAt: true,
  mergeHealthStatus: true,
  accountOriginId: true,
};

export const SECURITY_INTEGRATION_CONFIG_FRAGMENT = {
  id: true,
  integrationSource: true,
  createdAt: true,
  updatedAt: true,
  isActive: true,
  lastSyncAt: true,
};

export const DETAILED_ORGANIZATION_FRAGMENT = {
  commissionStructures: SHALLOW_COMMISSION_STRUCTURE_FRAGMENT,
  benefitPackages: SHALLOW_BENEFIT_PACKAGE_FRAGMENT,
  performanceratingschemeSet: PERFORMANCE_RATING_SCHEME_FRAGMENT,
  flatfileImports: FLATFILE_IMPORT_FRAGMENT,
  defaultVestingScheduleType: VESTING_SCHEDULE_FRAGMENT,
  hrisAccounts: ORGANIZATION_HRIS_ACCOUNTS_FRAGMENT,
  securityIntegrationConfigs: SECURITY_INTEGRATION_CONFIG_FRAGMENT,
  defaultPerformanceRatingScheme: PERFORMANCE_RATING_SCHEME_FRAGMENT,
  ...SHALLOW_ORGANIZATION_FRAGMENT,
};

export const ORGANIZATION_USERS_FRAGMENT = {
  users: SHALLOW_USER_FRAGMENT,
};

export const SHALLOW_COMPENSATION_PACKAGE_FRAGMENT = {
  id: true,
  annualSalaryCents: true,
  equityValueCents: true,
  cashCurrency: true,
  equityUnitType: true,
  equityUnits: true,
  equityPercentBps: true,
  hourlyWageCents: true,
  earlyExercise: true,
  vestingSchedule: true,
  commissionStructure: SHALLOW_COMMISSION_STRUCTURE_FRAGMENT,
};

export const ORGANIZATION_MESSAGES_FRAGMENT_USER_MESSAGES = {
  id: true,
  offer: {
    id: true,
  },
  user: {
    id: true,
  },
  content: true,
  createdAt: true,
  videoUrl: true,
  __typename: true,
};

export const ORGANIZATION_MESSAGES_FRAGMENT_USERS = {
  id: true,
  fullName: true,
  email: true,
  group: true,
  imagePath: true,
  links: true,
  title: true,
  genericMessages: ORGANIZATION_MESSAGES_FRAGMENT_USER_MESSAGES,
  __typename: true,
};

export const ORGANIZATION_MESSAGES_FRAGMENT_ORGANIZATION = {
  id: true,
  name: true,
  identifier: true,
  uuid: true,
  users: ORGANIZATION_MESSAGES_FRAGMENT_USERS,
  __typename: true,
};

export type OrganizationMessagesFragment = FieldsSelection<OrganizationType, typeof ORGANIZATION_MESSAGES_FRAGMENT_ORGANIZATION>;
export type OrganizationMessagesFragmentUsers = FieldsSelection<OrganizationType['users'][0], typeof ORGANIZATION_MESSAGES_FRAGMENT_USERS>;
export type OrganizationMessagesFragmentUserMessages = FieldsSelection<
  OrganizationType['users'][0]['messages'][0],
  typeof ORGANIZATION_MESSAGES_FRAGMENT_USER_MESSAGES
>;
