import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { shouldSkipContextRequests } from 'components/core/context/common';
import { useRouter } from 'next/router';
import { useAuthenticatedClient } from 'services/client';
import {
  NotificationConfigurationInput,
  NotificationRecipientInput,
  OrganizationCreateInput,
  OrganizationInputPartial,
  OrganizationType,
  UpdateOrganizationPayload,
} from 'services/generated';
import { OrganizationMessagesFragment } from 'services/organization/fragments';
import {
  createOrganization,
  updateNotificationType,
  updateOrganization,
  updateOrganizationNotificationRecipients,
} from 'services/organization/mutations';
import {
  BenchmarkingImportType,
  CreateOrganizationReturnType,
  DetailedOrganizationType,
  getDetailedOrganization,
  getOrganizationNotificationConfigurations,
  getOrganizationNotificationRecipients,
  getOrganizationUsers,
  getOrganizationWithMessages,
  getShallowOrganization,
  OrganizationUsersType,
  ShallowOrganizationType,
} from 'services/organization/queries';
import {
  GetOrganizationNotificationConfigurationsReturnType,
  GetOrganizationNotificationRecipientsType,
} from 'services/organization/types';
import { getOrganizationBenchmarkingImportsQuery } from 'services/queries';
import { BENCHMARKING_IMPORTS_CACHE_KEY } from 'services/scenarios/hooks';

// https://react-query-v3.tanstack.com/guides/query-keys#array-keys
export const ORG_NOTIF_CONFIG_CACHE_KEY = (id: any) => [`organization_${id}`, `organization_notification_configurations_${id}`];
export const ORG_NOTIF_RECIPIENTS_CACHE_KEY = (id: any) => [`organization_${id}`, `organization_notification_recipients_${id}`];

// Query
// get an organization's notification configurations
export const useGetOrganizationNotificationConfigurations = (
  id: number,
  options: UseQueryOptions<GetOrganizationNotificationConfigurationsReturnType> = {},
) => {
  const client = useAuthenticatedClient();
  return useQuery<GetOrganizationNotificationConfigurationsReturnType>(
    ORG_NOTIF_CONFIG_CACHE_KEY(id),
    () => getOrganizationNotificationConfigurations(client, id),
    options,
  );
};

// get an organization's notification recipients
export const useGetOrganizationNotificationRecipients = (
  id: number,
  options: UseQueryOptions<GetOrganizationNotificationRecipientsType> = {},
) => {
  const client = useAuthenticatedClient();
  return useQuery<GetOrganizationNotificationRecipientsType>(
    ORG_NOTIF_RECIPIENTS_CACHE_KEY(id),
    () => getOrganizationNotificationRecipients(client, id),
    options,
  );
};

// Mutation
export const useCreateOrganization = (options: UseMutationOptions<CreateOrganizationReturnType, unknown, OrganizationCreateInput> = {}) => {
  // TODO: update cache key
  const client = useAuthenticatedClient();
  return useMutation<CreateOrganizationReturnType, unknown, OrganizationCreateInput>((input) => createOrganization(input, client), options);
};

export const useUpdateNotificationConfiguration = (
  id: number,
  options: UseMutationOptions<UpdateOrganizationPayload, unknown, Partial<NotificationConfigurationInput>[]> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();

  const client = useAuthenticatedClient();
  return useMutation<UpdateOrganizationPayload, unknown, Partial<NotificationConfigurationInput>[]>(
    (variables: Partial<NotificationConfigurationInput>[]) => updateNotificationType(id, variables, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        // update cache
        const cachedNotificationConfigs = queryData.getQueryData(ORG_NOTIF_CONFIG_CACHE_KEY(id)) as OrganizationType;
        if (cachedNotificationConfigs) {
          //https://react-query-v3.tanstack.com/reference/QueryClient#queryclientsetquerydata
          //---!!!important!!!---
          // data shape must match the shape of query data!
          queryData.setQueryData(ORG_NOTIF_CONFIG_CACHE_KEY(id), {
            ...cachedNotificationConfigs,
            notificationConfigurations: [...(data as OrganizationType).notificationConfigurations],
          });
        }
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

export const useUpdateOrganizationNotificationRecipients = (
  id: number,
  options: UseMutationOptions<UpdateOrganizationPayload, unknown, NotificationRecipientInput[]> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();

  const client = useAuthenticatedClient();
  return useMutation<UpdateOrganizationPayload, unknown, NotificationRecipientInput[]>(
    (variables: NotificationRecipientInput[]) => updateOrganizationNotificationRecipients(id, variables, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        // update cache
        const cachedNotificationRecipients = queryData.getQueryData(ORG_NOTIF_RECIPIENTS_CACHE_KEY(id)) as OrganizationType;
        if (cachedNotificationRecipients) {
          //https://react-query-v3.tanstack.com/reference/QueryClient#queryclientsetquerydata
          queryData.setQueryData(ORG_NOTIF_RECIPIENTS_CACHE_KEY(id), {
            ...cachedNotificationRecipients,
            notificationRecipients: [...(data as OrganizationType).notificationRecipients],
          });
        }
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

export const useShallowOrganization = (options: UseQueryOptions<ShallowOrganizationType | undefined> = {}) => {
  const isDisabledRoute = shouldSkipContextRequests(useRouter());
  const client = useAuthenticatedClient();
  return useQuery<ShallowOrganizationType | undefined>(['shallow-organization'], () => getShallowOrganization(client), {
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: true,
    enabled: !isDisabledRoute,
  });
};

export const useDetailedOrganization = (options: UseQueryOptions<DetailedOrganizationType | undefined> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<DetailedOrganizationType | undefined>(['organization'], () => getDetailedOrganization(client), {
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: true,
  });
};

export const useOrganizationUsers = (options: UseQueryOptions<OrganizationUsersType | undefined> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<OrganizationUsersType | undefined>(['organization-users-and-roles'], () => getOrganizationUsers(client), options);
};

export const useOrganizationWithMessages = (options: UseQueryOptions<OrganizationMessagesFragment | undefined> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<OrganizationMessagesFragment | undefined>(['organization-messages'], () => getOrganizationWithMessages(client), options);
};

export const useUpdateOrganization = (options: UseMutationOptions<UpdateOrganizationPayload, unknown, OrganizationInputPartial> = {}) => {
  const { mutationKey, ...restOfOptions } = options;
  const client = useAuthenticatedClient();
  return useMutation<UpdateOrganizationPayload, unknown, OrganizationInputPartial>(
    [mutationKey],
    (input) => updateOrganization(input, client),
    restOfOptions,
  );
};

export const useInvalidateOrganization = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries(['organization']);
    },
  };
};

export const useInvalidateShallowOrganization = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries(['shallow-organization']);
    },
  };
};

export const useOrganizationBenchmarkingImports = (options: UseQueryOptions<BenchmarkingImportType[] | undefined> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<BenchmarkingImportType[] | undefined>(
    BENCHMARKING_IMPORTS_CACHE_KEY,
    async (): Promise<BenchmarkingImportType[] | undefined> => {
      const response = await client.query(getOrganizationBenchmarkingImportsQuery);
      return response.organization?.benchmarkingImports;
    },
    options,
  );
};
