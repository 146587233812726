import { FieldsSelection } from '@genql/runtime';
import { Market } from 'components/core/enums';
import {
  BenchmarkImportType,
  Client,
  CreateOrganizationPayload,
  MessageUserType,
  OfferType,
  OrganizationType,
  PerformanceRatingSchemeType,
} from 'services/generated';
import {
  CREATE_ORGANIZATION_RETURN_FRAGMENT,
  DETAILED_ORGANIZATION_FRAGMENT,
  GET_NOTIFICATION_CONFIGURATIONS_RETURN_FRAGMENT,
  GET_NOTIFICATION_RECIPIENTS_RETURN_FRAGMENT,
  ORGANIZATION_MESSAGES_FRAGMENT_ORGANIZATION,
  ORGANIZATION_USERS_FRAGMENT,
  OrganizationMessagesFragment,
  PERFORMANCE_RATING_SCHEME_DESCRIPTION,
  PERFORMANCE_RATING_SCHEME_FRAGMENT,
  SERVERSIDE_ORGANIZATION_FRAGMENT,
  SHALLOW_COMPENSATION_PACKAGE_FRAGMENT,
  SHALLOW_USER_FRAGMENT,
} from 'services/organization/fragments';
import {
  GetOrganizationNotificationConfigurationsReturnType,
  GetOrganizationNotificationRecipientsType,
} from 'services/organization/types';
import { BENCHMARK_IMPORT_FRAGMENT, SHALLOW_ORGANIZATION_FRAGMENT, shallowOrganizationQuery } from 'services/queries';

export type ShallowUserType = FieldsSelection<MessageUserType, typeof SHALLOW_USER_FRAGMENT>;
export type ShallowOrganizationType = FieldsSelection<OrganizationType, typeof SHALLOW_ORGANIZATION_FRAGMENT>;
export type DetailedOrganizationType = FieldsSelection<OrganizationType, typeof DETAILED_ORGANIZATION_FRAGMENT> & {
  market: Market;
};
export type OrganizationUsersType = FieldsSelection<OrganizationType, typeof ORGANIZATION_USERS_FRAGMENT>;
export type ServersideOrganizationType = FieldsSelection<OrganizationType, typeof SERVERSIDE_ORGANIZATION_FRAGMENT>;
export type BenchmarkingImportType = FieldsSelection<BenchmarkImportType, typeof BENCHMARK_IMPORT_FRAGMENT>;

export type PerformanceRatingSchemeMapping = FieldsSelection<
  PerformanceRatingSchemeType['scaleDescription'],
  typeof PERFORMANCE_RATING_SCHEME_DESCRIPTION
>;
export type PerformanceRatingScheme = FieldsSelection<PerformanceRatingSchemeType, typeof PERFORMANCE_RATING_SCHEME_FRAGMENT>;
export type CustomPerformanceRatingScheme = {
  scheme?: PerformanceRatingScheme;
  isLoading?: boolean;
};
export type ShallowCompensationPackageType = FieldsSelection<OfferType, typeof SHALLOW_COMPENSATION_PACKAGE_FRAGMENT>;
export type CreateOrganizationReturnType = FieldsSelection<CreateOrganizationPayload, typeof CREATE_ORGANIZATION_RETURN_FRAGMENT>;

export const getOrganizationServerside = async (client: Client): Promise<ServersideOrganizationType | undefined> => {
  const result = await client.query({
    __name: 'getOrganizationServerside',
    organization: SERVERSIDE_ORGANIZATION_FRAGMENT,
  });
  if (!result || !result.organization) {
    throw new Error('No organization found');
  }
  return result.organization;
};

export const getShallowOrganization = async (client: Client): Promise<ShallowOrganizationType | undefined> => {
  const result = await client.query(shallowOrganizationQuery);
  if (!result || !result.organization) {
    throw new Error('No organization found');
  }
  return result.organization;
};

export const getDetailedOrganization = async (client: Client): Promise<DetailedOrganizationType | undefined> => {
  const result = await client.query({
    __name: 'getDetailedOrganization',
    organization: DETAILED_ORGANIZATION_FRAGMENT,
  });
  if (!result || !result.organization) {
    throw new Error('No organization found');
  }
  const market = result?.organization?.stock?.ticker ? Market.PUBLIC : Market.PRIVATE;
  return { ...result.organization, market };
};

export const getOrganizationUsers = async (client: Client): Promise<OrganizationUsersType | undefined> => {
  const result = await client.query({
    __name: 'getOrganizationUsers',
    organization: ORGANIZATION_USERS_FRAGMENT,
  });
  if (!result || !result.organization) {
    throw new Error('No organization found');
  }
  return result.organization;
};

export const getOrganizationWithMessages = async (client: Client): Promise<OrganizationMessagesFragment | undefined> => {
  const result = await client.query({
    __name: 'getOrganizationWithMessages',
    organization: ORGANIZATION_MESSAGES_FRAGMENT_ORGANIZATION,
  });
  return result.organization;
};

// get an organization's notification configurations
export const getOrganizationNotificationConfigurations = async (
  client: Client,
  id: number,
): Promise<GetOrganizationNotificationConfigurationsReturnType> => {
  const data = await client.query({
    __name: 'getOrganizationNotificationConfigurations',
    organization: [
      { pk: id },
      {
        __typename: true,
        ...GET_NOTIFICATION_CONFIGURATIONS_RETURN_FRAGMENT,
      },
    ],
  });
  return data.organization as GetOrganizationNotificationConfigurationsReturnType;
};

// get an organization's notification recipients
export const getOrganizationNotificationRecipients = async (
  client: Client,
  id: number,
): Promise<GetOrganizationNotificationRecipientsType> => {
  const data = await client.query({
    __name: 'getOrganizationNotificationRecipients',
    organization: [
      { pk: id },
      {
        __typename: true,
        ...GET_NOTIFICATION_RECIPIENTS_RETURN_FRAGMENT,
      },
    ],
  });
  return data.organization as GetOrganizationNotificationRecipientsType;
};
