import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

const DowntimeNotice: React.FC = () => {
  const [isVisible, setIsVisible] = useState(process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_BANNER === 'true');
  const isBlocking = process.env.NEXT_PUBLIC_CURRENTLY_UNDER_MAINTENANCE === 'true';

  const warningMessage =
    'Complete will be undergoing maintenance this evening beginning at 9 PM PST until midnight. Please contact us if you have any questions or concerns.';
  const blockingMessage = (
    <div className='max-w-[800px]'>
      <p>
        Complete is currently down due to an outage of our hosting provider, Render. We are actively communicating and monitoring for a fix.
        Once Render is back online, we will ensure that Complete is back in full operation. Thank you for your understanding.
      </p>
      <p>
        Feel free to Slack or{' '}
        <a style={{ color: '#187ee0' }} href={`mailto:support@complete.so`}>
          email
        </a>{' '}
        us if you have any additional questions!
      </p>
    </div>
  );

  const handleClose = () => {
    if (isBlocking) {
      return;
    }
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={'bg-yellow-50 text-yellow-800'}
      style={{
        padding: '0.4rem',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 49, // left side nav is 50
      }}
    >
      <ExclamationTriangleIcon className='inline h-5 w-5 text-yellow-400' aria-hidden='true' /> {warningMessage}
      {isBlocking && (
        <div
          style={{
            backgroundColor: '#000000',
            color: '#ffffff',
            padding: '0.5rem',
            textAlign: 'center',
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 20,
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '16pt',
            }}
          >
            {blockingMessage}
          </h1>
        </div>
      )}
      <button
        onClick={handleClose}
        style={{
          marginLeft: '1rem',
          background: 'none',
          border: 'none',
          color: '#333131',
          fontSize: '1rem',
          cursor: 'pointer',
          padding: 0,
          display: isBlocking ? 'none' : 'inline',
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default DowntimeNotice;
