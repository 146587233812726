import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

const VersionNotifier = () => {
  useEffect(() => {
    const fetchVersion = async () => {
      const { data } = await axios.get('/api/version');
      // this should only happen if an older client is making a request
      // to a newer server.
      if (data.version !== process.env.NEXT_PUBLIC_APP_VERSION) {
        // Emit a segment event if this is shown
        global.analytics.track('version-notifier-shown');
        toast(
          <div className='bg-white sm:rounded-lg'>
            <div className='px-2 py-3 sm:p-2'>
              <h3 className='text-base font-semibold leading-6 text-gray-900'>New Version Available</h3>
              <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
                <div className='max-w-xl text-sm text-gray-500'>
                  <p>A new version of Complete is available. Please refresh to update.</p>
                </div>
                <div className='mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center'>
                  <button
                    type='button'
                    className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>,
          {
            id: 'versionToast', // Add unique id to the toast
            position: 'bottom-right',
            duration: 60000,
          },
        );
      }
    };

    fetchVersion();
  }, []);

  return null;
};

export default VersionNotifier;
