import { FieldsSelection } from '@genql/runtime';
import { OPERATION_INFO_FRAGMENT } from 'services/common/fragments';
import {
  AssignIneligibilityReasonsInput,
  AssignIneligibilityReasonsResponse,
  Client,
  CompensationChangeScenarioInputType,
  CompensationChangeScenarioInviteInput,
  CompensationChangeScenarioType,
  CompensationScenarioCohortInputType,
  CompensationScenarioDataDumpInput,
  DeleteCompensationChangeCohortInput,
  DeleteCompensationChangeScenarioInput,
  DeleteFlatfileImportInput,
  DeleteFlatfileImportResponse,
  DismissScenarioEmployeeAlertInput,
  isOperationInfo,
  MarkAllEligibleInput,
  MarkAllEligibleResponse,
  NotifyApprovalEmployeeInput,
  OperationInfo,
  PerformanceRatingInput,
  PerformanceRatingInputPartial,
  PerformanceRatingType,
  ScenarioApproverInput,
  ScenarioApproverType,
  ScenarioBudgetInput,
  ScenarioBudgetInputPartial,
  ScenarioEmployeeApproverBulkInputType,
  ScenarioEmployeeApproverBulkResponse,
  ScenarioEmployeeApproverInput,
  ScenarioEmployeeApproverInputPartial,
  ScenarioEmployeeApproverType,
  ScenarioEmployeeInputPartial,
  ScenarioEmployeeInviteInput,
} from 'services/generated';
import {
  COHORT_FRAGMENT,
  NOTIFY_APPROVAL_EMPLOYEE_FRAGMENT,
  SCENARIO_APPROVER_FRAGMENT,
  SCENARIO_BUDGET_FRAGMENT,
  SCENARIO_EMPLOYEE_FRAGMENT,
  SCENARIO_EMPLOYEE_INVITE_FRAGMENT,
  SCENARIO_INVITE_FRAGMENT,
} from 'services/scenarios/fragments';
import { CREATE_APPROVAL_EMPLOYEE_SUMMARY, CREATE_PERFORMANCE_RATING_SUMMARY } from 'services/scenarios/queries';
import {
  CohortFragmentType,
  NotifyApprovalEmployeeFragmentType,
  ScenarioBudgetFragmentType,
  ScenarioEmployeeFragmentType,
  ScenarioEmployeeInviteFragmentType,
  ScenarioInviteFragmentType,
} from 'services/scenarios/types';

export const MARK_ALL_ELIGIBLE_FRAGMENT = {
  scenarioId: true,
  __typename: true,
};

export const ASSIGN_INELIGIBILITY_REASONS_FRAGMENT = {
  scenarioId: true,
  __typename: true,
};

export const DELETE_FLATFILE_IMPORT_FRAGMENT = {
  success: true,
  __typename: true,
};

export type MarkAllEligibleFragmentType = FieldsSelection<MarkAllEligibleResponse, typeof MARK_ALL_ELIGIBLE_FRAGMENT>;
export type AssignIneligibilityReasonsFragmentType = FieldsSelection<
  AssignIneligibilityReasonsResponse,
  typeof ASSIGN_INELIGIBILITY_REASONS_FRAGMENT
>;
export type DeleteFlatfileImportFragment = FieldsSelection<DeleteFlatfileImportResponse, typeof DELETE_FLATFILE_IMPORT_FRAGMENT>;

export const deleteCompensationChangeScenario = async (
  input: DeleteCompensationChangeScenarioInput,
  client: Client,
): Promise<Partial<OperationInfo>> => {
  return await client.chain.mutation.deleteCompensationChangeScenario({ input }).get({
    __typename: true,
  });
};

export const createPerformanceRating = async (input: PerformanceRatingInput, client: Client): Promise<PerformanceRatingType> => {
  const { __typename, ...data } = await client.chain.mutation.createPerformanceRating({ input }).get(CREATE_PERFORMANCE_RATING_SUMMARY);
  return data as PerformanceRatingType;
};

export const updatePerformanceRating = async (input: PerformanceRatingInputPartial, client: Client): Promise<PerformanceRatingType> => {
  const { __typename, ...data } = await client.chain.mutation.updatePerformanceRating({ input }).get(CREATE_PERFORMANCE_RATING_SUMMARY);
  return data as PerformanceRatingType;
};

export const dismissScenarioEmployeeAlert = async (input: DismissScenarioEmployeeAlertInput, client: Client): Promise<void> => {
  const data = await client.chain.mutation.dismissScenarioEmployeeAlert({ input }).get({ __typename: true });
  if (data.__typename === 'OperationInfo') {
    throw new Error(data.messages.toString());
  }
  return;
};

export const createApprovalEmployee = async (
  input: ScenarioEmployeeApproverInput,
  client: Client,
): Promise<ScenarioEmployeeApproverType> => {
  const data = await client.chain.mutation
    .createApprovalEmployee({ input })
    .get({ on_ScenarioEmployeeApproverType: CREATE_APPROVAL_EMPLOYEE_SUMMARY, on_OperationInfo: OPERATION_INFO_FRAGMENT });
  if (data.__typename === 'OperationInfo') {
    throw new Error(data.messages.toString());
  }
  return data as ScenarioEmployeeApproverType;
};

export const updateApprovalEmployee = async (
  input: ScenarioEmployeeApproverInputPartial,
  client: Client,
): Promise<ScenarioEmployeeApproverType> => {
  const { __typename, ...data } = await client.chain.mutation.updateApprovalEmployee({ input }).get(CREATE_APPROVAL_EMPLOYEE_SUMMARY);
  return data as ScenarioEmployeeApproverType;
};

export const updateScenarioApprover = async (input: ScenarioApproverInput, client: Client): Promise<ScenarioApproverType> => {
  const { __typename, ...data } = await client.chain.mutation.updateScenarioApprover({ input }).get(SCENARIO_APPROVER_FRAGMENT);
  return data as ScenarioApproverType;
};

export const bulkUpdateScenarioEmployeeApprovers = async (
  input: ScenarioEmployeeApproverBulkInputType,
  client: Client,
): Promise<ScenarioEmployeeApproverBulkResponse> => {
  const data = await client.chain.mutation.bulkUpdateScenarioEmployeeApprovers({ input }).get({
    __typename: true,
    on_ScenarioEmployeeApproverBulkResponse: { updatedApprovalsCount: true },
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
  if (data.__typename === 'OperationInfo') {
    throw new Error(data.messages.toString());
  }
  return data as ScenarioEmployeeApproverBulkResponse;
};

export const createCompensationChangeScenarioDetail = async (
  input: CompensationChangeScenarioInputType,
  client: Client,
): Promise<Pick<CompensationChangeScenarioType, 'uuid'>> => {
  return await client.chain.mutation.createCompensationChangeScenarioV2({ input }).get({
    uuid: true,
  });
};

export const updateCompensationChangeScenarioDetail = async (input: CompensationChangeScenarioInputType, client: Client): Promise<void> => {
  await client.chain.mutation.updateCompensationChangeScenarioV2({ input }).get();
};

export const persistCompensationChangeScenarioData = async (input: CompensationScenarioDataDumpInput, client: Client): Promise<void> => {
  await client.chain.mutation.persistCompensationChangeScenarioData({ input }).get();
};

export const persistCompensationChangeScenarioDataV2 = async (input: CompensationScenarioDataDumpInput, client: Client): Promise<void> => {
  await client.chain.mutation.persistCompensationChangeScenarioDataV2({ input }).get();
};

export const updateScenarioEmployee = async (
  input: ScenarioEmployeeInputPartial,
  client: Client,
): Promise<ScenarioEmployeeFragmentType> => {
  return await client.chain.mutation.updateScenarioEmployee({ input }).get(SCENARIO_EMPLOYEE_FRAGMENT);
};

export const createCompensationChangeCohort = async (
  input: CompensationScenarioCohortInputType,
  client: Client,
): Promise<CohortFragmentType> => {
  return await client.chain.mutation.createCompensationChangeCohort({ input }).get(COHORT_FRAGMENT);
};

export const updateCompensationChangeCohort = async (
  input: CompensationScenarioCohortInputType,
  client: Client,
): Promise<CohortFragmentType> => {
  return await client.chain.mutation.updateCompensationChangeCohort({ input }).get(COHORT_FRAGMENT);
};

export const deleteCompensationChangeCohort = async (input: DeleteCompensationChangeCohortInput, client: Client): Promise<void> => {
  const response = await client.chain.mutation.deleteCompensationChangeCohortTemp({ input }).get({
    __typename: true,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
  if (isOperationInfo(response)) {
    throw new Error(response.messages[0]?.message || response.messages[0]?.kind || 'Error');
  }
};

export const compensationChangeScenarioInvite = async (
  input: CompensationChangeScenarioInviteInput,
  client: Client,
): Promise<ScenarioInviteFragmentType> => {
  const response = await client.chain.mutation.compensationChangeScenarioInvite({ input }).get({
    on_CompensationChangeScenarioInviteResponse: SCENARIO_INVITE_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });

  if (isOperationInfo(response)) {
    throw new Error(response.messages.toString());
  }

  return response;
};

export const notifyApprovalEmployee = async (
  input: NotifyApprovalEmployeeInput,
  client: Client,
): Promise<NotifyApprovalEmployeeFragmentType> => {
  const response = await client.chain.mutation.notifyApprovalEmployee({ input }).get({
    on_NotifyApprovalEmployeeResponse: NOTIFY_APPROVAL_EMPLOYEE_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
    __typename: true,
  });
  if (isOperationInfo(response)) {
    throw new Error(response.messages[0]?.message || response.messages[0]?.kind || 'Error');
  }
  return response;
};

export const scenarioEmployeeInvite = async (
  input: ScenarioEmployeeInviteInput,
  client: Client,
): Promise<ScenarioEmployeeInviteFragmentType> => {
  const response = await client.chain.mutation.scenarioEmployeeInvite({ input }).get({
    on_ScenarioEmployeeInviteResponse: SCENARIO_EMPLOYEE_INVITE_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });

  if (isOperationInfo(response)) {
    throw new Error(response.messages.toString());
  }

  return response;
};

export const createScenarioBudget = async (
  input: ScenarioBudgetInput,
  client: Client,
): Promise<ScenarioBudgetFragmentType | OperationInfo> => {
  return await client.chain.mutation.createScenarioBudget({ input }).get({
    on_ScenarioBudgetType: SCENARIO_BUDGET_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
};

export const updateScenarioBudget = async (
  input: ScenarioBudgetInputPartial,
  client: Client,
): Promise<ScenarioBudgetFragmentType | OperationInfo> => {
  return await client.chain.mutation.updateScenarioBudget({ input }).get({
    on_ScenarioBudgetType: SCENARIO_BUDGET_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
};

export const markAllEligible = async (
  input: MarkAllEligibleInput,
  client: Client,
): Promise<MarkAllEligibleFragmentType | OperationInfo> => {
  const response = await client.chain.mutation.markAllEligible({ input }).get({
    on_MarkAllEligibleResponse: MARK_ALL_ELIGIBLE_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
  if (isOperationInfo(response)) {
    throw new Error(response.messages[0]?.message || response.messages[0]?.kind || 'Error');
  }
  return response;
};

export const assignIneligibilityReasons = async (
  input: AssignIneligibilityReasonsInput,
  client: Client,
): Promise<AssignIneligibilityReasonsFragmentType | OperationInfo> => {
  const response = await client.chain.mutation.assignIneligibilityReasons({ input }).get({
    on_AssignIneligibilityReasonsResponse: ASSIGN_INELIGIBILITY_REASONS_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
  });
  if (isOperationInfo(response)) {
    throw new Error(response.messages[0]?.message || response.messages[0]?.kind || 'Error');
  }
  return response;
};

export const deleteFlatfileImport = async (
  input: DeleteFlatfileImportInput,
  client: Client,
): Promise<DeleteFlatfileImportFragment | OperationInfo> => {
  const response = await client.chain.mutation.deleteFlatfileImport({ input }).get({
    on_DeleteFlatfileImportResponse: DELETE_FLATFILE_IMPORT_FRAGMENT,
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
    __typename: true,
  });
  if (isOperationInfo(response)) {
    throw new Error(response.messages[0]?.message || response.messages[0]?.kind || 'Error');
  }
  return response;
};
