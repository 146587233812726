import { useMe } from 'components/core/api/queries/getMe';
import { shouldSkipContextRequests } from 'components/core/context/common';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useShallowOrganization } from 'services/organization/hooks';
import { ShallowOrganizationType } from 'services/organization/queries';

export const OrganizationContext = createContext<ShallowOrganizationType | null>(null);

export const OrganizationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const isDisabledRoute = shouldSkipContextRequests(useRouter());
  const [currentOrganization, setCurrentOrganization] = useState<ShallowOrganizationType | null>(null);
  const { profile: user } = useMe();

  const organization = useShallowOrganization({ enabled: !currentOrganization && user.isSuccess });

  useEffect(() => {
    if (isDisabledRoute) {
      return;
    }
    if (organization.isSuccess) {
      setCurrentOrganization(organization.data || null);
    }
  }, [organization]);

  return <OrganizationContext.Provider value={currentOrganization}>{children}</OrganizationContext.Provider>;
};

export function useOrganizationContext() {
  return useContext(OrganizationContext);
}
