import { useAuth, useUser } from '@clerk/clerk-react';
import { useMe } from 'components/core/api/queries/getMe';
import React, { useEffect, useRef } from 'react';
import { useShallowOrganization } from 'services/organization/hooks';

const Analytics = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn, user } = useUser();
  const { actor, userId } = useAuth();
  const userid = userId === null ? undefined : userId;
  const { profile } = useMe();

  const organization = useShallowOrganization({ enabled: !!isSignedIn });
  const hasIdentified = useRef(false);

  useEffect(() => {
    if (!hasIdentified.current && !!user && profile.isSuccess && organization.isSuccess) {
      const { fullName: name } = profile.data;
      const { id, primaryEmailAddress } = user;
      const properties = {
        email: primaryEmailAddress?.emailAddress,
        name,
        organization: organization.data?.name,
        actor: actor?.sub,
        userid: userid,
      };
      global.analytics.identify(id, properties);
      hasIdentified.current = true;
    }
  }, [profile, user, organization, actor, userid]);

  return <>{children}</>;
};

export default Analytics;
