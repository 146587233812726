/**
 * enums.ts handles proper constant enumerations
 */

import { captureException } from '@sentry/nextjs';
import { BadgeColorType } from 'components/common/badge/Badge';
import { FlatfileImportKind, ProfileGroupType } from 'services/generated';

export enum Market {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum CustomizationLevel {
  ORGANIZATION = 'organization',
  OFFER = 'offer',
  TEMPLATE = 'template',
}

export enum NotificationType {
  OFFER_SIGN = 'offer-sign',
  OFFER_EXPIRING = 'offer-expiring',
}

export enum BonusKind {
  SIGNING = 'signing',
  ANNUAL = 'annual',
  OTHER = 'other',
}

export const bonusTypeDisplay: Record<BonusKind, string> = {
  [BonusKind.SIGNING]: 'Signing',
  [BonusKind.ANNUAL]: 'Annual',
  [BonusKind.OTHER]: 'Other',
};

export enum PayoutFrequencyKind {
  ONE_TIME = 'one-time',
  ANNUAL = 'annual',
  BI_ANNUAL = 'bi-annual',
  MONTHLY = 'monthly',
}

export const payoutFrequencyDisplay: Record<PayoutFrequencyKind, string> = {
  [PayoutFrequencyKind.ONE_TIME]: 'One time',
  [PayoutFrequencyKind.ANNUAL]: 'Annual',
  [PayoutFrequencyKind.BI_ANNUAL]: 'Bi-annual',
  [PayoutFrequencyKind.MONTHLY]: 'Monthly',
};

export const notificationDisplayName: Record<NotificationType, string> = {
  [NotificationType.OFFER_SIGN]: 'Offer Sign',
  [NotificationType.OFFER_EXPIRING]: 'Offer Expiring',
};

export const notificationDescription: Record<NotificationType, string> = {
  [NotificationType.OFFER_SIGN]: "Candidates and recruiters will be notified when the candidate indicates they're ready to sign.",
  [NotificationType.OFFER_EXPIRING]: 'Candidates will receive a notification 24 hours before the offer is set to expire.',
};

export enum MarketMilestoneKind {
  Slack = 'Slack', //26.51 Billion
  Salesforce = 'Salesforce', //163.63B
  Asana = 'Asana', //3.38B
  Stripe = 'Stripe', //95B 2021
  Alphabet = 'Alphabet', //1.5T
  Warby_Parker = 'Warby_Parker', //1.55B
  Amazon = 'Amazon', //1.04T
  Airbnb = 'Airbnb', //62.93B
  Dropbox = 'Dropbox', //7.72B
  Datadog = 'Datadog', //27.44B
  Pagerduty = 'Pagerduty', //1.98B
  Tesla = 'Tesla', //$686.53 B
  Twitch = 'Twitch', //1B
  Twilio = 'Twilio',
  Cloudflare = 'Cloudflare',
  Figma = 'Figma', //8.5B
  Nuance = 'Nuance',
  Flatironhealth = 'Flatiron Health',
  Monday = 'Monday.com',
  Anduril = 'Anduril', // $8.4B
  AppliedIntuition = 'AppliedIntuition', //$3.6 billion
  DigitalOcean = 'DigitalOcean', //$3.05 billion
  Jasper = 'Jasper', //1.5B
  Harvey = 'Harvey', //715M
  Character_ai = 'Character.ai', //1B
  Ironclad = 'Ironclad', //3.2B
  GenerateBiomedicines = 'Generate Biomedicines', //1.9B
  ScaleAI = 'Scale AI', //7.3B
  Notion = 'Notion', // 10B
  Zapier = 'Zapier', // 5B
}

export type MarketMilestone = {
  type: MarketMilestoneKind;
  name: string;
  value_cents: number;
  value_year: string;
  logo: string;
  descriptor: 'acquired' | 'evaluated' | 'market cap';
};

export const MarketMilestoneDetails: Record<MarketMilestoneKind, MarketMilestone> = {
  [MarketMilestoneKind.Cloudflare]: {
    type: MarketMilestoneKind.Cloudflare,
    name: 'Cloudflare',
    value_cents: 1833000000000, //18.33 Billion
    value_year: 'Q3 2022',
    logo: 'cloudflare.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Figma]: {
    type: MarketMilestoneKind.Figma,
    name: 'Figma',
    value_cents: 850000000000, //8.5 Billion
    value_year: 'Q3 2022',
    logo: 'figma.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Twilio]: {
    type: MarketMilestoneKind.Twilio,
    name: 'Twilio',
    value_cents: 1265000000000, //12.65 Billion
    value_year: 'Q3 2022',
    logo: 'twilio.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Slack]: {
    type: MarketMilestoneKind.Slack,
    name: 'Slack',
    value_cents: 2651000000000, //26.51 Billion
    value_year: 'Q2 2022',
    logo: 'slack.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Asana]: {
    type: MarketMilestoneKind.Asana,
    name: 'Asana',
    value_cents: 338000000000,
    value_year: 'Q2 2022',
    logo: 'ASAN.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Salesforce]: {
    type: MarketMilestoneKind.Salesforce,
    name: 'Salesforce',
    value_cents: 1.6363e13,
    value_year: 'Q2 2022',
    logo: 'salesforce.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Alphabet]: {
    type: MarketMilestoneKind.Alphabet,
    name: 'Alphabet (Google)',
    value_cents: 1.5e14,
    value_year: 'Q2 2022',
    logo: 'alphabet.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Warby_Parker]: {
    type: MarketMilestoneKind.Warby_Parker,
    name: 'Warby Parker',
    value_cents: 155000000000,
    value_year: 'Q2 2022',
    logo: 'WRBY.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Amazon]: {
    type: MarketMilestoneKind.Amazon,
    name: 'Amazon',
    value_cents: 1.04e14,
    value_year: 'Q2 2022',
    logo: 'amazon.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Stripe]: {
    type: MarketMilestoneKind.Stripe,
    name: 'Stripe',
    value_cents: 9.5e12,
    value_year: 'Q4 2021',
    logo: 'stripe.png',
    descriptor: 'evaluated',
  },
  [MarketMilestoneKind.Airbnb]: {
    type: MarketMilestoneKind.Airbnb,
    name: 'Airbnb',
    value_cents: 6.293e12,
    value_year: 'Q2 2022',
    logo: 'ABNB.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Dropbox]: {
    type: MarketMilestoneKind.Dropbox,
    name: 'Dropbox',
    value_cents: 772000000000,
    value_year: 'Q2 2022',
    logo: 'DBX.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Datadog]: {
    type: MarketMilestoneKind.Datadog,
    name: 'Datadog',
    value_cents: 2.744e12,
    value_year: 'Q2 2022',
    logo: 'datadog.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Pagerduty]: {
    type: MarketMilestoneKind.Pagerduty,
    name: 'Pagerduty',
    value_cents: 198000000000,
    value_year: 'Q2 2022',
    logo: 'pagerduty.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Tesla]: {
    type: MarketMilestoneKind.Tesla,
    name: 'Tesla',
    value_cents: 6.8653e13,
    value_year: 'Q2 2022',
    logo: 'tesla.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Twitch]: {
    type: MarketMilestoneKind.Twitch,
    name: 'Twitch',
    value_cents: 100000000000,
    value_year: '2014',
    logo: 'twitch.png',
    descriptor: 'acquired',
  },
  [MarketMilestoneKind.Nuance]: {
    type: MarketMilestoneKind.Nuance,
    name: 'Nuance',
    value_cents: 1788000000000,
    value_year: '2022',
    logo: 'nuance.png',
    descriptor: 'acquired',
  },
  [MarketMilestoneKind.Flatironhealth]: {
    type: MarketMilestoneKind.Flatironhealth,
    name: 'Flatiron Health',
    value_cents: 190000000000,
    value_year: '2018',
    logo: 'flatiron.svg',
    descriptor: 'acquired',
  },
  [MarketMilestoneKind.Monday]: {
    type: MarketMilestoneKind.Monday,
    name: 'Monday.com',
    value_cents: 6.22e11,
    value_year: '2023',
    logo: 'monday.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Anduril]: {
    type: MarketMilestoneKind.Anduril,
    name: 'Anduril',
    value_cents: 840000000000,
    value_year: 'Q1 2023',
    logo: 'anduril_tech.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.AppliedIntuition]: {
    type: MarketMilestoneKind.AppliedIntuition,
    name: 'Applied Intuition',
    value_cents: 360000000000,
    value_year: 'Q4 2021',
    logo: 'applied_intuition.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.DigitalOcean]: {
    type: MarketMilestoneKind.DigitalOcean,
    name: 'DigitalOcean',
    value_cents: 305000000000,
    value_year: 'Q4 2023',
    logo: 'digitalocean.svg',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Jasper]: {
    type: MarketMilestoneKind.Jasper,
    name: 'Jasper',
    value_cents: 150000000000, //1.5 Billion
    value_year: 'Oct 2022',
    logo: 'jasper.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Harvey]: {
    type: MarketMilestoneKind.Harvey,
    name: 'Harvey',
    value_cents: 71500000000, //715 Million
    value_year: 'Dec 2023',
    logo: 'harvey.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Character_ai]: {
    type: MarketMilestoneKind.Character_ai,
    name: 'Character.ai',
    value_cents: 100000000000, //1 Billion
    value_year: 'Nov 2023',
    logo: 'character_ai.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Ironclad]: {
    type: MarketMilestoneKind.Ironclad,
    name: 'Ironclad',
    value_cents: 320000000000, //3.2 Billion
    value_year: 'Jan 2022',
    logo: 'ironclad.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.GenerateBiomedicines]: {
    type: MarketMilestoneKind.GenerateBiomedicines,
    name: 'Generate Biomedicines',
    value_cents: 190000000000, //1.9 Billion
    value_year: 'Sept 2023',
    logo: 'generate_biomedicines.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.ScaleAI]: {
    type: MarketMilestoneKind.ScaleAI,
    name: 'Scale AI',
    value_cents: 730000000000, //7.3 Billion
    value_year: 'May 2023',
    logo: 'scale_ai.webp',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Notion]: {
    type: MarketMilestoneKind.Notion,
    name: 'Notion',
    value_cents: 1000000000000, //10 Billion
    value_year: 'Oct 2021',
    logo: 'notion.png',
    descriptor: 'market cap',
  },
  [MarketMilestoneKind.Zapier]: {
    type: MarketMilestoneKind.Zapier,
    name: 'Zapier',
    value_cents: 500000000000, //5 Billion
    value_year: 'Jan 2021',
    logo: 'zapier.svg',
    descriptor: 'market cap',
  },
};

export const sortedMarketMilestoneList = Object.values(MarketMilestoneDetails).sort((a, b) => a.name.localeCompare(b.name));

// @ts-ignore (fix with reika changes)
export const ProfileRoleDisplay: Record<ProfileGroupType, string> = {
  admin: 'Admin',
  employee: 'Employee',
  recruiter: 'Recruiter',
  talent_admin: 'Talent Admin',
  lead: 'Lead',
  hrbp: 'HRBP',
};

export const profileRoleColorMap: { [role in ProfileGroupType]: BadgeColorType } = {
  admin: 'green',
  lead: 'yellow',
  employee: 'yellow',
  hrbp: 'blue',
  recruiter: 'pink',
  talent_admin: 'indigo',
};

export enum JobFamilyNames {
  CUSTOMER_SUCCESS = 'Customer Success',
  SWE = 'Engineering',
  HWE = 'Hardware Engineering',
  DEVOPS = 'Development Operations',
  IT = 'Information Technology',
  DSR = 'Data Science and Research',
  PD = 'Product Design',
  PM = 'Product Management',
  CREATIVE = 'Creative',
  MARKETING = 'Marketing',
  BIZDEV = 'Business Development',
  SALES = 'Sales',
  AM = 'Account Management',
  CS = 'Customer Success',
  TRAINING = 'Training',
  PROSERV = 'Consulting and Professional Services',
  MANUFACTURING = 'Manufacturing',
  FINANCE = 'Finance',
  HR = 'Human Resources',
  AOM = 'Admin and Office Management',
  OPS = 'Operations and Facilities',
}

export enum JobTrackType {
  PROFESSIONAL = 'professional',
  MANAGEMENT = 'management',
  EXECUTIVE = 'executive',
  SUPPORT = 'support',
}

export const toJobTrackType = (s?: string) => {
  switch (s) {
    case 'professional':
    case 'management':
    case 'executive':
    case 'support':
    case undefined:
    case null:
      return s;
    default:
      captureException(Error(`toJobTrackType: unknown job track: ${s}`), {
        extra: { s },
      });
      return undefined;
  }
};

export const JobTrackDisplay: { [key in JobTrackType]: string } = {
  professional: 'Professional',
  management: 'Management',
  executive: 'Executive',
  support: 'Support',
};

export const JOB_TRACK_TO_ABBREVIATION: { [key in JobTrackType]: string } = {
  professional: 'P',
  management: 'M',
  executive: 'E',
  support: 'S',
};

export const jobTrackAndLevelDisplay = (jobTrack?: string, levelNum?: number, title?: string) => {
  const jobTrackType = toJobTrackType(jobTrack);

  return levelNum != undefined
    ? `${jobTrackType ? JOB_TRACK_TO_ABBREVIATION[jobTrackType] : ''}${levelNum} ${title ? `${title}` : ''}`.trim()
    : undefined;
};

export const jobTrackAndLevelAndTitleDisplay = (jobTrack?: string, levelNum?: number, title?: string) =>
  [jobTrackAndLevelDisplay(jobTrack, levelNum), title].filter((item) => !!item).join(' - ');

export const ADMIN_GROUP = 'admin';
export const TALENT_ADMIN_GROUP = 'talent_admin';
export const RECRUITER_GROUP = 'recruiter';
export const LEAD_GROUP = 'lead';
export const EMPLOYEE_GROUP = 'employee';
export const HRBP_GROUP = 'hrbp';
export const rolesWithOfferPermission: ProfileGroupType[] = [ADMIN_GROUP, TALENT_ADMIN_GROUP, RECRUITER_GROUP];

export const canAccessLevelsData = (admin: boolean, enableSalaryRangeTransparency: boolean, hasOfferAddPermission: boolean) => {
  return admin || (!admin && enableSalaryRangeTransparency) || (!admin && hasOfferAddPermission);
};

export enum BudgetUnitDescriptor {
  SALARY_PERCENTAGE = 'SALARY_PERCENTAGE',
  SALARY_ABSOLUTE = 'SALARY_ABSOLUTE',
  BONUS_PERCENTAGE = 'BONUS_PERCENTAGE',
  BONUS_ABSOLUTE = 'BONUS_ABSOLUTE',
  VARIABLE_COMMISSION_PERCENTAGE = 'VARIABLE_COMMISSION_PERCENTAGE',
  VARIABLE_COMMISSION_ABSOLUTE = 'VARIABLE_COMMISSION_ABSOLUTE',
}

export enum TitleTypes {
  Salary = 'Salary',
  Bonus = 'Bonus',
  Equity = 'Equity',
  VariableComp = 'Variable Comp',
}

export type EquityType = 'RSU' | 'ISO' | 'NSO';

export const FlatfileImportTitles: { [key in FlatfileImportKind]: string } = {
  BENCHMARK_REPORT_IMPORT: 'Benchmarking Imports',
  CAPTABLE: 'Captable Imports',
  COMPENSATION_PLAN_CONFIGURATION: 'Compensation Plan Configuration Import',
  HRIS: 'HRIS Import',
  PERFORMANCE_RATING: 'Performance Rating Imports',
  LEVELS_IMPORT: 'Levels Import',
  EMPLOYEE_COMMISSIONS: 'Employee variable commissions import',
  TRANCHE_REPORT: 'Tranche report import',
};
export enum ActivityActionDisplay {
  UNKNOWN = 'UNKNOWN',
  UPDATE_NOTE = 'UPDATE_NOTE',
  COMMENTED = 'COMMENTED',
  CREATE_COMPENSATION_SCENARIO = 'CREATE_COMPENSATION_SCENARIO',
  UPDATE_SCENARIO_LAUNCHED = 'UPDATE_SCENARIO_LAUNCHED',
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_EQUITY = 'UPDATE_EQUITY',
  UPDATE_SALARY = 'UPDATE_SALARY',
  UPDATE_BONUS = 'UPDATE_BONUS',
  UPDATE_VARIABLE_COMP = 'UPDATE_VARIABLE_COMP',
  UPDATE_TITLE = 'UPDATE_TITLE',
  PROMOTED = 'PROMOTED',
  REMOVE_PROMOTION = 'REMOVE_PROMOTION',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DELETED_ADJUSTMENT = 'DELETED_ADJUSTMENT',
}

export enum PerformanceRatingStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}
export enum ProgressStatusTypes {
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  DONE = 'DONE',
}

export type ProgressStatus = ProgressStatusTypes;

export enum CompensationPlanStatusTypes {
  implemented = 'implemented',
  draft = 'draft',
  archived = 'archived',
}

export enum AdjustmentTypeEnum {
  SALARY = 'SALARY',
  BONUS = 'BONUS',
  EQUITY = 'EQUITY',
  VARIABLE_COMP = 'VARIABLE_COMP',
}

export type CompensationPlanStatus = CompensationPlanStatusTypes;

export enum BandRangeType {
  salary = 'salary',
  equity = 'equity',
  bonus = 'bonus',
  variable = 'variable',
  total = 'total',
}

export const BandRangeTypedToTitles: { [key in keyof typeof BandRangeType]: string } = {
  salary: 'Salary',
  equity: 'Equity',
  bonus: 'Bonus',
  variable: 'Variable',
  total: 'Total Comp',
};

export const toBandRangeTypeTitleDisplay = (bandType?: string) => {
  switch (bandType) {
    case BandRangeType.salary:
      return 'Salary';
    case BandRangeType.equity:
      return 'Equity';
    case BandRangeType.bonus:
      return 'Bonus';
    case BandRangeType.variable:
      return 'Variable Comp';
    case BandRangeType.total:
      return 'Total Comp';
    default:
      return '';
  }
};

export enum BandName {
  default = 'default',
  tier1 = 'tier1',
  tier2 = 'tier2',
  tier3 = 'tier3',
  tier4 = 'tier4',
}

export const toBandName = (bandName?: string) => {
  switch (bandName) {
    case 'default':
      return BandName.default;
    case 'tier1':
      return BandName.tier1;
    case 'tier2':
      return BandName.tier2;
    case 'tier3':
      return BandName.tier3;
    case 'tier4':
      return BandName.tier4;
    default:
      return undefined;
  }
};

export const toBandNameTitleDisplay = (bandName?: string) => {
  switch (bandName) {
    case BandName.default:
      return 'Default';
    case BandName.tier1:
      return 'Tier 1';
    case BandName.tier2:
      return 'Tier 2';
    case BandName.tier3:
      return 'Tier 3';
    case BandName.tier4:
      return 'Tier 4';
    default:
      return '';
  }
};

export const toBandNameTruncatedDisplay = (bandName?: string) => {
  switch (bandName) {
    case BandName.default:
      return '';
    case BandName.tier1:
      return TIER_1;
    case BandName.tier2:
      return TIER_2;
    case BandName.tier3:
      return TIER_3;
    case BandName.tier4:
      return TIER_4;
    default:
      return '';
  }
};

export const TIER_1 = 1;
export const TIER_2 = 2;
export const TIER_3 = 3;
export const TIER_4 = 4;

export const TierNumbers = [TIER_1, TIER_2, TIER_3, TIER_4];

export enum PayPeriod {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  EveryTwoWeeks = 'Every Two Weeks',
  SemiMonthly = 'Semimonthly',
  Month = 'Month',
  Quarter = 'Quarter',
  EverySixMonths = 'Every Six Months',
  Year = 'Year',
}

export enum PaymentTypeKind {
  Recurring = 'RECURRING',
  OneTime = 'ONE_TIME',
}

export const PaymentTypeDisplay: Record<PaymentTypeKind, string> = {
  [PaymentTypeKind.Recurring]: 'Recurring',
  [PaymentTypeKind.OneTime]: 'One Time',
};
