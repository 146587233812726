import { createContext, useContext } from 'react';

export type WelcomeThemeType = {
  welcome: string;
  offer_page: string;
  scenario_card: string;
  scenario_card_divider: string;
};

interface IThemeProps {
  // List properties of theming
  colors: Record<string, string>;
  theme?: WelcomeThemeType;
  themeName: string;
}

const themeContextDefaultValues: IThemeProps = { colors: {}, themeName: '' };

export const ThemeContext = createContext<IThemeProps>(themeContextDefaultValues);

export function useThemeContext() {
  return useContext(ThemeContext);
}
