import { useQueryClient } from '@tanstack/react-query';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAuthenticatedClient, useAuthenticatedPhoenixClient } from 'services/client';
import {
  CompensationChangeScenarioInputType,
  CompensationChangeScenarioType,
  CompensationScenarioCohortInputType,
  CompensationScenarioDataDumpType,
  CompensationScenarioDataDumpV2Type,
  DeleteCompensationChangeCohortInput,
  NotifyApprovalEmployeeInput,
  ScenarioEmployeeInputPartial,
  ScenarioEmployeeInviteInput,
} from 'services/generated';
import { ScenarioBudgetInput, ScenarioBudgetInputPartial } from 'services/generated/schema';
import {
  createCompensationChangeCohort,
  createCompensationChangeScenarioDetail,
  createScenarioBudget,
  deleteCompensationChangeCohort,
  notifyApprovalEmployee,
  scenarioEmployeeInvite,
  updateCompensationChangeCohort,
  updateCompensationChangeScenarioDetail,
  updateScenarioBudget,
  updateScenarioEmployee,
} from 'services/scenarios/mutations';
import {
  getCompensationChangeScenarioList,
  getCompensationScenarioDataDump,
  getCompensationScenarioDataDumpV2,
  getCompensationScenarioDetail,
  getCompensationScenarioDetailV2,
  ShallowCompensationChangeScenarioListType,
} from 'services/scenarios/queries';
import {
  CohortFragmentType,
  CompensationScenarioDetailType,
  ExtendedCompScenarioDetailPhoenixRisingType,
  NotifyApprovalEmployeeFragmentType,
  ScenarioEmployeeFragmentType,
  ScenarioEmployeeInviteFragmentType,
} from 'services/scenarios/types';

export const COMP_SCENARIO_LIST_CACHE_KEY: string[] = ['comp-scenario', 'list', 'shallow'];

export const BENCHMARKING_IMPORTS_CACHE_KEY: string[] = ['benchmarking-imports', 'list'];

export const GET_SCENARIO_DETAILS_CACHE_KEY = (uuid: string): string[] => ['comp-scenario', 'detail', uuid];
export const GET_SCENARIO_DETAILS_CACHE_KEY_V2 = (uuid: string): string[] => ['comp-scenario-v2', 'detail-phoenix-rising', uuid];
export const GET_COMP_PLAN_CONFIG_CACHE_KEY = (uuid: string): string[] => ['comp-plan-config', uuid];

export const useCompScenarioList = (options: UseQueryOptions<ShallowCompensationChangeScenarioListType> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<ShallowCompensationChangeScenarioListType>(
    COMP_SCENARIO_LIST_CACHE_KEY,
    () => getCompensationChangeScenarioList(client),
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnReconnect: true,
    },
  );
};

export const useCompensationScenarioDataDump = (uuid: string, options: UseQueryOptions<CompensationScenarioDataDumpType> = {}) => {
  const client = useAuthenticatedClient();
  return useQuery<CompensationScenarioDataDumpType>(
    [...GET_SCENARIO_DETAILS_CACHE_KEY(uuid), 'dump'],
    () => getCompensationScenarioDataDump(uuid, client),
    options,
  );
};

export const useCompensationScenarioDataDumpV2 = (
  uuid: string,
  profileId: number,
  options: UseQueryOptions<CompensationScenarioDataDumpV2Type> = {},
) => {
  const client = useAuthenticatedClient();
  return useQuery<CompensationScenarioDataDumpV2Type>(
    [...GET_SCENARIO_DETAILS_CACHE_KEY(uuid), 'dump'],
    () => getCompensationScenarioDataDumpV2(uuid, profileId, client),
    options,
  );
};

export const useCompensationScenarioDetail = (uuid: string, options: UseQueryOptions<CompensationScenarioDetailType> = {}) => {
  const phoenixClient = useAuthenticatedPhoenixClient();
  return useQuery<CompensationScenarioDetailType>(
    GET_SCENARIO_DETAILS_CACHE_KEY(uuid),
    () => getCompensationScenarioDetail(uuid, phoenixClient),
    options,
  );
};

export const useCompensationScenarioDetailPhoenixRising = (
  uuid: string,
  profileId: number,
  options: UseQueryOptions<ExtendedCompScenarioDetailPhoenixRisingType> = {},
) => {
  const phoenixClient = useAuthenticatedPhoenixClient();
  return useQuery<ExtendedCompScenarioDetailPhoenixRisingType>(
    GET_SCENARIO_DETAILS_CACHE_KEY_V2(uuid),
    () => getCompensationScenarioDetailV2(uuid, profileId, phoenixClient),
    options,
  );
};

export const useNotifyApprovalEmployee = (
  options: UseMutationOptions<NotifyApprovalEmployeeFragmentType, unknown, NotifyApprovalEmployeeInput> = {},
) => {
  const client = useAuthenticatedClient();
  return useMutation<NotifyApprovalEmployeeFragmentType, unknown, NotifyApprovalEmployeeInput>(
    (input) => notifyApprovalEmployee(input, client),
    options,
  );
};

export const useCreateCompensationChangeScenarioDetail = (
  options: UseMutationOptions<Pick<CompensationChangeScenarioType, 'uuid'>, unknown, CompensationChangeScenarioInputType> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();

  const client = useAuthenticatedClient();
  return useMutation<Pick<CompensationChangeScenarioType, 'uuid'>, unknown, CompensationChangeScenarioInputType>(
    (input: CompensationChangeScenarioInputType) => createCompensationChangeScenarioDetail(input, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        await queryData.invalidateQueries({ queryKey: GET_COMP_PLAN_CONFIG_CACHE_KEY(data.uuid) });
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

export const useUpdateCompensationChangeScenarioDetail = (
  options: UseMutationOptions<void, unknown, CompensationChangeScenarioInputType> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();

  const client = useAuthenticatedClient();
  return useMutation<void, unknown, CompensationChangeScenarioInputType>((input) => updateCompensationChangeScenarioDetail(input, client), {
    onSuccess: async (data, variables, ...restOfArgs) => {
      const scenarioUUID = variables.uuid;
      await queryData.invalidateQueries({ queryKey: GET_SCENARIO_DETAILS_CACHE_KEY(scenarioUUID) });
      await queryData.invalidateQueries({ queryKey: GET_COMP_PLAN_CONFIG_CACHE_KEY(scenarioUUID) });
      if (onSuccess) {
        await onSuccess(data, variables, ...restOfArgs);
      }
    },
    ...restOfOptions,
  });
};

export const useUpdateScenarioEmployee = (
  options: UseMutationOptions<ScenarioEmployeeFragmentType, unknown, ScenarioEmployeeInputPartial> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();
  const client = useAuthenticatedClient();

  return useMutation<ScenarioEmployeeFragmentType, unknown, ScenarioEmployeeInputPartial>(
    (input) => updateScenarioEmployee(input, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        await queryData.invalidateQueries({ queryKey: GET_SCENARIO_DETAILS_CACHE_KEY(data.compensationChangeScenario.uuid) });
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

// NOTE: just use "useMutation(createCompensationChangeCohort)" and override the onSuccess function if necessary
export const useCreateCompensationChangeCohort = (
  options: UseMutationOptions<CohortFragmentType, unknown, CompensationScenarioCohortInputType> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();
  const client = useAuthenticatedClient();

  return useMutation<CohortFragmentType, unknown, CompensationScenarioCohortInputType>(
    (input) => createCompensationChangeCohort(input, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        await queryData.invalidateQueries({ queryKey: GET_SCENARIO_DETAILS_CACHE_KEY(data.scenario.uuid) });
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

// NOTE: just use "useMutation(updateCompensationChangeCohort)" and override the onSuccess function if necessary
export const useUpdateCompensationChangeCohort = (
  options: UseMutationOptions<CohortFragmentType, unknown, CompensationScenarioCohortInputType> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();
  const client = useAuthenticatedClient();

  return useMutation<CohortFragmentType, unknown, CompensationScenarioCohortInputType>(
    (input) => updateCompensationChangeCohort(input, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        await queryData.invalidateQueries({ queryKey: GET_SCENARIO_DETAILS_CACHE_KEY(data.scenario.uuid) });
        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

// NOTE: just use "useMutation(deleteCompensationChangeCohort)" and override the onSuccess function if necessary
export const useDeleteCompensationChangeCohort = (options: UseMutationOptions<void, unknown, DeleteCompensationChangeCohortInput> = {}) => {
  const { onSuccess, ...restOfOptions } = options;
  const client = useAuthenticatedClient();

  return useMutation<void, unknown, DeleteCompensationChangeCohortInput>((input) => deleteCompensationChangeCohort(input, client), {
    onSuccess: async (data, variables, ...restOfArgs) => {
      // TODO: invalidate the cache for this scenario. not urgent as we usually manually refetch
      // await queryData.invalidateQueries({ queryKey: GET_SCENARIO_DETAILS_CACHE_KEY(data.scenario.uuid) });
      if (onSuccess) {
        await onSuccess(data, variables, ...restOfArgs);
      }
    },
    ...restOfOptions,
  });
};

export const useScenarioEmployeeInvite = (
  options: UseMutationOptions<ScenarioEmployeeInviteFragmentType, unknown, ScenarioEmployeeInviteInput> = {},
) => {
  const { onSuccess, ...restOfOptions } = options;
  const queryData = useQueryClient();
  const client = useAuthenticatedClient();

  return useMutation<ScenarioEmployeeInviteFragmentType, unknown, ScenarioEmployeeInviteInput>(
    (input) => scenarioEmployeeInvite(input, client),
    {
      onSuccess: async (data, ...restOfArgs) => {
        const scenarioDetail = queryData.getQueryData<CompensationScenarioDetailType>(
          GET_SCENARIO_DETAILS_CACHE_KEY(data.scenarioEmployee.compensationChangeScenario.uuid),
        );

        if (scenarioDetail) {
          queryData.setQueryData(GET_SCENARIO_DETAILS_CACHE_KEY(data.scenarioEmployee.compensationChangeScenario.uuid), {
            ...scenarioDetail,
            scenarioEmployees: [
              ...scenarioDetail.scenarioEmployees.filter((scenarioEmployee) => scenarioEmployee.id !== data.scenarioEmployee.id),
              data.scenarioEmployee,
            ],
          });
        }

        if (onSuccess) {
          await onSuccess(data, ...restOfArgs);
        }
      },
      ...restOfOptions,
    },
  );
};

export const useCreateScenarioBudget = (scenarioID: string) => {
  const queryClient = useQueryClient();
  const client = useAuthenticatedClient();
  return useMutation({
    mutationFn: (input: ScenarioBudgetInput) => createScenarioBudget(input, client),
    onSuccess: async (_data, _variables, _context) => {
      if (queryClient) {
        await queryClient.invalidateQueries(GET_SCENARIO_DETAILS_CACHE_KEY(scenarioID));
      }
    },
  });
};

export const useUpdateScenarioBudget = (scenarioID: string) => {
  const queryClient = useQueryClient();
  const client = useAuthenticatedClient();
  return useMutation({
    mutationFn: (input: ScenarioBudgetInputPartial) => updateScenarioBudget(input, client),
    onSuccess: async (_data, _variables, _context) => {
      if (queryClient) {
        await queryClient.invalidateQueries(GET_SCENARIO_DETAILS_CACHE_KEY(scenarioID));
      }
    },
  });
};
