import 'tailwindcss/tailwind.css';
import 'intro.js/introjs.css';
import 'styles/globals.css';
import 'styles/nprogress.css';
import 'styles/tour.css';
import 'styles/flatfileembed.css';

import { ClerkProvider } from '@clerk/nextjs';
import { datadogRum } from '@datadog/browser-rum';
import { captureException, ErrorBoundary, withScope } from '@sentry/nextjs';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics as NextAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import Analytics from 'components/Analytics';
import BrowserWarning from 'components/common/banners/BrowserWarning';
import DowntimeNotice from 'components/common/banners/DowntimeNotice';
import Button from 'components/common/button/Button';
import { ModalProvider } from 'components/common/modal/ModalContext';
import Typography from 'components/common/Typography';
import { PreferencesContextProvider, ThemeContext } from 'components/core/context';
import { AuthenticationContextProvider } from 'components/core/context/AuthenticationContext';
import { LaunchDarklyProvider } from 'components/core/context/LaunchDarklyProvider';
import { OrganizationContextProvider } from 'components/core/context/OrganizationContext';
import { WelcomeThemeType } from 'components/core/context/ThemeContext';
import VersionNotifier from 'components/VersionNotifier';
import { detect } from 'detect-browser';
import { colors as appColors, welcomeThemes as appThemes } from 'lib/colors';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Error from 'next/error';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { pdfjs } from 'react-pdf';
import { ClientProvider } from 'services/client';
import { queryOptions } from 'services/client';
import { analyticsMutationCache } from 'utils/analytics';

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ErrorFallback = ({
  error,
}: {
  error?: {
    message?: string;
  };
}) => {
  return (
    <div role='alert' className='flex h-screen w-screen flex-col items-center justify-center gap-4'>
      <Typography.Heading variant='h4'>Something went wrong</Typography.Heading>
      <Typography.Paragraph>{error?.message}</Typography.Paragraph>
      <div>
        <Button
          onPress={() => {
            // @ts-ignore
            window.location = '/';
          }}
        >
          Try again
        </Button>
      </div>
    </div>
  );
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Complete({ Component, pageProps }: AppPropsWithLayout) {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const shouldPrintSegmentAnalytics = process.env.NEXT_PUBLIC_SHOULD_PRINT_SEGMENT_ANALYTICS === 'true';

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: analyticsMutationCache,
        queryCache: new QueryCache({
          onError: (error, query) => {
            withScope((scope) => {
              scope.setTag('kind', 'client-query-error');
              scope.setExtra('queryState', query.state);
              scope.setExtra('queryOptions', query.options);
              scope.setExtra('queryKey', query.queryKey);
              captureException(error);
            });
          },
        }),
        defaultOptions: {
          queries: queryOptions,
        },
      }),
  );
  const router = useRouter();

  // This initializes Segment analytics to be non-null when page is server-side rendered,
  // and in non-prod environments, where we don't load the analytics snippet
  if (typeof window === 'undefined' || !isProduction) {
    // @ts-ignore
    global.analytics = { track: () => null, identify: () => null, page: () => null };
  }

  // For debugging and development purposes, logs analytics to console
  if (shouldPrintSegmentAnalytics) {
    // @ts-ignore
    global.analytics = {
      track: (eventName) => console.log(`[segment] ${eventName}`),
      identify: (eventName) => console.log(`[segment] ${eventName}`),
      page: (eventName) => console.log(`[segment] ${eventName}`),
    };
  }

  if (typeof window !== 'undefined') {
    const { publicRuntimeConfig } = getConfig();

    NProgress.configure({
      showSpinner: publicRuntimeConfig.NProgressShowSpinner,
    });

    // @ts-ignore
    Router.onRouteChangeStart = () => {
      NProgress.start();
    };

    // @ts-ignore
    Router.onRouteChangeComplete = () => {
      NProgress.done();
    };

    // @ts-ignore
    Router.onRouteChangeError = () => {
      NProgress.done();
    };
  }

  // Datadog RUM + session replays
  useEffect(() => {
    if (isProduction) {
      datadogRum.init({
        applicationId: '4fbe2071-3851-48c8-b0cd-fe492e375af8',
        clientToken: 'pubc13c33e1bc66dfaae8a2ecb5fc657dd6',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'complete-nextjs-app',
        env: process.env.NEXT_PUBLIC_VERCEL_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
      });
    }
  }, [isProduction]);

  const [colors, setColors] = useState(appColors.default);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [theme, setTheme] = useState<WelcomeThemeType>(appThemes.default!);
  const [orgTheme] = useState(() => {
    if (pageProps?.offer && pageProps?.offer?.customization?.appTheme) {
      return pageProps?.offer?.customization?.appTheme;
    }

    if (pageProps.organization) {
      if (pageProps?.organization?.customization?.appTheme) {
        return pageProps?.organization?.customization?.appTheme;
      }

      return pageProps?.organization?.identifier;
    } else if (pageProps.offer) {
      if (pageProps?.offer?.organization?.customization?.appTheme) {
        return pageProps?.offer?.organization?.customization?.appTheme;
      }

      return pageProps?.offer?.organization?.identifier;
    }
    return 'default';
  });

  const [supportMessage, setSupportMessage] = useState(pageProps?.error?.message);
  useEffect(() => {
    const browser = detect();
    const { name = '', version = '' } = browser || {};

    const sp = (version || '').split('.');
    const major = sp.length > 0 ? Number(sp[0]) : undefined;
    if (name === 'safari' && major && major < 14) {
      setSupportMessage('This version of Safari is not supported');
      captureException('unsupported browser safari');
    }
    if (name === 'chrome' && major && major < 64) {
      setSupportMessage('This version of Chrome is not supported');
      captureException('unsupported browser chrome');
    }
    if (name === 'ie' || name === 'pie') {
      setSupportMessage('This version of Internet Explorer is not supported');
      captureException('unsupported browser internet explorer');
    }
  }, []);

  useEffect(() => {
    // org theme should only be set when on the offer or other
    // candidate / employee experiences.
    if (!router.pathname.startsWith('/offer/')) {
      return;
    }
    // @ts-ignore
    if (appColors[orgTheme]) {
      document.querySelector('html')?.setAttribute('data-theme', orgTheme);
      // @ts-ignore
      setColors(appColors[orgTheme]);
    }

    if (appThemes[orgTheme]) {
      setTheme(appThemes[orgTheme] as WelcomeThemeType);
    }
  }, [orgTheme, router.pathname]);

  const isRootIndex = router.pathname === '/';
  const showError = !isRootIndex && !!supportMessage;

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <main>
      <DowntimeNotice />
      <VersionNotifier />
      <ClerkProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate
            state={pageProps?.dehydratedState}
            options={{
              defaultOptions: {
                queries: queryOptions,
              },
            }}
          >
            <LaunchDarklyProvider>
              <ThemeContext.Provider value={{ colors, theme, themeName: orgTheme }}>
                {showError ? (
                  <Error statusCode={pageProps?.error?.code || 500} title={supportMessage} />
                ) : (
                  <ErrorBoundary
                    fallback={ErrorFallback}
                    showDialog
                    onError={(error) => {
                      // TODO: this should not be necessary, but it doesn't seem like sentry is capturing exceptions
                      // at these boundaries by default
                      captureException(error);
                    }}
                  >
                    <OrganizationContextProvider>
                      <AuthenticationContextProvider>
                        <PreferencesContextProvider>
                          <ClientProvider>
                            <Analytics>
                              <ModalProvider>
                                {getLayout(<Component {...pageProps} />)}
                                <NextAnalytics />
                                <Toaster />
                                <BrowserWarning />
                                <SpeedInsights />
                              </ModalProvider>
                            </Analytics>
                          </ClientProvider>
                        </PreferencesContextProvider>
                      </AuthenticationContextProvider>
                    </OrganizationContextProvider>
                  </ErrorBoundary>
                )}
              </ThemeContext.Provider>
            </LaunchDarklyProvider>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ClerkProvider>
    </main>
  );
}
