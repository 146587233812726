import { raiseOnOperationInfo } from 'lib/errors';
import { OPERATION_INFO_FRAGMENT } from 'services/common/fragments';
import {
  BenchmarkImportFilterUpdateInput,
  Client,
  NotificationConfigurationInput,
  NotificationRecipientInput,
  OrganizationCreateInput,
  OrganizationImportSourceInputPartial,
  OrganizationImportSourceResponse,
  OrganizationInputPartial,
  UpdateBenchmarkImportFiltersPayload,
  UpdateOrganizationPayload,
} from 'services/generated';
import { CREATE_ORGANIZATION_RETURN_FRAGMENT, NOTIFICATION_CONFIGURATIONS_FRAGMENT } from 'services/organization/fragments';
import { CreateOrganizationReturnType } from 'services/organization/queries';

export const createOrganization = async (input: OrganizationCreateInput, client: Client): Promise<CreateOrganizationReturnType> => {
  const data = await client.chain.mutation.createOrganization({ input }).get(CREATE_ORGANIZATION_RETURN_FRAGMENT);
  return data as CreateOrganizationReturnType;
};

// add a new notification type to an organization
export const addNotificationType = async (id: number, type: string, client: Client): Promise<UpdateOrganizationPayload> => {
  const data = await client.chain.mutation.updateOrganization({ input: { id, notificationConfigurations: { add: [{ type }] } } }).get({
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
    on_OrganizationType: {
      id: true,
      name: true,
      notificationConfigurations: {
        ...NOTIFICATION_CONFIGURATIONS_FRAGMENT,
      },
    },
  });

  return data as UpdateOrganizationPayload;
};

// updated and existing notification config for an organization
// @params
// id: organization id
// updateData: array of partial or complete notification configuration inputs
export const updateNotificationType = async (
  id: number,
  updateData: Partial<NotificationConfigurationInput>[],
  client: Client,
): Promise<UpdateOrganizationPayload> => {
  // set is something that is generated by the backend when a field is using gql.ListInput as its type
  // it returns 3 options you can do with the list data, add, remove, set
  // in this case we are using set to update the list,
  // !!!!IMPORTANT!!!!!!!!
  // if you are using set, you must pass in the entire list of data, not just the data you want to update
  // since it will delete the old data and write it again with the data you pass in
  const data = await client.chain.mutation.updateOrganization({ input: { id, notificationConfigurations: { set: [...updateData] } } }).get({
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
    on_OrganizationType: {
      id: true,
      name: true,
      notificationConfigurations: {
        ...NOTIFICATION_CONFIGURATIONS_FRAGMENT,
      },
    },
  });

  return data as UpdateOrganizationPayload;
};

// add notificationRecipients to an organization
// @params
// id: organization id
// email: array of notification recipient inputs
export const addOrganizationNotificationRecipients = async (
  id: number,
  notificationRecipients: NotificationRecipientInput[],
  client: Client,
): Promise<UpdateOrganizationPayload> => {
  const data = await client.chain.mutation
    .updateOrganization({ input: { id, notificationRecipients: { add: notificationRecipients } } })
    .get({
      on_OperationInfo: OPERATION_INFO_FRAGMENT,
      on_OrganizationType: {
        id: true,
        name: true,
        notificationRecipients: {
          email: true,
        },
      },
    });

  return data as UpdateOrganizationPayload;
};

// update notificationRecipients to an organization
// @params
// id: organization id
// email: array of notification recipient inputs
export const updateOrganizationNotificationRecipients = async (
  id: number,
  notificationRecipients: NotificationRecipientInput[],
  client: Client,
): Promise<UpdateOrganizationPayload> => {
  const data = await client.chain.mutation
    .updateOrganization({ input: { id, notificationRecipients: { set: notificationRecipients } } })
    .get({
      on_OperationInfo: {
        __typename: true,
        messages: {
          field: true,
          message: true,
          kind: true,
        },
      },
      on_OrganizationType: {
        id: true,
        name: true,
        notificationRecipients: {
          id: true,
          email: true,
        },
      },
    });

  return data as UpdateOrganizationPayload;
};

export const updateOrganization = async (input: OrganizationInputPartial, client: Client): Promise<UpdateOrganizationPayload> => {
  const { ...data } = await client.chain.mutation.updateOrganization({ input }).get({
    on_OperationInfo: OPERATION_INFO_FRAGMENT,
    on_OrganizationType: {
      id: true,
      name: true,
      notificationConfigurations: {
        ...NOTIFICATION_CONFIGURATIONS_FRAGMENT,
      },
      defaultPerformanceRatingScheme: {
        id: true,
      },
      __typename: true,
    },
  });

  if (data.__typename === 'OperationInfo') {
    throw new Error(data.messages.toString());
  }

  return data as UpdateOrganizationPayload;
};

export const createOrganizationImportSource = async (
  input: OrganizationImportSourceInputPartial,
  client: Client,
): Promise<OrganizationImportSourceResponse> => {
  const { ...data } = await client.chain.mutation.createOrganizationImportSource({ input }).get({
    __typename: true,
    on_OperationInfo: {
      __typename: true,
      messages: {
        field: true,
        message: true,
        kind: true,
      },
    },
    on_OrganizationImportSourceResponse: {
      success: true,
    },
  });

  raiseOnOperationInfo(data);

  return data as OrganizationImportSourceResponse;
};

export const relinkOrganizationImportSource = async (
  input: OrganizationImportSourceInputPartial,
  client: Client,
): Promise<OrganizationImportSourceResponse> => {
  const { ...data } = await client.chain.mutation.relinkOrganizationImportSource({ input }).get({
    __typename: true,
    on_OperationInfo: {
      __typename: true,
      messages: {
        field: true,
        message: true,
        kind: true,
      },
    },
    on_OrganizationImportSourceResponse: {
      success: true,
    },
  });
  raiseOnOperationInfo(data);
  return data as OrganizationImportSourceResponse;
};

export const updateBenchmarkImportFilters = async (
  input: BenchmarkImportFilterUpdateInput,
  client: Client,
): Promise<UpdateBenchmarkImportFiltersPayload> => {
  const { ...data } = await client.chain.mutation.updateBenchmarkImportFilters({ input }).get({ __typename: true });
  return data;
};
