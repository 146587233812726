
var AggregateReferenceType_possibleTypes = ['AggregateReferenceType']
module.exports.isAggregateReferenceType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAggregateReferenceType"')
  return AggregateReferenceType_possibleTypes.includes(obj.__typename)
}



var ApplicationType_possibleTypes = ['ApplicationType']
module.exports.isApplicationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isApplicationType"')
  return ApplicationType_possibleTypes.includes(obj.__typename)
}



var ArchiveOfferTemplatePayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isArchiveOfferTemplatePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isArchiveOfferTemplatePayload"')
  return ArchiveOfferTemplatePayload_possibleTypes.includes(obj.__typename)
}



var AssignIneligibilityReasonsPayload_possibleTypes = ['AssignIneligibilityReasonsResponse','OperationInfo']
module.exports.isAssignIneligibilityReasonsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAssignIneligibilityReasonsPayload"')
  return AssignIneligibilityReasonsPayload_possibleTypes.includes(obj.__typename)
}



var AssignIneligibilityReasonsResponse_possibleTypes = ['AssignIneligibilityReasonsResponse']
module.exports.isAssignIneligibilityReasonsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAssignIneligibilityReasonsResponse"')
  return AssignIneligibilityReasonsResponse_possibleTypes.includes(obj.__typename)
}



var AuditLogActor_possibleTypes = ['AuditLogActor']
module.exports.isAuditLogActor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogActor"')
  return AuditLogActor_possibleTypes.includes(obj.__typename)
}



var AuditLogIndividualUpdate_possibleTypes = ['AuditLogIndividualUpdate']
module.exports.isAuditLogIndividualUpdate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogIndividualUpdate"')
  return AuditLogIndividualUpdate_possibleTypes.includes(obj.__typename)
}



var AuditLogSummary_possibleTypes = ['AuditLogSummary']
module.exports.isAuditLogSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogSummary"')
  return AuditLogSummary_possibleTypes.includes(obj.__typename)
}



var AuditLogSummaryItem_possibleTypes = ['AuditLogSummaryItem']
module.exports.isAuditLogSummaryItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogSummaryItem"')
  return AuditLogSummaryItem_possibleTypes.includes(obj.__typename)
}



var AuditLogType_possibleTypes = ['AuditLogType']
module.exports.isAuditLogType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogType"')
  return AuditLogType_possibleTypes.includes(obj.__typename)
}



var AuditLogUpdateType_possibleTypes = ['AuditLogUpdateType']
module.exports.isAuditLogUpdateType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuditLogUpdateType"')
  return AuditLogUpdateType_possibleTypes.includes(obj.__typename)
}



var AuthUserInfoType_possibleTypes = ['AuthUserInfoType']
module.exports.isAuthUserInfoType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuthUserInfoType"')
  return AuthUserInfoType_possibleTypes.includes(obj.__typename)
}



var BandLocationSearchType_possibleTypes = ['BandLocationSearchType']
module.exports.isBandLocationSearchType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBandLocationSearchType"')
  return BandLocationSearchType_possibleTypes.includes(obj.__typename)
}



var BandTargetCompType_possibleTypes = ['BandTargetCompType']
module.exports.isBandTargetCompType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBandTargetCompType"')
  return BandTargetCompType_possibleTypes.includes(obj.__typename)
}



var BandType_possibleTypes = ['BandType']
module.exports.isBandType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBandType"')
  return BandType_possibleTypes.includes(obj.__typename)
}



var BandTypeV3_possibleTypes = ['BandTypeV3']
module.exports.isBandTypeV3 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBandTypeV3"')
  return BandTypeV3_possibleTypes.includes(obj.__typename)
}



var BandV2Type_possibleTypes = ['BandV2Type']
module.exports.isBandV2Type = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBandV2Type"')
  return BandV2Type_possibleTypes.includes(obj.__typename)
}



var BenchmarkDataPercentile_possibleTypes = ['BenchmarkDataPercentile']
module.exports.isBenchmarkDataPercentile = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkDataPercentile"')
  return BenchmarkDataPercentile_possibleTypes.includes(obj.__typename)
}



var BenchmarkDistribution_possibleTypes = ['BenchmarkDistribution']
module.exports.isBenchmarkDistribution = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkDistribution"')
  return BenchmarkDistribution_possibleTypes.includes(obj.__typename)
}



var BenchmarkDistributions_possibleTypes = ['BenchmarkDistributions']
module.exports.isBenchmarkDistributions = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkDistributions"')
  return BenchmarkDistributions_possibleTypes.includes(obj.__typename)
}



var BenchmarkFilters_possibleTypes = ['BenchmarkFilters']
module.exports.isBenchmarkFilters = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkFilters"')
  return BenchmarkFilters_possibleTypes.includes(obj.__typename)
}



var BenchmarkImportType_possibleTypes = ['BenchmarkImportType']
module.exports.isBenchmarkImportType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkImportType"')
  return BenchmarkImportType_possibleTypes.includes(obj.__typename)
}



var BenchmarkMetadata_possibleTypes = ['BenchmarkMetadata']
module.exports.isBenchmarkMetadata = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenchmarkMetadata"')
  return BenchmarkMetadata_possibleTypes.includes(obj.__typename)
}



var BenefitPackageType_possibleTypes = ['BenefitPackageType']
module.exports.isBenefitPackageType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBenefitPackageType"')
  return BenefitPackageType_possibleTypes.includes(obj.__typename)
}



var BonusType_possibleTypes = ['BonusType']
module.exports.isBonusType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBonusType"')
  return BonusType_possibleTypes.includes(obj.__typename)
}



var BulkEmployeeCompaRatios_possibleTypes = ['BulkEmployeeCompaRatios']
module.exports.isBulkEmployeeCompaRatios = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkEmployeeCompaRatios"')
  return BulkEmployeeCompaRatios_possibleTypes.includes(obj.__typename)
}



var BulkPublishLevelsResponse_possibleTypes = ['BulkPublishLevelsResponse']
module.exports.isBulkPublishLevelsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkPublishLevelsResponse"')
  return BulkPublishLevelsResponse_possibleTypes.includes(obj.__typename)
}



var BulkUpdateEmployeesResponse_possibleTypes = ['BulkUpdateEmployeesResponse']
module.exports.isBulkUpdateEmployeesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkUpdateEmployeesResponse"')
  return BulkUpdateEmployeesResponse_possibleTypes.includes(obj.__typename)
}



var BulkUpdatePayGeoResponse_possibleTypes = ['BulkUpdatePayGeoResponse']
module.exports.isBulkUpdatePayGeoResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkUpdatePayGeoResponse"')
  return BulkUpdatePayGeoResponse_possibleTypes.includes(obj.__typename)
}



var BulkUpdateScenarioEmployeeApproversPayload_possibleTypes = ['ScenarioEmployeeApproverBulkResponse','OperationInfo']
module.exports.isBulkUpdateScenarioEmployeeApproversPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkUpdateScenarioEmployeeApproversPayload"')
  return BulkUpdateScenarioEmployeeApproversPayload_possibleTypes.includes(obj.__typename)
}



var BulkUpdateTotalRewardsStatementPayload_possibleTypes = ['TotalRewardsStatementInputPartialBulkResponse','OperationInfo']
module.exports.isBulkUpdateTotalRewardsStatementPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBulkUpdateTotalRewardsStatementPayload"')
  return BulkUpdateTotalRewardsStatementPayload_possibleTypes.includes(obj.__typename)
}



var CandidateMessageResponse_possibleTypes = ['CandidateMessageResponse']
module.exports.isCandidateMessageResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCandidateMessageResponse"')
  return CandidateMessageResponse_possibleTypes.includes(obj.__typename)
}



var CandidateOfferAcceptResponse_possibleTypes = ['CandidateOfferAcceptResponse']
module.exports.isCandidateOfferAcceptResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCandidateOfferAcceptResponse"')
  return CandidateOfferAcceptResponse_possibleTypes.includes(obj.__typename)
}



var CandidateType_possibleTypes = ['CandidateType']
module.exports.isCandidateType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCandidateType"')
  return CandidateType_possibleTypes.includes(obj.__typename)
}



var CartaAccessTokenSuccess_possibleTypes = ['CartaAccessTokenSuccess']
module.exports.isCartaAccessTokenSuccess = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCartaAccessTokenSuccess"')
  return CartaAccessTokenSuccess_possibleTypes.includes(obj.__typename)
}



var CartaAuthorizationData_possibleTypes = ['CartaAuthorizationData']
module.exports.isCartaAuthorizationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCartaAuthorizationData"')
  return CartaAuthorizationData_possibleTypes.includes(obj.__typename)
}



var CityType_possibleTypes = ['CityType']
module.exports.isCityType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCityType"')
  return CityType_possibleTypes.includes(obj.__typename)
}



var CityTypeCountryTypeContinentType_possibleTypes = ['CityType','CountryType','ContinentType']
module.exports.isCityTypeCountryTypeContinentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCityTypeCountryTypeContinentType"')
  return CityTypeCountryTypeContinentType_possibleTypes.includes(obj.__typename)
}



var CommissionScenarioType_possibleTypes = ['CommissionScenarioType']
module.exports.isCommissionScenarioType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCommissionScenarioType"')
  return CommissionScenarioType_possibleTypes.includes(obj.__typename)
}



var CommissionStructureType_possibleTypes = ['CommissionStructureType']
module.exports.isCommissionStructureType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCommissionStructureType"')
  return CommissionStructureType_possibleTypes.includes(obj.__typename)
}



var CompChartRangeLimit_possibleTypes = ['CompChartRangeLimit']
module.exports.isCompChartRangeLimit = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompChartRangeLimit"')
  return CompChartRangeLimit_possibleTypes.includes(obj.__typename)
}



var CompPlanConfig_possibleTypes = ['CompPlanConfig']
module.exports.isCompPlanConfig = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompPlanConfig"')
  return CompPlanConfig_possibleTypes.includes(obj.__typename)
}



var CompaRatioResponse_possibleTypes = ['CompaRatioResponse']
module.exports.isCompaRatioResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompaRatioResponse"')
  return CompaRatioResponse_possibleTypes.includes(obj.__typename)
}



var CompensationBonusType_possibleTypes = ['CompensationBonusType']
module.exports.isCompensationBonusType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationBonusType"')
  return CompensationBonusType_possibleTypes.includes(obj.__typename)
}



var CompensationChangeScenarioContributor_possibleTypes = ['CompensationChangeScenarioContributor']
module.exports.isCompensationChangeScenarioContributor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationChangeScenarioContributor"')
  return CompensationChangeScenarioContributor_possibleTypes.includes(obj.__typename)
}



var CompensationChangeScenarioInvitePayload_possibleTypes = ['CompensationChangeScenarioInviteResponse','OperationInfo']
module.exports.isCompensationChangeScenarioInvitePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationChangeScenarioInvitePayload"')
  return CompensationChangeScenarioInvitePayload_possibleTypes.includes(obj.__typename)
}



var CompensationChangeScenarioInviteResponse_possibleTypes = ['CompensationChangeScenarioInviteResponse']
module.exports.isCompensationChangeScenarioInviteResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationChangeScenarioInviteResponse"')
  return CompensationChangeScenarioInviteResponse_possibleTypes.includes(obj.__typename)
}



var CompensationChangeScenarioType_possibleTypes = ['CompensationChangeScenarioType']
module.exports.isCompensationChangeScenarioType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationChangeScenarioType"')
  return CompensationChangeScenarioType_possibleTypes.includes(obj.__typename)
}



var CompensationPackageBonusType_possibleTypes = ['CompensationPackageBonusType']
module.exports.isCompensationPackageBonusType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationPackageBonusType"')
  return CompensationPackageBonusType_possibleTypes.includes(obj.__typename)
}



var CompensationPackageType_possibleTypes = ['CompensationPackageType']
module.exports.isCompensationPackageType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationPackageType"')
  return CompensationPackageType_possibleTypes.includes(obj.__typename)
}



var CompensationPhilosophyType_possibleTypes = ['CompensationPhilosophyType']
module.exports.isCompensationPhilosophyType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationPhilosophyType"')
  return CompensationPhilosophyType_possibleTypes.includes(obj.__typename)
}



var CompensationPlanningColumnPreset_possibleTypes = ['CompensationPlanningColumnPreset']
module.exports.isCompensationPlanningColumnPreset = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationPlanningColumnPreset"')
  return CompensationPlanningColumnPreset_possibleTypes.includes(obj.__typename)
}



var CompensationPlanningCustomColumnData_possibleTypes = ['CompensationPlanningCustomColumnData']
module.exports.isCompensationPlanningCustomColumnData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationPlanningCustomColumnData"')
  return CompensationPlanningCustomColumnData_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioAdjustment_possibleTypes = ['CompensationScenarioAdjustment']
module.exports.isCompensationScenarioAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioAdjustment"')
  return CompensationScenarioAdjustment_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioAdjustmentV8_possibleTypes = ['CompensationScenarioAdjustmentV8']
module.exports.isCompensationScenarioAdjustmentV8 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioAdjustmentV8"')
  return CompensationScenarioAdjustmentV8_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioCohortMember_possibleTypes = ['CompensationScenarioCohortMember']
module.exports.isCompensationScenarioCohortMember = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioCohortMember"')
  return CompensationScenarioCohortMember_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioCohortType_possibleTypes = ['CompensationScenarioCohortType']
module.exports.isCompensationScenarioCohortType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioCohortType"')
  return CompensationScenarioCohortType_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioDataDumpType_possibleTypes = ['CompensationScenarioDataDumpType']
module.exports.isCompensationScenarioDataDumpType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioDataDumpType"')
  return CompensationScenarioDataDumpType_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioDataDumpV2Type_possibleTypes = ['CompensationScenarioDataDumpV2Type']
module.exports.isCompensationScenarioDataDumpV2Type = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioDataDumpV2Type"')
  return CompensationScenarioDataDumpV2Type_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioIndex_possibleTypes = ['CompensationScenarioIndex']
module.exports.isCompensationScenarioIndex = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioIndex"')
  return CompensationScenarioIndex_possibleTypes.includes(obj.__typename)
}



var CompensationScenarioIndexPhoenixRising_possibleTypes = ['CompensationScenarioIndexPhoenixRising']
module.exports.isCompensationScenarioIndexPhoenixRising = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompensationScenarioIndexPhoenixRising"')
  return CompensationScenarioIndexPhoenixRising_possibleTypes.includes(obj.__typename)
}



var CompetingOfferType_possibleTypes = ['CompetingOfferType']
module.exports.isCompetingOfferType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompetingOfferType"')
  return CompetingOfferType_possibleTypes.includes(obj.__typename)
}



var Contact_possibleTypes = ['Contact']
module.exports.isContact = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContact"')
  return Contact_possibleTypes.includes(obj.__typename)
}



var ContinentType_possibleTypes = ['ContinentType']
module.exports.isContinentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContinentType"')
  return ContinentType_possibleTypes.includes(obj.__typename)
}



var CountryType_possibleTypes = ['CountryType']
module.exports.isCountryType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCountryType"')
  return CountryType_possibleTypes.includes(obj.__typename)
}



var CreateAggregateBenchmarkImportPayload_possibleTypes = ['BenchmarkImportType','OperationInfo']
module.exports.isCreateAggregateBenchmarkImportPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateAggregateBenchmarkImportPayload"')
  return CreateAggregateBenchmarkImportPayload_possibleTypes.includes(obj.__typename)
}



var CreateApprovalEmployeePayload_possibleTypes = ['ScenarioEmployeeApproverType','OperationInfo']
module.exports.isCreateApprovalEmployeePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateApprovalEmployeePayload"')
  return CreateApprovalEmployeePayload_possibleTypes.includes(obj.__typename)
}



var CreateBandPayload_possibleTypes = ['BandType','OperationInfo']
module.exports.isCreateBandPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateBandPayload"')
  return CreateBandPayload_possibleTypes.includes(obj.__typename)
}



var CreateBenefitPackagePayload_possibleTypes = ['BenefitPackageType','OperationInfo']
module.exports.isCreateBenefitPackagePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateBenefitPackagePayload"')
  return CreateBenefitPackagePayload_possibleTypes.includes(obj.__typename)
}



var CreateCommissionStructurePayload_possibleTypes = ['CommissionStructureType','OperationInfo']
module.exports.isCreateCommissionStructurePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateCommissionStructurePayload"')
  return CreateCommissionStructurePayload_possibleTypes.includes(obj.__typename)
}



var CreateCompensationPhilosophyPayload_possibleTypes = ['CompensationPhilosophyType','OperationInfo']
module.exports.isCreateCompensationPhilosophyPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateCompensationPhilosophyPayload"')
  return CreateCompensationPhilosophyPayload_possibleTypes.includes(obj.__typename)
}



var CreateDocumentManagementConfigPayload_possibleTypes = ['DocumentManagementConfigType','OperationInfo']
module.exports.isCreateDocumentManagementConfigPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateDocumentManagementConfigPayload"')
  return CreateDocumentManagementConfigPayload_possibleTypes.includes(obj.__typename)
}



var CreateEmployeeCommentPayload_possibleTypes = ['EmployeeCommentType','OperationInfo']
module.exports.isCreateEmployeeCommentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateEmployeeCommentPayload"')
  return CreateEmployeeCommentPayload_possibleTypes.includes(obj.__typename)
}



var CreateFlatfileImportPayload_possibleTypes = ['FlatfileSpaceCreationResponse','OperationInfo']
module.exports.isCreateFlatfileImportPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateFlatfileImportPayload"')
  return CreateFlatfileImportPayload_possibleTypes.includes(obj.__typename)
}



var CreateJobFamilyPayload_possibleTypes = ['JobFamilyType','OperationInfo']
module.exports.isCreateJobFamilyPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateJobFamilyPayload"')
  return CreateJobFamilyPayload_possibleTypes.includes(obj.__typename)
}



var CreateOfferPayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isCreateOfferPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateOfferPayload"')
  return CreateOfferPayload_possibleTypes.includes(obj.__typename)
}



var CreateOfferTemplatePayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isCreateOfferTemplatePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateOfferTemplatePayload"')
  return CreateOfferTemplatePayload_possibleTypes.includes(obj.__typename)
}



var CreateOrganizationImportSourcePayload_possibleTypes = ['OrganizationImportSourceResponse','OperationInfo']
module.exports.isCreateOrganizationImportSourcePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateOrganizationImportSourcePayload"')
  return CreateOrganizationImportSourcePayload_possibleTypes.includes(obj.__typename)
}



var CreateOrganizationPayload_possibleTypes = ['OrganizationType','OperationInfo']
module.exports.isCreateOrganizationPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateOrganizationPayload"')
  return CreateOrganizationPayload_possibleTypes.includes(obj.__typename)
}



var CreatePerformanceRatingPayload_possibleTypes = ['PerformanceRatingType','OperationInfo']
module.exports.isCreatePerformanceRatingPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreatePerformanceRatingPayload"')
  return CreatePerformanceRatingPayload_possibleTypes.includes(obj.__typename)
}



var CreateRolePayload_possibleTypes = ['RoleType','OperationInfo']
module.exports.isCreateRolePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateRolePayload"')
  return CreateRolePayload_possibleTypes.includes(obj.__typename)
}



var CreateScenarioBudgetPayload_possibleTypes = ['ScenarioBudgetType','OperationInfo']
module.exports.isCreateScenarioBudgetPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateScenarioBudgetPayload"')
  return CreateScenarioBudgetPayload_possibleTypes.includes(obj.__typename)
}



var CreateScenarioEmployeeCommentPayload_possibleTypes = ['ScenarioEmployeeCommentType','OperationInfo']
module.exports.isCreateScenarioEmployeeCommentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateScenarioEmployeeCommentPayload"')
  return CreateScenarioEmployeeCommentPayload_possibleTypes.includes(obj.__typename)
}



var CreateUserPayload_possibleTypes = ['ProfileType','OperationInfo']
module.exports.isCreateUserPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateUserPayload"')
  return CreateUserPayload_possibleTypes.includes(obj.__typename)
}



var CustomColumnsType_possibleTypes = ['CustomColumnsType']
module.exports.isCustomColumnsType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnsType"')
  return CustomColumnsType_possibleTypes.includes(obj.__typename)
}



var DataSyncStatusType_possibleTypes = ['DataSyncStatusType']
module.exports.isDataSyncStatusType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataSyncStatusType"')
  return DataSyncStatusType_possibleTypes.includes(obj.__typename)
}



var DataSyncTaskType_possibleTypes = ['DataSyncTaskType']
module.exports.isDataSyncTaskType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataSyncTaskType"')
  return DataSyncTaskType_possibleTypes.includes(obj.__typename)
}



var DataSyncTaskTypeMergeHealthTaskTypeLocationMismatchTaskType_possibleTypes = ['DataSyncTaskType','MergeHealthTaskType','LocationMismatchTaskType']
module.exports.isDataSyncTaskTypeMergeHealthTaskTypeLocationMismatchTaskType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataSyncTaskTypeMergeHealthTaskTypeLocationMismatchTaskType"')
  return DataSyncTaskTypeMergeHealthTaskTypeLocationMismatchTaskType_possibleTypes.includes(obj.__typename)
}



var DataTableColumn_possibleTypes = ['DataTableColumn']
module.exports.isDataTableColumn = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataTableColumn"')
  return DataTableColumn_possibleTypes.includes(obj.__typename)
}



var DataTableColumnPreset_possibleTypes = ['DataTableColumnPreset']
module.exports.isDataTableColumnPreset = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataTableColumnPreset"')
  return DataTableColumnPreset_possibleTypes.includes(obj.__typename)
}



var DeleteBandPayload_possibleTypes = ['BandType','OperationInfo']
module.exports.isDeleteBandPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteBandPayload"')
  return DeleteBandPayload_possibleTypes.includes(obj.__typename)
}



var DeleteBenchmarkImportPayload_possibleTypes = ['BenchmarkImportType','OperationInfo']
module.exports.isDeleteBenchmarkImportPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteBenchmarkImportPayload"')
  return DeleteBenchmarkImportPayload_possibleTypes.includes(obj.__typename)
}



var DeleteCommentPayload_possibleTypes = ['DeleteCommentResponse','OperationInfo']
module.exports.isDeleteCommentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteCommentPayload"')
  return DeleteCommentPayload_possibleTypes.includes(obj.__typename)
}



var DeleteCommentResponse_possibleTypes = ['DeleteCommentResponse']
module.exports.isDeleteCommentResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteCommentResponse"')
  return DeleteCommentResponse_possibleTypes.includes(obj.__typename)
}



var DeleteCommissionStructurePayload_possibleTypes = ['CommissionStructureType','OperationInfo']
module.exports.isDeleteCommissionStructurePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteCommissionStructurePayload"')
  return DeleteCommissionStructurePayload_possibleTypes.includes(obj.__typename)
}



var DeleteCompensationChangeCohortTempPayload_possibleTypes = ['CompensationScenarioCohortType','OperationInfo']
module.exports.isDeleteCompensationChangeCohortTempPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteCompensationChangeCohortTempPayload"')
  return DeleteCompensationChangeCohortTempPayload_possibleTypes.includes(obj.__typename)
}



var DeleteDocumentManagementConfigPayload_possibleTypes = ['DeleteDocumentManagementConfigResponse','OperationInfo']
module.exports.isDeleteDocumentManagementConfigPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteDocumentManagementConfigPayload"')
  return DeleteDocumentManagementConfigPayload_possibleTypes.includes(obj.__typename)
}



var DeleteDocumentManagementConfigResponse_possibleTypes = ['DeleteDocumentManagementConfigResponse']
module.exports.isDeleteDocumentManagementConfigResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteDocumentManagementConfigResponse"')
  return DeleteDocumentManagementConfigResponse_possibleTypes.includes(obj.__typename)
}



var DeleteFlatfileImportPayload_possibleTypes = ['DeleteFlatfileImportResponse','OperationInfo']
module.exports.isDeleteFlatfileImportPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteFlatfileImportPayload"')
  return DeleteFlatfileImportPayload_possibleTypes.includes(obj.__typename)
}



var DeleteFlatfileImportResponse_possibleTypes = ['DeleteFlatfileImportResponse']
module.exports.isDeleteFlatfileImportResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteFlatfileImportResponse"')
  return DeleteFlatfileImportResponse_possibleTypes.includes(obj.__typename)
}



var DeleteJobFamilyPayload_possibleTypes = ['JobFamilyType','OperationInfo']
module.exports.isDeleteJobFamilyPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteJobFamilyPayload"')
  return DeleteJobFamilyPayload_possibleTypes.includes(obj.__typename)
}



var DeleteMessageUserPayload_possibleTypes = ['MessageUserType','OperationInfo']
module.exports.isDeleteMessageUserPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteMessageUserPayload"')
  return DeleteMessageUserPayload_possibleTypes.includes(obj.__typename)
}



var DeleteOfferPayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isDeleteOfferPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteOfferPayload"')
  return DeleteOfferPayload_possibleTypes.includes(obj.__typename)
}



var DeleteOfferTemplatePayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isDeleteOfferTemplatePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteOfferTemplatePayload"')
  return DeleteOfferTemplatePayload_possibleTypes.includes(obj.__typename)
}



var DeleteSecuritiesSuccess_possibleTypes = ['DeleteSecuritiesSuccess']
module.exports.isDeleteSecuritiesSuccess = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteSecuritiesSuccess"')
  return DeleteSecuritiesSuccess_possibleTypes.includes(obj.__typename)
}



var DeleteTotalRewardsStatementPayload_possibleTypes = ['TotalRewardsStatementType','OperationInfo']
module.exports.isDeleteTotalRewardsStatementPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteTotalRewardsStatementPayload"')
  return DeleteTotalRewardsStatementPayload_possibleTypes.includes(obj.__typename)
}



var DeleteUserPayload_possibleTypes = ['DeleteUserResponse','OperationInfo']
module.exports.isDeleteUserPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteUserPayload"')
  return DeleteUserPayload_possibleTypes.includes(obj.__typename)
}



var DeleteUserResponse_possibleTypes = ['DeleteUserResponse']
module.exports.isDeleteUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteUserResponse"')
  return DeleteUserResponse_possibleTypes.includes(obj.__typename)
}



var DisableManagerViewPayload_possibleTypes = ['DisableManagerViewResponse','OperationInfo']
module.exports.isDisableManagerViewPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDisableManagerViewPayload"')
  return DisableManagerViewPayload_possibleTypes.includes(obj.__typename)
}



var DisableManagerViewResponse_possibleTypes = ['DisableManagerViewResponse']
module.exports.isDisableManagerViewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDisableManagerViewResponse"')
  return DisableManagerViewResponse_possibleTypes.includes(obj.__typename)
}



var DismissScenarioEmployeeAlertPayload_possibleTypes = ['ScenarioEmployeeAlertType','OperationInfo']
module.exports.isDismissScenarioEmployeeAlertPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDismissScenarioEmployeeAlertPayload"')
  return DismissScenarioEmployeeAlertPayload_possibleTypes.includes(obj.__typename)
}



var DjangoModelType_possibleTypes = ['DjangoModelType']
module.exports.isDjangoModelType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDjangoModelType"')
  return DjangoModelType_possibleTypes.includes(obj.__typename)
}



var DocumentManagementConfigType_possibleTypes = ['DocumentManagementConfigType']
module.exports.isDocumentManagementConfigType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDocumentManagementConfigType"')
  return DocumentManagementConfigType_possibleTypes.includes(obj.__typename)
}



var DocumentManagementConfigurations_possibleTypes = ['DocumentManagementConfigurations']
module.exports.isDocumentManagementConfigurations = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDocumentManagementConfigurations"')
  return DocumentManagementConfigurations_possibleTypes.includes(obj.__typename)
}



var DuplicateOfferPayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isDuplicateOfferPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDuplicateOfferPayload"')
  return DuplicateOfferPayload_possibleTypes.includes(obj.__typename)
}



var DuplicateOfferTemplatePayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isDuplicateOfferTemplatePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDuplicateOfferTemplatePayload"')
  return DuplicateOfferTemplatePayload_possibleTypes.includes(obj.__typename)
}



var EmailAddress_possibleTypes = ['EmailAddress']
module.exports.isEmailAddress = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmailAddress"')
  return EmailAddress_possibleTypes.includes(obj.__typename)
}



var EmployeeAdditionalCalculatedData_possibleTypes = ['EmployeeAdditionalCalculatedData']
module.exports.isEmployeeAdditionalCalculatedData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeAdditionalCalculatedData"')
  return EmployeeAdditionalCalculatedData_possibleTypes.includes(obj.__typename)
}



var EmployeeCommentType_possibleTypes = ['EmployeeCommentType']
module.exports.isEmployeeCommentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeCommentType"')
  return EmployeeCommentType_possibleTypes.includes(obj.__typename)
}



var EmployeeCommissionsType_possibleTypes = ['EmployeeCommissionsType']
module.exports.isEmployeeCommissionsType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeCommissionsType"')
  return EmployeeCommissionsType_possibleTypes.includes(obj.__typename)
}



var EmployeeCompaRatios_possibleTypes = ['EmployeeCompaRatios']
module.exports.isEmployeeCompaRatios = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeCompaRatios"')
  return EmployeeCompaRatios_possibleTypes.includes(obj.__typename)
}



var EmployeeLocationSearchType_possibleTypes = ['EmployeeLocationSearchType']
module.exports.isEmployeeLocationSearchType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeLocationSearchType"')
  return EmployeeLocationSearchType_possibleTypes.includes(obj.__typename)
}



var EmployeeLocationType_possibleTypes = ['EmployeeLocationType']
module.exports.isEmployeeLocationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeLocationType"')
  return EmployeeLocationType_possibleTypes.includes(obj.__typename)
}



var EmployeeLocationsType_possibleTypes = ['EmployeeLocationsType']
module.exports.isEmployeeLocationsType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeLocationsType"')
  return EmployeeLocationsType_possibleTypes.includes(obj.__typename)
}



var EmployeePlanningActivityLogSummary_possibleTypes = ['EmployeePlanningActivityLogSummary']
module.exports.isEmployeePlanningActivityLogSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeePlanningActivityLogSummary"')
  return EmployeePlanningActivityLogSummary_possibleTypes.includes(obj.__typename)
}



var EmployeePlanningActivityLogSummaryItem_possibleTypes = ['EmployeePlanningActivityLogSummaryItem']
module.exports.isEmployeePlanningActivityLogSummaryItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeePlanningActivityLogSummaryItem"')
  return EmployeePlanningActivityLogSummaryItem_possibleTypes.includes(obj.__typename)
}



var EmployeePublicType_possibleTypes = ['EmployeePublicType']
module.exports.isEmployeePublicType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeePublicType"')
  return EmployeePublicType_possibleTypes.includes(obj.__typename)
}



var EmployeeReportingToManagerType_possibleTypes = ['EmployeeReportingToManagerType']
module.exports.isEmployeeReportingToManagerType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeReportingToManagerType"')
  return EmployeeReportingToManagerType_possibleTypes.includes(obj.__typename)
}



var EmployeeTeamMember_possibleTypes = ['EmployeeTeamMember']
module.exports.isEmployeeTeamMember = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeTeamMember"')
  return EmployeeTeamMember_possibleTypes.includes(obj.__typename)
}



var EmployeeType_possibleTypes = ['EmployeeType']
module.exports.isEmployeeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeType"')
  return EmployeeType_possibleTypes.includes(obj.__typename)
}



var EmployeeVariableCompAndBonusType_possibleTypes = ['EmployeeVariableCompAndBonusType']
module.exports.isEmployeeVariableCompAndBonusType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeeVariableCompAndBonusType"')
  return EmployeeVariableCompAndBonusType_possibleTypes.includes(obj.__typename)
}



var EmployeesAvailableToUserType_possibleTypes = ['EmployeesAvailableToUserType']
module.exports.isEmployeesAvailableToUserType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployeesAvailableToUserType"')
  return EmployeesAvailableToUserType_possibleTypes.includes(obj.__typename)
}



var EmploymentCompScenarioType_possibleTypes = ['EmploymentCompScenarioType']
module.exports.isEmploymentCompScenarioType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmploymentCompScenarioType"')
  return EmploymentCompScenarioType_possibleTypes.includes(obj.__typename)
}



var EmploymentDataclassType_possibleTypes = ['EmploymentDataclassType']
module.exports.isEmploymentDataclassType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmploymentDataclassType"')
  return EmploymentDataclassType_possibleTypes.includes(obj.__typename)
}



var EmploymentRoleInfo_possibleTypes = ['EmploymentRoleInfo']
module.exports.isEmploymentRoleInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmploymentRoleInfo"')
  return EmploymentRoleInfo_possibleTypes.includes(obj.__typename)
}



var EmploymentType_possibleTypes = ['EmploymentType']
module.exports.isEmploymentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmploymentType"')
  return EmploymentType_possibleTypes.includes(obj.__typename)
}



var EnableManagerViewPayload_possibleTypes = ['EnableManagerViewResponse','OperationInfo']
module.exports.isEnableManagerViewPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEnableManagerViewPayload"')
  return EnableManagerViewPayload_possibleTypes.includes(obj.__typename)
}



var EnableManagerViewResponse_possibleTypes = ['EnableManagerViewResponse']
module.exports.isEnableManagerViewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEnableManagerViewResponse"')
  return EnableManagerViewResponse_possibleTypes.includes(obj.__typename)
}



var EquityCardDataType_possibleTypes = ['EquityCardDataType']
module.exports.isEquityCardDataType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEquityCardDataType"')
  return EquityCardDataType_possibleTypes.includes(obj.__typename)
}



var ExchangeRatesType_possibleTypes = ['ExchangeRatesType']
module.exports.isExchangeRatesType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExchangeRatesType"')
  return ExchangeRatesType_possibleTypes.includes(obj.__typename)
}



var FieldMappingType_possibleTypes = ['FieldMappingType']
module.exports.isFieldMappingType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFieldMappingType"')
  return FieldMappingType_possibleTypes.includes(obj.__typename)
}



var FlatfileImportType_possibleTypes = ['FlatfileImportType']
module.exports.isFlatfileImportType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFlatfileImportType"')
  return FlatfileImportType_possibleTypes.includes(obj.__typename)
}



var FlatfileSpaceCreationResponse_possibleTypes = ['FlatfileSpaceCreationResponse']
module.exports.isFlatfileSpaceCreationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFlatfileSpaceCreationResponse"')
  return FlatfileSpaceCreationResponse_possibleTypes.includes(obj.__typename)
}



var GenerateStatementPayload_possibleTypes = ['TotalRewardsStatementGenerationResponse','OperationInfo']
module.exports.isGenerateStatementPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGenerateStatementPayload"')
  return GenerateStatementPayload_possibleTypes.includes(obj.__typename)
}



var GeoLocationType_possibleTypes = ['GeoLocationType']
module.exports.isGeoLocationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGeoLocationType"')
  return GeoLocationType_possibleTypes.includes(obj.__typename)
}



var GrantAccessResponse_possibleTypes = ['GrantAccessResponse']
module.exports.isGrantAccessResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGrantAccessResponse"')
  return GrantAccessResponse_possibleTypes.includes(obj.__typename)
}



var GrantUserAccessPayload_possibleTypes = ['GrantAccessResponse','OperationInfo']
module.exports.isGrantUserAccessPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGrantUserAccessPayload"')
  return GrantUserAccessPayload_possibleTypes.includes(obj.__typename)
}



var GrantUserAccessResponse_possibleTypes = ['GrantUserAccessResponse']
module.exports.isGrantUserAccessResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGrantUserAccessResponse"')
  return GrantUserAccessResponse_possibleTypes.includes(obj.__typename)
}



var HideUnhideEmployeesPayload_possibleTypes = ['updateHiddenAtForEmployeesResponse','OperationInfo']
module.exports.isHideUnhideEmployeesPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHideUnhideEmployeesPayload"')
  return HideUnhideEmployeesPayload_possibleTypes.includes(obj.__typename)
}



var HiringManagerType_possibleTypes = ['HiringManagerType']
module.exports.isHiringManagerType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHiringManagerType"')
  return HiringManagerType_possibleTypes.includes(obj.__typename)
}



var HiringRangeType_possibleTypes = ['HiringRangeType']
module.exports.isHiringRangeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHiringRangeType"')
  return HiringRangeType_possibleTypes.includes(obj.__typename)
}



var ImpersonateUserPayload_possibleTypes = ['ImpersonateUserResponse','OperationInfo']
module.exports.isImpersonateUserPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isImpersonateUserPayload"')
  return ImpersonateUserPayload_possibleTypes.includes(obj.__typename)
}



var ImpersonateUserResponse_possibleTypes = ['ImpersonateUserResponse']
module.exports.isImpersonateUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isImpersonateUserResponse"')
  return ImpersonateUserResponse_possibleTypes.includes(obj.__typename)
}



var IndividualEmployeeCommissionType_possibleTypes = ['IndividualEmployeeCommissionType']
module.exports.isIndividualEmployeeCommissionType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIndividualEmployeeCommissionType"')
  return IndividualEmployeeCommissionType_possibleTypes.includes(obj.__typename)
}



var JobFamilyType_possibleTypes = ['JobFamilyType']
module.exports.isJobFamilyType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJobFamilyType"')
  return JobFamilyType_possibleTypes.includes(obj.__typename)
}



var JobFamilyTypeV3_possibleTypes = ['JobFamilyTypeV3']
module.exports.isJobFamilyTypeV3 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJobFamilyTypeV3"')
  return JobFamilyTypeV3_possibleTypes.includes(obj.__typename)
}



var JobFamilyV2Type_possibleTypes = ['JobFamilyV2Type']
module.exports.isJobFamilyV2Type = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJobFamilyV2Type"')
  return JobFamilyV2Type_possibleTypes.includes(obj.__typename)
}



var JobTrackType_possibleTypes = ['JobTrackType']
module.exports.isJobTrackType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJobTrackType"')
  return JobTrackType_possibleTypes.includes(obj.__typename)
}



var JobType_possibleTypes = ['JobType']
module.exports.isJobType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJobType"')
  return JobType_possibleTypes.includes(obj.__typename)
}



var LastSyncTimeType_possibleTypes = ['LastSyncTimeType']
module.exports.isLastSyncTimeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLastSyncTimeType"')
  return LastSyncTimeType_possibleTypes.includes(obj.__typename)
}



var LevelsMetadataType_possibleTypes = ['LevelsMetadataType']
module.exports.isLevelsMetadataType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLevelsMetadataType"')
  return LevelsMetadataType_possibleTypes.includes(obj.__typename)
}



var LevelsResult_possibleTypes = ['LevelsResult']
module.exports.isLevelsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLevelsResult"')
  return LevelsResult_possibleTypes.includes(obj.__typename)
}



var LevelsSearchType_possibleTypes = ['LevelsSearchType']
module.exports.isLevelsSearchType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLevelsSearchType"')
  return LevelsSearchType_possibleTypes.includes(obj.__typename)
}



var LinkProfileToEmployeePayload_possibleTypes = ['ProfileType','OperationInfo']
module.exports.isLinkProfileToEmployeePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkProfileToEmployeePayload"')
  return LinkProfileToEmployeePayload_possibleTypes.includes(obj.__typename)
}



var LocationMismatchEmployeeType_possibleTypes = ['LocationMismatchEmployeeType']
module.exports.isLocationMismatchEmployeeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLocationMismatchEmployeeType"')
  return LocationMismatchEmployeeType_possibleTypes.includes(obj.__typename)
}



var LocationMismatchTaskType_possibleTypes = ['LocationMismatchTaskType']
module.exports.isLocationMismatchTaskType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLocationMismatchTaskType"')
  return LocationMismatchTaskType_possibleTypes.includes(obj.__typename)
}



var LocationSearchType_possibleTypes = ['LocationSearchType']
module.exports.isLocationSearchType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLocationSearchType"')
  return LocationSearchType_possibleTypes.includes(obj.__typename)
}



var LocationType_possibleTypes = ['LocationType']
module.exports.isLocationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLocationType"')
  return LocationType_possibleTypes.includes(obj.__typename)
}



var ManagerEmployeeTreeResponse_possibleTypes = ['ManagerEmployeeTreeResponse']
module.exports.isManagerEmployeeTreeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManagerEmployeeTreeResponse"')
  return ManagerEmployeeTreeResponse_possibleTypes.includes(obj.__typename)
}



var MarkAllEligiblePayload_possibleTypes = ['MarkAllEligibleResponse','OperationInfo']
module.exports.isMarkAllEligiblePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMarkAllEligiblePayload"')
  return MarkAllEligiblePayload_possibleTypes.includes(obj.__typename)
}



var MarkAllEligibleResponse_possibleTypes = ['MarkAllEligibleResponse']
module.exports.isMarkAllEligibleResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMarkAllEligibleResponse"')
  return MarkAllEligibleResponse_possibleTypes.includes(obj.__typename)
}



var MergeHealthTaskType_possibleTypes = ['MergeHealthTaskType']
module.exports.isMergeHealthTaskType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergeHealthTaskType"')
  return MergeHealthTaskType_possibleTypes.includes(obj.__typename)
}



var MergeOfferType_possibleTypes = ['MergeOfferType']
module.exports.isMergeOfferType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergeOfferType"')
  return MergeOfferType_possibleTypes.includes(obj.__typename)
}



var MeritMatrixType_possibleTypes = ['MeritMatrixType']
module.exports.isMeritMatrixType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMeritMatrixType"')
  return MeritMatrixType_possibleTypes.includes(obj.__typename)
}



var MessageType_possibleTypes = ['MessageType']
module.exports.isMessageType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMessageType"')
  return MessageType_possibleTypes.includes(obj.__typename)
}



var MessageUserType_possibleTypes = ['MessageUserType']
module.exports.isMessageUserType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMessageUserType"')
  return MessageUserType_possibleTypes.includes(obj.__typename)
}



var Mutation_possibleTypes = ['Mutation']
module.exports.isMutation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMutation"')
  return Mutation_possibleTypes.includes(obj.__typename)
}



var NewTotalRewardsDataType_possibleTypes = ['NewTotalRewardsDataType']
module.exports.isNewTotalRewardsDataType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNewTotalRewardsDataType"')
  return NewTotalRewardsDataType_possibleTypes.includes(obj.__typename)
}



var NewTotalRewardsStatementType_possibleTypes = ['NewTotalRewardsStatementType']
module.exports.isNewTotalRewardsStatementType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNewTotalRewardsStatementType"')
  return NewTotalRewardsStatementType_possibleTypes.includes(obj.__typename)
}



var NormalizedTableColumnType_possibleTypes = ['NormalizedTableColumnType']
module.exports.isNormalizedTableColumnType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNormalizedTableColumnType"')
  return NormalizedTableColumnType_possibleTypes.includes(obj.__typename)
}



var NotificationConfigurationType_possibleTypes = ['NotificationConfigurationType']
module.exports.isNotificationConfigurationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotificationConfigurationType"')
  return NotificationConfigurationType_possibleTypes.includes(obj.__typename)
}



var NotificationRecipientType_possibleTypes = ['NotificationRecipientType']
module.exports.isNotificationRecipientType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotificationRecipientType"')
  return NotificationRecipientType_possibleTypes.includes(obj.__typename)
}



var NotifyApprovalEmployeePayload_possibleTypes = ['NotifyApprovalEmployeeResponse','OperationInfo']
module.exports.isNotifyApprovalEmployeePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotifyApprovalEmployeePayload"')
  return NotifyApprovalEmployeePayload_possibleTypes.includes(obj.__typename)
}



var NotifyApprovalEmployeeResponse_possibleTypes = ['NotifyApprovalEmployeeResponse']
module.exports.isNotifyApprovalEmployeeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotifyApprovalEmployeeResponse"')
  return NotifyApprovalEmployeeResponse_possibleTypes.includes(obj.__typename)
}



var NotifyCustomerRequestPayload_possibleTypes = ['NotifyCustomerRequestResponse','OperationInfo']
module.exports.isNotifyCustomerRequestPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotifyCustomerRequestPayload"')
  return NotifyCustomerRequestPayload_possibleTypes.includes(obj.__typename)
}



var NotifyCustomerRequestResponse_possibleTypes = ['NotifyCustomerRequestResponse']
module.exports.isNotifyCustomerRequestResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotifyCustomerRequestResponse"')
  return NotifyCustomerRequestResponse_possibleTypes.includes(obj.__typename)
}



var OfferAnalyticsType_possibleTypes = ['OfferAnalyticsType']
module.exports.isOfferAnalyticsType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferAnalyticsType"')
  return OfferAnalyticsType_possibleTypes.includes(obj.__typename)
}



var OfferInvitePayload_possibleTypes = ['OfferInviteResponse','OperationInfo']
module.exports.isOfferInvitePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferInvitePayload"')
  return OfferInvitePayload_possibleTypes.includes(obj.__typename)
}



var OfferInviteResponse_possibleTypes = ['OfferInviteResponse']
module.exports.isOfferInviteResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferInviteResponse"')
  return OfferInviteResponse_possibleTypes.includes(obj.__typename)
}



var OfferInvitee_possibleTypes = ['OfferInvitee']
module.exports.isOfferInvitee = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferInvitee"')
  return OfferInvitee_possibleTypes.includes(obj.__typename)
}



var OfferLastViewedType_possibleTypes = ['OfferLastViewedType']
module.exports.isOfferLastViewedType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferLastViewedType"')
  return OfferLastViewedType_possibleTypes.includes(obj.__typename)
}



var OfferPackageSelectResponse_possibleTypes = ['OfferPackageSelectResponse']
module.exports.isOfferPackageSelectResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferPackageSelectResponse"')
  return OfferPackageSelectResponse_possibleTypes.includes(obj.__typename)
}



var OfferPageViewType_possibleTypes = ['OfferPageViewType']
module.exports.isOfferPageViewType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferPageViewType"')
  return OfferPageViewType_possibleTypes.includes(obj.__typename)
}



var OfferPublicType_possibleTypes = ['OfferPublicType']
module.exports.isOfferPublicType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferPublicType"')
  return OfferPublicType_possibleTypes.includes(obj.__typename)
}



var OfferRoleMetadata_possibleTypes = ['OfferRoleMetadata']
module.exports.isOfferRoleMetadata = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferRoleMetadata"')
  return OfferRoleMetadata_possibleTypes.includes(obj.__typename)
}



var OfferType_possibleTypes = ['OfferType']
module.exports.isOfferType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfferType"')
  return OfferType_possibleTypes.includes(obj.__typename)
}



var OfficeType_possibleTypes = ['OfficeType']
module.exports.isOfficeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOfficeType"')
  return OfficeType_possibleTypes.includes(obj.__typename)
}



var OperationInfo_possibleTypes = ['OperationInfo']
module.exports.isOperationInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOperationInfo"')
  return OperationInfo_possibleTypes.includes(obj.__typename)
}



var OperationMessage_possibleTypes = ['OperationMessage']
module.exports.isOperationMessage = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOperationMessage"')
  return OperationMessage_possibleTypes.includes(obj.__typename)
}



var OrganizationConfigurationType_possibleTypes = ['OrganizationConfigurationType']
module.exports.isOrganizationConfigurationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationConfigurationType"')
  return OrganizationConfigurationType_possibleTypes.includes(obj.__typename)
}



var OrganizationFeatureFlags_possibleTypes = ['OrganizationFeatureFlags']
module.exports.isOrganizationFeatureFlags = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationFeatureFlags"')
  return OrganizationFeatureFlags_possibleTypes.includes(obj.__typename)
}



var OrganizationIdentificationType_possibleTypes = ['OrganizationIdentificationType']
module.exports.isOrganizationIdentificationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationIdentificationType"')
  return OrganizationIdentificationType_possibleTypes.includes(obj.__typename)
}



var OrganizationImportSourceResponse_possibleTypes = ['OrganizationImportSourceResponse']
module.exports.isOrganizationImportSourceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationImportSourceResponse"')
  return OrganizationImportSourceResponse_possibleTypes.includes(obj.__typename)
}



var OrganizationImportSourceType_possibleTypes = ['OrganizationImportSourceType']
module.exports.isOrganizationImportSourceType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationImportSourceType"')
  return OrganizationImportSourceType_possibleTypes.includes(obj.__typename)
}



var OrganizationPublicType_possibleTypes = ['OrganizationPublicType']
module.exports.isOrganizationPublicType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationPublicType"')
  return OrganizationPublicType_possibleTypes.includes(obj.__typename)
}



var OrganizationType_possibleTypes = ['OrganizationType']
module.exports.isOrganizationType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrganizationType"')
  return OrganizationType_possibleTypes.includes(obj.__typename)
}



var PerformanceDescription_possibleTypes = ['PerformanceDescription']
module.exports.isPerformanceDescription = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPerformanceDescription"')
  return PerformanceDescription_possibleTypes.includes(obj.__typename)
}



var PerformanceRatingSchemeType_possibleTypes = ['PerformanceRatingSchemeType']
module.exports.isPerformanceRatingSchemeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPerformanceRatingSchemeType"')
  return PerformanceRatingSchemeType_possibleTypes.includes(obj.__typename)
}



var PerformanceRatingType_possibleTypes = ['PerformanceRatingType']
module.exports.isPerformanceRatingType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPerformanceRatingType"')
  return PerformanceRatingType_possibleTypes.includes(obj.__typename)
}



var PhoneNumberType_possibleTypes = ['PhoneNumberType']
module.exports.isPhoneNumberType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPhoneNumberType"')
  return PhoneNumberType_possibleTypes.includes(obj.__typename)
}



var Place_possibleTypes = ['CityType','ContinentType','CountryType']
module.exports.isPlace = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPlace"')
  return Place_possibleTypes.includes(obj.__typename)
}



var PlannerBudgetRollup_possibleTypes = ['PlannerBudgetRollup']
module.exports.isPlannerBudgetRollup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPlannerBudgetRollup"')
  return PlannerBudgetRollup_possibleTypes.includes(obj.__typename)
}



var ProfileType_possibleTypes = ['ProfileType']
module.exports.isProfileType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProfileType"')
  return ProfileType_possibleTypes.includes(obj.__typename)
}



var ProfilesFilteredByAccessType_possibleTypes = ['ProfilesFilteredByAccessType']
module.exports.isProfilesFilteredByAccessType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProfilesFilteredByAccessType"')
  return ProfilesFilteredByAccessType_possibleTypes.includes(obj.__typename)
}



var PublishRoleType_possibleTypes = ['PublishRoleType']
module.exports.isPublishRoleType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPublishRoleType"')
  return PublishRoleType_possibleTypes.includes(obj.__typename)
}



var PublishRolesResponse_possibleTypes = ['PublishRolesResponse']
module.exports.isPublishRolesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPublishRolesResponse"')
  return PublishRolesResponse_possibleTypes.includes(obj.__typename)
}



var PublishRolesV2Payload_possibleTypes = ['PublishRolesResponse','OperationInfo']
module.exports.isPublishRolesV2Payload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPublishRolesV2Payload"')
  return PublishRolesV2Payload_possibleTypes.includes(obj.__typename)
}



var Query_possibleTypes = ['Query']
module.exports.isQuery = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"')
  return Query_possibleTypes.includes(obj.__typename)
}



var RecordTotalRewardsAnalyticsPayload_possibleTypes = ['TotalRewardsStatementType','OperationInfo']
module.exports.isRecordTotalRewardsAnalyticsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecordTotalRewardsAnalyticsPayload"')
  return RecordTotalRewardsAnalyticsPayload_possibleTypes.includes(obj.__typename)
}



var RecruiterType_possibleTypes = ['RecruiterType']
module.exports.isRecruiterType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecruiterType"')
  return RecruiterType_possibleTypes.includes(obj.__typename)
}



var RelinkOrganizationImportSourcePayload_possibleTypes = ['OrganizationImportSourceResponse','OperationInfo']
module.exports.isRelinkOrganizationImportSourcePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRelinkOrganizationImportSourcePayload"')
  return RelinkOrganizationImportSourcePayload_possibleTypes.includes(obj.__typename)
}



var RemoteType_possibleTypes = ['RemoteType']
module.exports.isRemoteType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRemoteType"')
  return RemoteType_possibleTypes.includes(obj.__typename)
}



var RevertToOriginalUserPayload_possibleTypes = ['RevertToOriginalUserResponse','OperationInfo']
module.exports.isRevertToOriginalUserPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRevertToOriginalUserPayload"')
  return RevertToOriginalUserPayload_possibleTypes.includes(obj.__typename)
}



var RevertToOriginalUserResponse_possibleTypes = ['RevertToOriginalUserResponse']
module.exports.isRevertToOriginalUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRevertToOriginalUserResponse"')
  return RevertToOriginalUserResponse_possibleTypes.includes(obj.__typename)
}



var RevokeAccessResponse_possibleTypes = ['RevokeAccessResponse']
module.exports.isRevokeAccessResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRevokeAccessResponse"')
  return RevokeAccessResponse_possibleTypes.includes(obj.__typename)
}



var RevokeUserAccessPayload_possibleTypes = ['RevokeAccessResponse','OperationInfo']
module.exports.isRevokeUserAccessPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRevokeUserAccessPayload"')
  return RevokeUserAccessPayload_possibleTypes.includes(obj.__typename)
}



var RevokeUserAccessResponse_possibleTypes = ['RevokeUserAccessResponse']
module.exports.isRevokeUserAccessResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRevokeUserAccessResponse"')
  return RevokeUserAccessResponse_possibleTypes.includes(obj.__typename)
}



var RoleChangeCompaRatio_possibleTypes = ['RoleChangeCompaRatio']
module.exports.isRoleChangeCompaRatio = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRoleChangeCompaRatio"')
  return RoleChangeCompaRatio_possibleTypes.includes(obj.__typename)
}



var RolePublicType_possibleTypes = ['RolePublicType']
module.exports.isRolePublicType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRolePublicType"')
  return RolePublicType_possibleTypes.includes(obj.__typename)
}



var RoleType_possibleTypes = ['RoleType']
module.exports.isRoleType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRoleType"')
  return RoleType_possibleTypes.includes(obj.__typename)
}



var RoleTypeV3_possibleTypes = ['RoleTypeV3']
module.exports.isRoleTypeV3 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRoleTypeV3"')
  return RoleTypeV3_possibleTypes.includes(obj.__typename)
}



var RoleV2Type_possibleTypes = ['RoleV2Type']
module.exports.isRoleV2Type = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRoleV2Type"')
  return RoleV2Type_possibleTypes.includes(obj.__typename)
}



var RolesSummary_possibleTypes = ['RolesSummary']
module.exports.isRolesSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRolesSummary"')
  return RolesSummary_possibleTypes.includes(obj.__typename)
}



var RolesSummaryByJobTrack_possibleTypes = ['RolesSummaryByJobTrack']
module.exports.isRolesSummaryByJobTrack = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRolesSummaryByJobTrack"')
  return RolesSummaryByJobTrack_possibleTypes.includes(obj.__typename)
}



var SalaryCardDataType_possibleTypes = ['SalaryCardDataType']
module.exports.isSalaryCardDataType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalaryCardDataType"')
  return SalaryCardDataType_possibleTypes.includes(obj.__typename)
}



var ScaleDescription_possibleTypes = ['ScaleDescription']
module.exports.isScaleDescription = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScaleDescription"')
  return ScaleDescription_possibleTypes.includes(obj.__typename)
}



var ScenarioApproverType_possibleTypes = ['ScenarioApproverType']
module.exports.isScenarioApproverType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioApproverType"')
  return ScenarioApproverType_possibleTypes.includes(obj.__typename)
}



var ScenarioBudgetCompType_possibleTypes = ['ScenarioBudgetCompType']
module.exports.isScenarioBudgetCompType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioBudgetCompType"')
  return ScenarioBudgetCompType_possibleTypes.includes(obj.__typename)
}



var ScenarioBudgetType_possibleTypes = ['ScenarioBudgetType']
module.exports.isScenarioBudgetType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioBudgetType"')
  return ScenarioBudgetType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeAbridgedCommentType_possibleTypes = ['ScenarioEmployeeAbridgedCommentType']
module.exports.isScenarioEmployeeAbridgedCommentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeAbridgedCommentType"')
  return ScenarioEmployeeAbridgedCommentType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeAlertCompScenarioType_possibleTypes = ['ScenarioEmployeeAlertCompScenarioType']
module.exports.isScenarioEmployeeAlertCompScenarioType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeAlertCompScenarioType"')
  return ScenarioEmployeeAlertCompScenarioType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeAlertType_possibleTypes = ['ScenarioEmployeeAlertType']
module.exports.isScenarioEmployeeAlertType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeAlertType"')
  return ScenarioEmployeeAlertType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeApproverBulkResponse_possibleTypes = ['ScenarioEmployeeApproverBulkResponse']
module.exports.isScenarioEmployeeApproverBulkResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeApproverBulkResponse"')
  return ScenarioEmployeeApproverBulkResponse_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeApproverType_possibleTypes = ['ScenarioEmployeeApproverType']
module.exports.isScenarioEmployeeApproverType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeApproverType"')
  return ScenarioEmployeeApproverType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeApproverTypeV8_possibleTypes = ['ScenarioEmployeeApproverTypeV8']
module.exports.isScenarioEmployeeApproverTypeV8 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeApproverTypeV8"')
  return ScenarioEmployeeApproverTypeV8_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeCommentType_possibleTypes = ['ScenarioEmployeeCommentType']
module.exports.isScenarioEmployeeCommentType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeCommentType"')
  return ScenarioEmployeeCommentType_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeContributor_possibleTypes = ['ScenarioEmployeeContributor']
module.exports.isScenarioEmployeeContributor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeContributor"')
  return ScenarioEmployeeContributor_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeInvitePayload_possibleTypes = ['ScenarioEmployeeInviteResponse','OperationInfo']
module.exports.isScenarioEmployeeInvitePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeInvitePayload"')
  return ScenarioEmployeeInvitePayload_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeInviteResponse_possibleTypes = ['ScenarioEmployeeInviteResponse']
module.exports.isScenarioEmployeeInviteResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeInviteResponse"')
  return ScenarioEmployeeInviteResponse_possibleTypes.includes(obj.__typename)
}



var ScenarioEmployeeType_possibleTypes = ['ScenarioEmployeeType']
module.exports.isScenarioEmployeeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScenarioEmployeeType"')
  return ScenarioEmployeeType_possibleTypes.includes(obj.__typename)
}



var SecurityDataclassType_possibleTypes = ['SecurityDataclassType']
module.exports.isSecurityDataclassType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityDataclassType"')
  return SecurityDataclassType_possibleTypes.includes(obj.__typename)
}



var SecurityIntegrationConfigType_possibleTypes = ['SecurityIntegrationConfigType']
module.exports.isSecurityIntegrationConfigType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityIntegrationConfigType"')
  return SecurityIntegrationConfigType_possibleTypes.includes(obj.__typename)
}



var SecurityType_possibleTypes = ['SecurityType']
module.exports.isSecurityType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityType"')
  return SecurityType_possibleTypes.includes(obj.__typename)
}



var SecurityTypeV8_possibleTypes = ['SecurityTypeV8']
module.exports.isSecurityTypeV8 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityTypeV8"')
  return SecurityTypeV8_possibleTypes.includes(obj.__typename)
}



var SecurityVestingEventDataClassType_possibleTypes = ['SecurityVestingEventDataClassType']
module.exports.isSecurityVestingEventDataClassType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityVestingEventDataClassType"')
  return SecurityVestingEventDataClassType_possibleTypes.includes(obj.__typename)
}



var SecurityVestingEventType_possibleTypes = ['SecurityVestingEventType']
module.exports.isSecurityVestingEventType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSecurityVestingEventType"')
  return SecurityVestingEventType_possibleTypes.includes(obj.__typename)
}



var SendDocumentPayload_possibleTypes = ['SendDocumentResponse','OperationInfo']
module.exports.isSendDocumentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendDocumentPayload"')
  return SendDocumentPayload_possibleTypes.includes(obj.__typename)
}



var SendDocumentResponse_possibleTypes = ['SendDocumentResponse']
module.exports.isSendDocumentResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendDocumentResponse"')
  return SendDocumentResponse_possibleTypes.includes(obj.__typename)
}



var SendWelcomeEmailPayload_possibleTypes = ['SendWelcomeEmailResponse','OperationInfo']
module.exports.isSendWelcomeEmailPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendWelcomeEmailPayload"')
  return SendWelcomeEmailPayload_possibleTypes.includes(obj.__typename)
}



var SendWelcomeEmailResponse_possibleTypes = ['SendWelcomeEmailResponse']
module.exports.isSendWelcomeEmailResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendWelcomeEmailResponse"')
  return SendWelcomeEmailResponse_possibleTypes.includes(obj.__typename)
}



var SetStatementVisibilityPayload_possibleTypes = ['TotalRewardsStatementType','OperationInfo']
module.exports.isSetStatementVisibilityPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSetStatementVisibilityPayload"')
  return SetStatementVisibilityPayload_possibleTypes.includes(obj.__typename)
}



var SimpleEmployeeType_possibleTypes = ['SimpleEmployeeType']
module.exports.isSimpleEmployeeType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimpleEmployeeType"')
  return SimpleEmployeeType_possibleTypes.includes(obj.__typename)
}



var SimpleEmployeeTypeList_possibleTypes = ['SimpleEmployeeTypeList']
module.exports.isSimpleEmployeeTypeList = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimpleEmployeeTypeList"')
  return SimpleEmployeeTypeList_possibleTypes.includes(obj.__typename)
}



var StakeholderDataclassType_possibleTypes = ['StakeholderDataclassType']
module.exports.isStakeholderDataclassType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStakeholderDataclassType"')
  return StakeholderDataclassType_possibleTypes.includes(obj.__typename)
}



var StakeholderType_possibleTypes = ['StakeholderType']
module.exports.isStakeholderType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStakeholderType"')
  return StakeholderType_possibleTypes.includes(obj.__typename)
}



var StorageObjectType_possibleTypes = ['StorageObjectType']
module.exports.isStorageObjectType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStorageObjectType"')
  return StorageObjectType_possibleTypes.includes(obj.__typename)
}



var TaskType_possibleTypes = ['DataSyncTaskType','LocationMismatchTaskType','MergeHealthTaskType']
module.exports.isTaskType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTaskType"')
  return TaskType_possibleTypes.includes(obj.__typename)
}



var TasksResponse_possibleTypes = ['TasksResponse']
module.exports.isTasksResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTasksResponse"')
  return TasksResponse_possibleTypes.includes(obj.__typename)
}



var TotalCompTargetType_possibleTypes = ['TotalCompTargetType']
module.exports.isTotalCompTargetType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalCompTargetType"')
  return TotalCompTargetType_possibleTypes.includes(obj.__typename)
}



var TotalRewardsCustomization_possibleTypes = ['TotalRewardsCustomization']
module.exports.isTotalRewardsCustomization = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsCustomization"')
  return TotalRewardsCustomization_possibleTypes.includes(obj.__typename)
}



var TotalRewardsData_possibleTypes = ['TotalRewardsData']
module.exports.isTotalRewardsData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsData"')
  return TotalRewardsData_possibleTypes.includes(obj.__typename)
}



var TotalRewardsOrgConfig_possibleTypes = ['TotalRewardsOrgConfig']
module.exports.isTotalRewardsOrgConfig = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsOrgConfig"')
  return TotalRewardsOrgConfig_possibleTypes.includes(obj.__typename)
}



var TotalRewardsStatementGenerationResponse_possibleTypes = ['TotalRewardsStatementGenerationResponse']
module.exports.isTotalRewardsStatementGenerationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsStatementGenerationResponse"')
  return TotalRewardsStatementGenerationResponse_possibleTypes.includes(obj.__typename)
}



var TotalRewardsStatementInputPartialBulkResponse_possibleTypes = ['TotalRewardsStatementInputPartialBulkResponse']
module.exports.isTotalRewardsStatementInputPartialBulkResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsStatementInputPartialBulkResponse"')
  return TotalRewardsStatementInputPartialBulkResponse_possibleTypes.includes(obj.__typename)
}



var TotalRewardsStatementType_possibleTypes = ['TotalRewardsStatementType']
module.exports.isTotalRewardsStatementType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTotalRewardsStatementType"')
  return TotalRewardsStatementType_possibleTypes.includes(obj.__typename)
}



var URLType_possibleTypes = ['URLType']
module.exports.isURLType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isURLType"')
  return URLType_possibleTypes.includes(obj.__typename)
}



var UpdateApprovalEmployeePayload_possibleTypes = ['ScenarioEmployeeApproverType','OperationInfo']
module.exports.isUpdateApprovalEmployeePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateApprovalEmployeePayload"')
  return UpdateApprovalEmployeePayload_possibleTypes.includes(obj.__typename)
}



var UpdateBenchmarkImportFiltersPayload_possibleTypes = ['OrganizationType','OperationInfo']
module.exports.isUpdateBenchmarkImportFiltersPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateBenchmarkImportFiltersPayload"')
  return UpdateBenchmarkImportFiltersPayload_possibleTypes.includes(obj.__typename)
}



var UpdateBenefitPackagePayload_possibleTypes = ['BenefitPackageType','OperationInfo']
module.exports.isUpdateBenefitPackagePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateBenefitPackagePayload"')
  return UpdateBenefitPackagePayload_possibleTypes.includes(obj.__typename)
}



var UpdateCommentPayload_possibleTypes = ['ScenarioEmployeeCommentType','OperationInfo']
module.exports.isUpdateCommentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateCommentPayload"')
  return UpdateCommentPayload_possibleTypes.includes(obj.__typename)
}



var UpdateCommissionStructurePayload_possibleTypes = ['CommissionStructureType','OperationInfo']
module.exports.isUpdateCommissionStructurePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateCommissionStructurePayload"')
  return UpdateCommissionStructurePayload_possibleTypes.includes(obj.__typename)
}



var UpdateCompensationPhilosophyPayload_possibleTypes = ['CompensationPhilosophyType','OperationInfo']
module.exports.isUpdateCompensationPhilosophyPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateCompensationPhilosophyPayload"')
  return UpdateCompensationPhilosophyPayload_possibleTypes.includes(obj.__typename)
}



var UpdateDocumentManagementConfigPayload_possibleTypes = ['DocumentManagementConfigType','OperationInfo']
module.exports.isUpdateDocumentManagementConfigPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateDocumentManagementConfigPayload"')
  return UpdateDocumentManagementConfigPayload_possibleTypes.includes(obj.__typename)
}



var UpdateEmployeePayload_possibleTypes = ['EmployeeType','OperationInfo']
module.exports.isUpdateEmployeePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateEmployeePayload"')
  return UpdateEmployeePayload_possibleTypes.includes(obj.__typename)
}



var UpdateEmployeesLocationPayload_possibleTypes = ['BulkUpdatePayGeoResponse','OperationInfo']
module.exports.isUpdateEmployeesLocationPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateEmployeesLocationPayload"')
  return UpdateEmployeesLocationPayload_possibleTypes.includes(obj.__typename)
}



var UpdateEmployeesPayload_possibleTypes = ['BulkUpdateEmployeesResponse','OperationInfo']
module.exports.isUpdateEmployeesPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateEmployeesPayload"')
  return UpdateEmployeesPayload_possibleTypes.includes(obj.__typename)
}



var UpdateFullOfferPayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isUpdateFullOfferPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateFullOfferPayload"')
  return UpdateFullOfferPayload_possibleTypes.includes(obj.__typename)
}



var UpdateJobFamilyPayload_possibleTypes = ['JobFamilyType','OperationInfo']
module.exports.isUpdateJobFamilyPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateJobFamilyPayload"')
  return UpdateJobFamilyPayload_possibleTypes.includes(obj.__typename)
}



var UpdateJobTrackPayload_possibleTypes = ['JobTrackType','OperationInfo']
module.exports.isUpdateJobTrackPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateJobTrackPayload"')
  return UpdateJobTrackPayload_possibleTypes.includes(obj.__typename)
}



var UpdateLevelsPermissionsPayload_possibleTypes = ['UpdateUserPermissionsResponse','OperationInfo']
module.exports.isUpdateLevelsPermissionsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateLevelsPermissionsPayload"')
  return UpdateLevelsPermissionsPayload_possibleTypes.includes(obj.__typename)
}



var UpdateLocationMismatchTaskPayload_possibleTypes = ['UpdateTaskStatus','OperationInfo']
module.exports.isUpdateLocationMismatchTaskPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateLocationMismatchTaskPayload"')
  return UpdateLocationMismatchTaskPayload_possibleTypes.includes(obj.__typename)
}



var UpdateNotificationRecipientPayload_possibleTypes = ['NotificationRecipientType','OperationInfo']
module.exports.isUpdateNotificationRecipientPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateNotificationRecipientPayload"')
  return UpdateNotificationRecipientPayload_possibleTypes.includes(obj.__typename)
}



var UpdateOfferPayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isUpdateOfferPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateOfferPayload"')
  return UpdateOfferPayload_possibleTypes.includes(obj.__typename)
}



var UpdateOfferTemplatePayload_possibleTypes = ['OfferType','OperationInfo']
module.exports.isUpdateOfferTemplatePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateOfferTemplatePayload"')
  return UpdateOfferTemplatePayload_possibleTypes.includes(obj.__typename)
}



var UpdateOrganizationPayload_possibleTypes = ['OrganizationType','OperationInfo']
module.exports.isUpdateOrganizationPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateOrganizationPayload"')
  return UpdateOrganizationPayload_possibleTypes.includes(obj.__typename)
}



var UpdatePerformanceRatingPayload_possibleTypes = ['PerformanceRatingType','OperationInfo']
module.exports.isUpdatePerformanceRatingPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdatePerformanceRatingPayload"')
  return UpdatePerformanceRatingPayload_possibleTypes.includes(obj.__typename)
}



var UpdateProfilePayload_possibleTypes = ['ProfileType','OperationInfo']
module.exports.isUpdateProfilePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateProfilePayload"')
  return UpdateProfilePayload_possibleTypes.includes(obj.__typename)
}



var UpdateRolePayload_possibleTypes = ['RoleType','OperationInfo']
module.exports.isUpdateRolePayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateRolePayload"')
  return UpdateRolePayload_possibleTypes.includes(obj.__typename)
}



var UpdateRolesPayload_possibleTypes = ['UpdateRolesResponse','OperationInfo']
module.exports.isUpdateRolesPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateRolesPayload"')
  return UpdateRolesPayload_possibleTypes.includes(obj.__typename)
}



var UpdateRolesResponse_possibleTypes = ['UpdateRolesResponse']
module.exports.isUpdateRolesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateRolesResponse"')
  return UpdateRolesResponse_possibleTypes.includes(obj.__typename)
}



var UpdateScenarioBudgetPayload_possibleTypes = ['ScenarioBudgetType','OperationInfo']
module.exports.isUpdateScenarioBudgetPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateScenarioBudgetPayload"')
  return UpdateScenarioBudgetPayload_possibleTypes.includes(obj.__typename)
}



var UpdateScenarioEmployeeCommentPayload_possibleTypes = ['ScenarioEmployeeCommentType','OperationInfo']
module.exports.isUpdateScenarioEmployeeCommentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateScenarioEmployeeCommentPayload"')
  return UpdateScenarioEmployeeCommentPayload_possibleTypes.includes(obj.__typename)
}



var UpdateTaskStatus_possibleTypes = ['UpdateTaskStatus']
module.exports.isUpdateTaskStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateTaskStatus"')
  return UpdateTaskStatus_possibleTypes.includes(obj.__typename)
}



var UpdateTaskStatusPayload_possibleTypes = ['UpdateTaskStatus','OperationInfo']
module.exports.isUpdateTaskStatusPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateTaskStatusPayload"')
  return UpdateTaskStatusPayload_possibleTypes.includes(obj.__typename)
}



var UpdateTotalRewardsStatementPayload_possibleTypes = ['TotalRewardsStatementType','OperationInfo']
module.exports.isUpdateTotalRewardsStatementPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateTotalRewardsStatementPayload"')
  return UpdateTotalRewardsStatementPayload_possibleTypes.includes(obj.__typename)
}



var UpdateUserPermissionsPayload_possibleTypes = ['UpdateUserPermissionsResponse','OperationInfo']
module.exports.isUpdateUserPermissionsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateUserPermissionsPayload"')
  return UpdateUserPermissionsPayload_possibleTypes.includes(obj.__typename)
}



var UpdateUserPermissionsResponse_possibleTypes = ['UpdateUserPermissionsResponse']
module.exports.isUpdateUserPermissionsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateUserPermissionsResponse"')
  return UpdateUserPermissionsResponse_possibleTypes.includes(obj.__typename)
}



var UploadOfferTemplateDocumentPayload_possibleTypes = ['StorageObjectType','OperationInfo']
module.exports.isUploadOfferTemplateDocumentPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUploadOfferTemplateDocumentPayload"')
  return UploadOfferTemplateDocumentPayload_possibleTypes.includes(obj.__typename)
}



var UserGroupType_possibleTypes = ['UserGroupType']
module.exports.isUserGroupType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserGroupType"')
  return UserGroupType_possibleTypes.includes(obj.__typename)
}



var UserImpersonationMappingType_possibleTypes = ['UserImpersonationMappingType']
module.exports.isUserImpersonationMappingType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserImpersonationMappingType"')
  return UserImpersonationMappingType_possibleTypes.includes(obj.__typename)
}



var UserObjectPermissionType_possibleTypes = ['UserObjectPermissionType']
module.exports.isUserObjectPermissionType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserObjectPermissionType"')
  return UserObjectPermissionType_possibleTypes.includes(obj.__typename)
}



var UserPermissionType_possibleTypes = ['UserPermissionType']
module.exports.isUserPermissionType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserPermissionType"')
  return UserPermissionType_possibleTypes.includes(obj.__typename)
}



var UserType_possibleTypes = ['UserType']
module.exports.isUserType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserType"')
  return UserType_possibleTypes.includes(obj.__typename)
}



var VariableCommissionJsonType_possibleTypes = ['VariableCommissionJsonType']
module.exports.isVariableCommissionJsonType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVariableCommissionJsonType"')
  return VariableCommissionJsonType_possibleTypes.includes(obj.__typename)
}



var VestingScheduleDataclassType_possibleTypes = ['VestingScheduleDataclassType']
module.exports.isVestingScheduleDataclassType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVestingScheduleDataclassType"')
  return VestingScheduleDataclassType_possibleTypes.includes(obj.__typename)
}



var VestingScheduleType_possibleTypes = ['VestingScheduleType']
module.exports.isVestingScheduleType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVestingScheduleType"')
  return VestingScheduleType_possibleTypes.includes(obj.__typename)
}



var unifiedCompPlanEmployeeTypeV2_possibleTypes = ['unifiedCompPlanEmployeeTypeV2']
module.exports.isunifiedCompPlanEmployeeTypeV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isunifiedCompPlanEmployeeTypeV2"')
  return unifiedCompPlanEmployeeTypeV2_possibleTypes.includes(obj.__typename)
}



var updateHiddenAtForEmployeesResponse_possibleTypes = ['updateHiddenAtForEmployeesResponse']
module.exports.isupdateHiddenAtForEmployeesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isupdateHiddenAtForEmployeesResponse"')
  return updateHiddenAtForEmployeesResponse_possibleTypes.includes(obj.__typename)
}
