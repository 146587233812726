import classnames from 'classnames';
import { ButtonBase, ButtonBaseProps } from 'components/common/button/ButtonBase';
import { exhaustiveMatchGuard } from 'lib/patternMatching';
import { cloneElement, forwardRef, ReactElement } from 'react';

export type ButtonVariantKind = 'primary' | 'secondary' | 'tertiary' | 'clear' | 'dark' | 'bordered' | 'danger' | 'success' | 'toggle';
export type ButtonSizeKind = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type ButtonClasses = {
  root: string;
  leftAdornment: string;
  rightAdornment: string;
  content: string;
};

export type ButtonProps = {
  pingIndicator?: boolean;
  leftAdornment?: ReactElement;
  rightAdornment?: ReactElement;
  variant?: ButtonVariantKind;
  size?: ButtonSizeKind;
  fullWidth?: boolean;
  classes?: Partial<ButtonClasses>;
  name?: string; // clicks will only be tracked if a name is provided
  isLinkButton?: boolean;
} & ButtonBaseProps;

const getPingIndicatorColor = (variant: ButtonVariantKind) => {
  switch (variant) {
    case 'primary':
      return 'bg-admin-primary-500';
    case 'secondary':
      return 'bg-admin-primary-500';
    case 'tertiary':
      return 'bg-slate-100';
    case 'danger':
      return 'bg-admin-danger-50';
    case 'success':
      return 'bg-admin-success-50';
    case 'clear':
      return 'bg-gray-500';
    case 'dark':
      return 'bg-white';
    case 'bordered':
      return 'bg-slate-500';
    default:
      exhaustiveMatchGuard();
  }
};

export const getButtonVariantClasses = (variant: ButtonVariantKind) => {
  switch (variant) {
    case 'primary':
      return `
        border
        border-admin-primary-500
        bg-admin-primary-500
        hover:bg-admin-primary-600 
        focus:bg-admin-primary-600 
        active:bg-admin-primary-700 
        disabled:bg-admin-primary-300
        text-white
        border-admin-primary-500
        disabled:border-admin-primary-300
      `;
    case 'secondary':
      return `
        border
        border-admin-primary-50
        bg-admin-primary-50 
        hover:bg-admin-primary-100 
        focus:bg-admin-primary-100 
        active:bg-admin-primary-200 
        disabled:bg-admin-primary-100
        text-admin-primary-500
        hover:text-admin-primary-600
        focus:text-admin-primary-600
        active:text-admin-primary-600
        disabled:text-admin-primary-300
      `;
    case 'tertiary':
      return `
        border
        border-slate-100
        bg-slate-100
        hover:bg-slate-200
        focus:bg-slate-200
        active:bg-slate-300
        disabled:bg-slate-100
        text-slate-500
        hover:text-slate-600
        focus:text-slate-600
        active:text-slate-700
        disabled:text-slate-300
      `;
    case 'danger':
      return `
          border
          border-admin-danger-50
          bg-admin-danger-50
          hover:bg-admin-danger-100
          focus:bg-admin-danger-100
          active:bg-admin-danger-200
          disabled:bg-admin-danger-100
          text-admin-danger-500
          hover:text-admin-danger-600
          focus:text-admin-danger-600
          active:text-admin-danger-700
          disabled:text-admin-danger-300
        `;
    case 'success':
      return `
          border
          border-admin-success-50
          bg-admin-success-50
          hover:bg-admin-success-100
          focus:bg-admin-success-100
          active:bg-admin-success-200
          disabled:bg-admin-success-100
          text-admin-success-500
          hover:text-admin-success-600
          focus:text-admin-success-600
          active:text-admin-success-700
          disabled:text-admin-success-300
        `;
    case 'clear':
      return `
        border
        border-transparent
        bg-transparent 
        text-gray-500
        hover:text-gray-600
        focus:text-gray-600
        active:text-gray-700
        disabled:text-gray-300
      `;
    case 'dark':
      return `
        border
        border-gray-600
        bg-gray-600 
        hover:bg-gray-700 
        focus:bg-gray-700 
        active:bg-gray-800 
        disabled:bg-gray-300 
        text-white
      `;
    case 'bordered':
      return `
        bg-transparent
        hover:bg-transparent
        focus:bg-transparent
        active:bg-transparent
        disabled:bg-transparent
        text-slate-500
        hover:text-slate-600 
        focus:text-slate-700
        active:text-slate-700
        disabled:text-slate-300 
        border
        border-slate-300
        hover:border-slate-400
        focus:border-slate-500
        active:border-slate-500
        disabled:border-slate-100
      `;
    case 'toggle':
      return `
        bg-transparent
        hover:bg-transparent
        focus:bg-transparent
        active:bg-transparent
        disabled:bg-transparent
        text-slate-500
        hover:text-slate-600 
        disabled:text-slate-300 
        border
        border-slate-300
        hover:border-slate-400
        disabled:border-slate-100
      `;
    default:
      exhaustiveMatchGuard();
  }
};

const getButtonSizeClasses = (size: ButtonSizeKind) => {
  switch (size) {
    case 'xl':
      return 'py-5 px-7 text-2xl';
    case 'lg':
      return 'py-4 px-6 text-xl';
    case 'md':
      return 'py-3.5 px-5 text-base';
    case 'sm':
      return 'py-2.5 px-4 text-sm';
    case 'xs':
      return 'py-2 px-3 text-xs';
    default:
      exhaustiveMatchGuard();
  }
};

const getAdornmentSizeClasses = (size: ButtonSizeKind) => {
  switch (size) {
    case 'xl':
      return 'w-8 h-8 ml-6 first:ml-0';
    case 'lg':
      return 'w-7 h-7 ml-5 first:ml-0';
    case 'md':
      return 'w-6 h-6 ml-4 first:ml-0';
    case 'sm':
      return 'w-5 h-5 ml-2 first:ml-0';
    case 'xs':
      return 'w-4 h-4 ml-2 first:ml-0';
    default:
      exhaustiveMatchGuard();
  }
};

const getMarginSizeClasses = (size: ButtonSizeKind) => {
  switch (size) {
    case 'xl':
      return 'ml-5 first:ml-0';
    case 'lg':
      return 'ml-4 first:ml-0';
    case 'md':
      return 'ml-3 first:ml-0';
    case 'sm':
      return 'ml-2 first:ml-0';
    case 'xs':
      return 'ml-1 first:ml-0';
    default:
      exhaustiveMatchGuard();
  }
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    leftAdornment,
    rightAdornment,
    variant = 'primary',
    size = 'sm',
    fullWidth = false,
    className,
    classes = {},
    pingIndicator,
    onPress,
    name,
    isLinkButton,
    ...rest
  } = props;

  // If the button is a link button (aka, being rendered from within <LinkButton>) we have to handle that separately.
  // In short, we can't use <ButtonBase>. Something with react-aria's `useButton` hook is messing up Next's <Link> component.
  // That means that we lose all the benefit of <Link>, the most important of which is client-side routing
  // I don't love this solution as its a little messy, but it means we can still use this core and the styling of <Button> for <LinkButton>
  if (isLinkButton) {
    return (
      <div
        // ref={ref}
        className={classnames(
          `
      relative
      flex
      cursor-pointer
      items-center
      justify-center
      rounded-md
      font-semibold
      transition-colors
      focus:outline-none
      disabled:cursor-default
    `,
          getButtonVariantClasses(variant),
          getButtonSizeClasses(size),
          fullWidth ? 'w-full' : 'w-fit',
          classes?.root,
          className,
        )}
        {...rest}
      >
        {leftAdornment
          ? cloneElement(leftAdornment, { className: classnames(getAdornmentSizeClasses(size), classes?.leftAdornment) })
          : null}
        {typeof children === 'string' ? (
          <span className={classnames('whitespace-nowrap', getMarginSizeClasses(size), classes?.content)}>{children}</span>
        ) : (
          children
        )}
        {rightAdornment
          ? cloneElement(rightAdornment, {
              className: classnames(getAdornmentSizeClasses(size), getMarginSizeClasses(size), classes.rightAdornment),
            })
          : null}
        {pingIndicator && (
          <>
            <span
              className={`absolute -right-1 -top-1 inline-block h-2.5 w-2.5 animate-ping rounded-full ${getPingIndicatorColor(variant)}`}
            />
            <span className={`${getPingIndicatorColor(variant)} absolute -right-1 -top-1 inline-block h-2.5 w-2.5 rounded-full`} />
          </>
        )}
      </div>
    );
  }
  return (
    <ButtonBase
      ref={ref}
      className={classnames(
        `
        relative
        flex
        cursor-pointer
        items-center
        justify-center
        rounded-md
        font-semibold
        transition-colors
        focus:outline-none
        disabled:cursor-default
      `,
        getButtonVariantClasses(variant),
        getButtonSizeClasses(size),
        fullWidth ? 'w-full' : 'w-fit',
        classes?.root,
        className,
      )}
      onPress={(e) => {
        if (name) {
          // eslint-disable-next-line no-undef
          global.analytics.track('button-pressed', {
            buttonName: name,
          });
        }

        if (onPress) {
          onPress(e);
        }
      }}
      {...rest}
    >
      {leftAdornment ? cloneElement(leftAdornment, { className: classnames(getAdornmentSizeClasses(size), classes?.leftAdornment) }) : null}
      {typeof children === 'string' ? (
        <span className={classnames('whitespace-nowrap', getMarginSizeClasses(size), classes?.content)}>{children}</span>
      ) : (
        children
      )}
      {rightAdornment
        ? cloneElement(rightAdornment, {
            className: classnames(getAdornmentSizeClasses(size), getMarginSizeClasses(size), classes.rightAdornment),
          })
        : null}
      {pingIndicator && (
        <>
          <span
            className={`absolute -right-1 -top-1 inline-block h-2.5 w-2.5 animate-ping rounded-full ${getPingIndicatorColor(variant)}`}
          />
          <span className={`${getPingIndicatorColor(variant)} absolute -right-1 -top-1 inline-block h-2.5 w-2.5 rounded-full`} />
        </>
      )}
    </ButtonBase>
  );
});

Button.displayName = 'Button';

export default Button;
