import { FieldsSelection } from '@genql/runtime';
import { BandV2Type, Client, JobFamilyType, RoleType, RoleV2Type } from 'services/generated';
import {
  BAND_FRAGMENT,
  BENCHMARK_IMPORT_FRAGMENT,
  getShallowJobFamilyListV2Query,
  HIRING_RANGE_FRAGMENT,
  PARTIAL_JOB_FAMILY_FRAGMENT,
  ROLE_FRAGMENT_WITHOUT_DRAFTS,
  ROLE_SCALARS,
  SHALLOW_DRAFT_ROLE_WITH_BANDS_FRAGMENT,
} from 'services/queries';

export const SHALLOW_LEVEL_FRAGMENT = {
  id: true,
  levelNum: true,
  jobTrack: true,
  jobFamily: true,
  isHidden: true,
  title: true,
};

export const SHALLOW_LEVEL_AND_BANDS_FRAGMENT = {
  id: true,
  title: true,
  level: true,
  jobCode: true,
  jobFamily: true,
  description: true,
  jobTrack: true,
  trackId: true,
  trackName: true,
  levelNum: true,
  isHidden: true,
  jobRoleTitles: true,
  benchmarkImport: true,
  benchmarkPercentileBps: true,
  equityBenchmarkPercentileBps: true,
  notes: true,
  bands: BAND_FRAGMENT,
};

export const SHALLOW_TIER_FRAGMENT = {
  uuid: true,
  tierNum: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  lowerBoundEquityValueCents: true,
  upperBoundEquityValueCents: true,
  salaryCurrencyCode: true,
  equityCurrencyCode: true,
  parentLevel: SHALLOW_LEVEL_FRAGMENT,
  description: true,
};

export const SHALLOW_DRAFT_ROLE_FRAGMENT = {
  id: true,
  jobFamily: true,
  title: true,
  level: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  targetAnnualSalaryCents: true,
  lowerBoundEquityValueCents: true,
  upperBoundEquityValueCents: true,
  targetEquityValueCents: true,
  jobTrack: true,
  salaryCurrencyCode: true,
  equityCurrencyCode: true,
  levelNum: true,
  isHidden: true,
  deletedAt: true,
  description: true,
  jobRoleTitles: true,
  jobCode: true,
  tier: true,
  createdAt: true,
  updatedAt: true,
  organization: { id: true },
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
  benchmarkPercentileBps: true,
  equityBenchmarkPercentileBps: true,
  notes: true,
  shouldBeDisplayed: true,
};

export const SHALLOW_ROLE_FRAGMENT = {
  ...ROLE_SCALARS,
  family: PARTIAL_JOB_FAMILY_FRAGMENT,
  draftRole: SHALLOW_DRAFT_ROLE_FRAGMENT,
  organization: { id: true },
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
  bands: BAND_FRAGMENT,
};

export const TIER_NUM_DESCRIPTION_FRAGMENT = {
  tierNum: true,
  description: true,
};

export const SHALLOW_JOB_FAMILY_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
  roles: SHALLOW_ROLE_FRAGMENT,
};

export const SHALLOW_ROLE_WITH_DRAFT_ROLE_BANDS_FRAGMENT = {
  ...SHALLOW_ROLE_FRAGMENT,
  trackId: true,
  bands: BAND_FRAGMENT,
  draftRole: {
    ...SHALLOW_DRAFT_ROLE_WITH_BANDS_FRAGMENT,
    trackId: true,
    bands: BAND_FRAGMENT,
  },
};

export const SHALLOW_JOB_FAMILY_WITH_DRAFTS_AND_BANDS_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
  roles: {
    ...SHALLOW_ROLE_WITH_DRAFT_ROLE_BANDS_FRAGMENT,
    trackId: true,
    trackName: true,
    draftRole: {
      ...SHALLOW_ROLE_WITH_DRAFT_ROLE_BANDS_FRAGMENT.draftRole,
      trackId: true,
      trackName: true,
    },
  },
};

export const ROLE_WITH_HIRING_RANGES = {
  ...ROLE_FRAGMENT_WITHOUT_DRAFTS,
  trackName: true,
  bands: {
    ...ROLE_FRAGMENT_WITHOUT_DRAFTS.bands,
    ...HIRING_RANGE_FRAGMENT,
  },
};

export type ShallowJobFamilyType = FieldsSelection<JobFamilyType, typeof SHALLOW_JOB_FAMILY_FRAGMENT>;
export type ShallowJobFamilyTypeWithDraftsAndBands = FieldsSelection<
  JobFamilyType,
  typeof SHALLOW_JOB_FAMILY_WITH_DRAFTS_AND_BANDS_FRAGMENT
>;
export type ShallowJobFamilyListType = ShallowJobFamilyType[];

export type ShallowBandType = FieldsSelection<BandV2Type, typeof BAND_FRAGMENT>;

export type ShallowRoleType = FieldsSelection<RoleType, typeof SHALLOW_ROLE_FRAGMENT>;
export type ShallowRoleTypeV2 = FieldsSelection<RoleV2Type, typeof SHALLOW_ROLE_FRAGMENT>;

export type RoleWithHiringRangesType = FieldsSelection<RoleV2Type, typeof ROLE_WITH_HIRING_RANGES> & {
  jobTrackLevelTitleFamilyDisplay: string;
};
export type RoleWithHiringRangesListType = RoleWithHiringRangesType[];

export type ShallowRoleListType = ShallowRoleType[];
export type ShallowRoleListTypeV2 = ShallowRoleTypeV2[];
export type ShallowLevelType = FieldsSelection<RoleV2Type, typeof SHALLOW_LEVEL_AND_BANDS_FRAGMENT>;
export type ShallowLevelListType = ShallowLevelType[];

export type ShallowRoleWithDraftType = FieldsSelection<RoleType, typeof SHALLOW_ROLE_WITH_DRAFT_ROLE_BANDS_FRAGMENT>;

export const getRolesList = async (phoenixClient: Client): Promise<ShallowRoleListType> => {
  const { ...rest } = ROLE_FRAGMENT_WITHOUT_DRAFTS;
  const response = await phoenixClient.query({
    __name: 'getRoleListV2',
    rolesV2: rest,
  });
  return response.rolesV2.filter((item): item is ShallowRoleTypeV2 => !item?.deletedAt).map((item) => item as ShallowRoleType);
};

export const getShallowRoleListV2 = async (phoenixClient: Client): Promise<ShallowRoleListType> => {
  const response = await phoenixClient.query({
    __name: 'getShallowRoleListV2',
    rolesV2: SHALLOW_ROLE_FRAGMENT,
  });
  return response.rolesV2.filter((item): item is ShallowRoleTypeV2 => !item?.deletedAt).map((item) => item as ShallowRoleType);
};

export const getShallowJobFamilyListV2 = async (phoenixClient: Client): Promise<ShallowJobFamilyListType> => {
  const response = await phoenixClient.query(getShallowJobFamilyListV2Query);
  return response.jobFamiliesV2.map((item) => item as ShallowJobFamilyType);
};
