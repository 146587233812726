import classnames from 'classnames';
import { createElement, memo, MouseEventHandler, ReactHTML } from 'react';

type HeadingProps = {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
};

const Heading: React.FC<HeadingProps> = ({ variant, children, className }) => {
  return createElement(
    variant,
    {
      className: classnames(
        'font-semibold',
        {
          'text-[39px] leading-[48px]': variant === 'h1',
          'text-[31px] leading-[40px]': variant === 'h2',
          'text-[25px] leading-[32px]': variant === 'h3',
          'text-[20px] leading-[24px]': variant === 'h4',
          'text-[16px] leading-[20px]': variant === 'h5',
          'text-[13px] leading-[16px]': variant === 'h6',
        },
        className,
      ),
    },
    children,
  );
};

type TShirtSizes = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
type Breakpoints = 'mobile' | 'desktop' | 'tablet';
type ResponsiveSize = {
  mobile: TShirtSizes;
  tablet?: TShirtSizes;
  desktop?: TShirtSizes;
};

type ElementTextProps = {
  size?: TShirtSizes | ResponsiveSize;
  element?: keyof ReactHTML;
  className?: string;
  bold?: boolean;
  onClick?: (() => void) | MouseEventHandler;
};

export const elementTextSizes: Record<TShirtSizes, string> = {
  xl: 'text-[26px]',
  lg: 'text-[22px]',
  md: 'text-[18px]',
  sm: 'text-[14px]',
  xs: 'text-[12px]',
};

export const elementLineHeight: Record<TShirtSizes, string> = {
  xl: 'leading-[29px]',
  lg: 'leading-[24px]',
  md: 'leading-[20px]',
  sm: 'leading-[16px]',
  xs: 'leading-[12px]',
};

const toResponseiveElementClass = ({ mobile, desktop, tablet }: ResponsiveSize) => {
  const mobileStyles = `${elementTextSizes[mobile]} ${elementLineHeight[mobile]}`;
  const desktopStyles = desktop ? `desktop:${elementTextSizes[desktop]} desktop:${elementLineHeight[desktop]}` : '';
  const tabletStyles = tablet ? `tablet:${elementTextSizes[tablet]} tablet:${elementLineHeight[tablet]}` : '';
  return classnames(mobileStyles, desktopStyles, tabletStyles);
};

const Element: React.FC<ElementTextProps> = ({ element = 'span', size = 'md', bold = false, children, className, ...rest }) => {
  const sizeClasses = typeof size === 'string' ? toResponseiveElementClass({ mobile: size }) : toResponseiveElementClass(size);

  return createElement(
    element,
    {
      className: classnames(sizeClasses, { 'font-semibold': bold }, className),
      ...rest,
    },
    children,
  );
};

type ParagraphTextProps = {
  size?: TShirtSizes | ResponsiveSize;
  element?: 'p' | 'span';
  bold?: boolean;
  className?: string;
};

const paragraphTextSizes: Record<TShirtSizes, string> = {
  xl: 'text-[26px]',
  lg: 'text-[22px]',
  md: 'text-[20px]',
  sm: 'text-[14px]',
  xs: 'text-[12px]',
};

const paragraphLineHeight: Record<TShirtSizes, string> = {
  xl: 'leading-[44px]',
  lg: 'leading-[40px]',
  md: 'leading-[36px]',
  sm: 'leading-[32px]',
  xs: 'leading-[24px]',
};

const toResponseiveParagraphClass = ({ mobile, desktop, tablet }: ResponsiveSize) => {
  const mobileStyles = `${paragraphTextSizes[mobile]} ${paragraphLineHeight[mobile]}`;
  const desktopStyles = desktop ? `desktop:${paragraphTextSizes[desktop]} desktop:${paragraphLineHeight[desktop]}` : '';
  const tabletStyles = tablet ? `tablet:${paragraphTextSizes[tablet]} tablet:${paragraphLineHeight[tablet]}` : '';
  return classnames(mobileStyles, tabletStyles, desktopStyles);
};

const Paragraph: React.FC<ParagraphTextProps> = memo(({ element = 'p', size = 'md', bold = false, className, children, ...rest }) => {
  const sizeClasses = typeof size === 'string' ? toResponseiveParagraphClass({ mobile: size }) : toResponseiveParagraphClass(size);

  return createElement(
    element,
    {
      className: classnames(
        sizeClasses,
        {
          'font-semibold': bold,
        },
        className,
      ),
    },
    children,
  );
});

const Typography = {
  Heading,
  Element,
  Paragraph,
};

export default Typography;
