import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface IPreferences {
  adminSideNavOpen: boolean;
}
interface IPreferencesContext {
  preferences: IPreferences;
  savePreferences: (preferences: IPreferences) => void;
}

// TODO: namespace by environment so it's easier to test
// without screwing up your real preferences
const storageKey = 'cp-preferences';

const loadPreferences = (): IPreferences => {
  const prefString = localStorage.getItem(storageKey);
  if (prefString) {
    return JSON.parse(prefString);
  }
  return {
    adminSideNavOpen: true,
  };
};

const preferencesContextDefaultValues: IPreferencesContext = {
  preferences: {
    adminSideNavOpen: true,
  },
  savePreferences: () => {},
};

export const PreferencesContext = createContext<IPreferencesContext>(preferencesContextDefaultValues);

// note that the only usecase for this was storing the admin sidenav expanded state
// and we opted for a different solution, so this is unused
export const PreferencesContextProvider = ({ children }: { children: ReactNode }) => {
  const [preferences, setPreferences] = useState<IPreferences>(preferencesContextDefaultValues.preferences);

  useEffect(() => {
    const preferencesFromLocalStorage = loadPreferences();
    setPreferences(preferencesFromLocalStorage);
  }, []);

  const savePreferences = (preferences: IPreferences) => {
    localStorage.setItem(storageKey, JSON.stringify(preferences));
    setPreferences(preferences);
  };

  const context: IPreferencesContext = {
    preferences,
    savePreferences,
  };
  return <PreferencesContext.Provider value={context}>{children}</PreferencesContext.Provider>;
};

export function usePreferencesContext() {
  return useContext(PreferencesContext);
}
