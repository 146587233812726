import { FieldsSelection } from '@genql/runtime';
import { EmployeeFilter, EmployeeTypeRequest, RoleType } from 'services/generated';

export const JOB_TRACK_FRAGMENT = {
  id: true,
  name: true,
  abbreviation: true,
  rank: true,
};

export const BENCHMARK_IMPORT_FRAGMENT = {
  id: true,
  name: true,
  createdAt: true,
  importedAt: true,
  dataSource: true,
  jobTitle: true,
  jobFamily: true,
  jobLevel: true,
  isAggregate: true,
  benchmarkDistributions: {
    distributions: {
      type: true,
      distribution: {
        percentile: true,
        value: true,
      },
    },
  },
  aggregateReferences: {
    benchmarkImport: {
      id: true,
      name: true,
      importedAt: true,
      createdAt: true,
      dataSource: true,
      jobFamily: true,
      jobLevel: true,
      filters: {
        location: true,
      },
    },
    weight: true,
  },
  currencyCode: true,
  filters: {
    location: true,
    capitalRaised: true,
    revenue: true,
    numEmployees: true,
    valuation: true,
  },
  importSource: {
    id: true,
  },
} as const;

export const PROFILE_FRAGMENT = {
  id: true,
  email: true,
  fullName: true,
  group: true,
  imagePath: true,
  title: true,
  employee: {
    id: true,
    role: {
      id: true,
      title: true,
      jobFamily: true,
    },
  },
  permissions: {
    name: true,
    codename: true,
  },
  impersonatableUserIds: {
    targetUserProfile: {
      id: true,
      email: true,
      fullName: true,
    },
    grantedAt: true,
  },
  hasAccessToApplication: true,
  organization: {
    identifier: true,
  },
};

export const PROFILE_LIST_FRAGMENT = {
  ...PROFILE_FRAGMENT,
  objectPermissions: {
    name: true,
    codename: true,
    contentType: true,
    objectId: true,
  },
};

export const DATA_TABLE_COLUMN_PRESET_COLUMN_FRAGMENT = {
  key: true,
  label: true,
  type: true,
  behavior: true,
  columnDefinition: true,
  __typename: true,
};

export const COMPENSATION_PLANNING_DATA_TABLE_COLUMN_PRESET_FRAGMENT = {
  customColumns: DATA_TABLE_COLUMN_PRESET_COLUMN_FRAGMENT,
  defaultColumns: DATA_TABLE_COLUMN_PRESET_COLUMN_FRAGMENT,
};

export const DATA_TABLE_COLUMN_PRESET_FRAGMENT = {
  compensationPlanning: COMPENSATION_PLANNING_DATA_TABLE_COLUMN_PRESET_FRAGMENT,
};

export const ORG_CONFIG_FRAGMENT = {
  activeContractDate: true,
  churnedDate: true,
  disabledDate: true,
  enableOffers: true,
  enableTeamView: true,
  enableLevels: true,
  enableBenchmarking: true,
  enableCompensationPlanning: true,
  enableTotalRewards: true,
  enableSalaryRangeTransparency: true,
  enableEquityRangeTransparency: true,
  enableTcRangeTransparency: true,
  enableDocumentManager: true,
  enableJobFamilySharing: true,
  enableManagerView: true,
  __typename: true,
};

export const SHALLOW_ORGANIZATION_FRAGMENT = {
  id: true,
  uuid: true,
  name: true,
  valuationCents: true,
  sharesOutstanding: true,
  identifier: true,
  customization: true,
  strikePriceCents: true,
  preferredSharePriceCents: true,
  hasMergeAccountToken: true,
  hasMergeHrisAccountToken: true,
  hasDocusignToken: true,
  transparency: true,
  defaultVestingSchedule: true,
  defaultEquityVestingSchedule: {
    uuid: true,
    description: true,
    events: true,
  },
  onboarding: true,
  welcomeImagePath: true,
  overviewImagePath: true,
  valuesImagePath: true,
  customersImagePath: true,
  exerciseWindow: true,
  compensationPhilosophy: true,
  defaultCurrencyCode: true,
  flexibility: true,
  stock: true,
  totalRewardsCustomization: {
    adminMessage: true,
    showJobLadder: true,
    showEmployeeInBand: true,
    enableCompModeling: true,
    headerColor: true,
    liteShowPerformanceRatings: true,
    liteShowYtdCompChange: true,
  },
  compChartRangeLimit: {
    startYear: true,
    endYear: true,
  },
  dataTableColumnPreset: DATA_TABLE_COLUMN_PRESET_FRAGMENT, // transformation of a JSON object (DataClassField) on the org model
  config: ORG_CONFIG_FRAGMENT,
  jobTracks: JOB_TRACK_FRAGMENT,
  // This list ^ should only include scalar fields or fields that do not require any additional queries
};

export const BAND_WITHOUT_LOCATIONS_FRAGMENT = {
  id: true,
  bandName: true,
  bandType: true,
  minInCents: true,
  targetInCents: true,
  maxInCents: true,
  currencyCode: true,
  description: true,
};

// TODO: [REVIEW PERFORMANCE] check locs doesn't introduce N+1
export const BAND_FRAGMENT = {
  id: true,
  bandName: true,
  bandType: true,
  minInCents: true,
  targetInCents: true,
  maxInCents: true,
  currencyCode: true,
  description: true,
  locations: {
    __typename: true,
    id: true,
    city: {
      id: true,
      name: true,
    },
    country: {
      id: true,
      name: true,
    },
    continent: {
      id: true,
      name: true,
    },
  },
  targetPercentage: true,
};

export const SHALLOW_COMPENSATION_CHANGE_SCENARIO_FRAGMENT = {
  uuid: true,
  createdAt: true,
  updatedAt: true,
  name: true,
  notes: true,
  state: true,
  financialYear: true,
  salaryBudgetCents: true,
  equityBudgetShares: true,
  deadline: true,
  implementedAt: true,
  archivedAt: true,
  effectiveDate: true,
  launchedAt: true,
  cycleClosedAt: true,
  adjustmentsImportedAt: true,
  shareWithManagers: true,

  approver: {
    id: true,
    approvalType: true,
    firstViewComplete: true,
  },
  __typename: true,
};
export const ROLE_SCALARS = {
  id: true,
  uuid: true,
  jobFamily: true,
  title: true,
  level: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  targetAnnualSalaryCents: true,
  lowerBoundEquityValueCents: true,
  upperBoundEquityValueCents: true,
  targetEquityValueCents: true,
  jobTrack: true,
  salaryCurrencyCode: true,
  equityCurrencyCode: true,
  levelNum: true,
  isHidden: true,
  deletedAt: true,
  description: true,
  jobRoleTitles: true,
  jobCode: true,
  tier: true,
  createdAt: true,
  updatedAt: true,
  benchmarkPercentileBps: true,
  equityBenchmarkPercentileBps: true,
  notes: true,
  shouldBeDisplayed: true,
};

export const PARTIAL_JOB_FAMILY_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
};

export const HIRING_RANGE_FRAGMENT = {
  hiringRange: {
    hiringMinInCents: true,
    hiringTargetInCents: true,
    hiringHighInCents: true,
    hiringMaxInCents: true,
    currencyCode: true,
  },
};

export const ROLE_FRAGMENT_WITHOUT_DRAFTS = {
  ...ROLE_SCALARS,
  family: PARTIAL_JOB_FAMILY_FRAGMENT,
  organization: { id: true },
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
  bands: BAND_FRAGMENT,
};

export const ROLE_FRAGMENT_WITHOUT_DRAFTS_AND_BANDS = {
  ...ROLE_SCALARS,
  family: PARTIAL_JOB_FAMILY_FRAGMENT,
  organization: { id: true },
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
};

export const JOB_FAMILY_WITHOUT_DRAFTS_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
  roles: ROLE_FRAGMENT_WITHOUT_DRAFTS,
};

export const JOB_FAMILY_WITHOUT_DRAFTS_AND_BANDS_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
  roles: ROLE_FRAGMENT_WITHOUT_DRAFTS_AND_BANDS,
};

export const EMPLOYEE_COMMISSIONS = {
  __typename: true,
  payPeriod: true,
  payRateInCents: true,
  currencyCode: true,
  effectiveDate: true,
  endDate: true,
  commissionType: true,
  paymentType: true,
};

export const EMPLOYEE_VARIABLE_COMMISSIONS = {
  __typename: true,
  allCommissions: EMPLOYEE_COMMISSIONS,
  activeCommissions: EMPLOYEE_COMMISSIONS,
};

export const DRAFT_ROLE_SCALARS = {
  id: true,
  jobFamily: true,
  title: true,
  level: true,
  createdAt: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  targetAnnualSalaryCents: true,
  lowerBoundEquityValueCents: true,
  upperBoundEquityValueCents: true,
  targetEquityValueCents: true,
  jobTrack: true,
  salaryCurrencyCode: true,
  equityCurrencyCode: true,
  levelNum: true,
  isHidden: true,
  deletedAt: true,
  description: true,
  jobRoleTitles: true,
  jobCode: true,
  tier: true,
  updatedAt: true,
  benchmarkPercentileBps: true,
  equityBenchmarkPercentileBps: true,
  notes: true,
  shouldBeDisplayed: true,
};

export const SHALLOW_DRAFT_ROLE_FRAGMENT = {
  ...DRAFT_ROLE_SCALARS,
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
};

export const SHALLOW_DRAFT_ROLE_WITH_BANDS_FRAGMENT = {
  ...SHALLOW_DRAFT_ROLE_FRAGMENT,
  bands: BAND_FRAGMENT,
};

export const SHALLOW_JOB_FAMILY_FRAGMENT = {
  uuid: true,
  name: true,
  description: true,
  targetPercentileBps: true,
};

export const GEOLOCATION_FRAGMENT = {
  __typename: true,
  id: true,
  city: {
    id: true,
    name: true,
  },
  country: {
    id: true,
    name: true,
  },
  continent: {
    id: true,
    name: true,
  },
};

export const SHALLOW_ROLE_FRAGMENT = {
  id: true,
  jobFamily: true,
  title: true,
  level: true,
  createdAt: true,
  lowerBoundAnnualSalaryCents: true,
  upperBoundAnnualSalaryCents: true,
  targetAnnualSalaryCents: true,
  lowerBoundEquityValueCents: true,
  upperBoundEquityValueCents: true,
  targetEquityValueCents: true,
  jobTrack: true,
  salaryCurrencyCode: true,
  equityCurrencyCode: true,
  levelNum: true,
  isHidden: true,
  deletedAt: true,
  description: true,
  family: SHALLOW_JOB_FAMILY_FRAGMENT,
  jobRoleTitles: true,
  jobCode: true,
  tier: true,
  updatedAt: true,
  notes: true,
  draftRole: SHALLOW_DRAFT_ROLE_FRAGMENT,
  benchmarkImport: BENCHMARK_IMPORT_FRAGMENT,
  benchmarkPercentileBps: true,
  equityBenchmarkPercentileBps: true,
  bands: BAND_FRAGMENT,
  shouldBeDisplayed: true,
};

export type ShallowRoleType = FieldsSelection<RoleType, typeof SHALLOW_ROLE_FRAGMENT>;

export const EMPLOYEE_ID_FRAGMENT = {
  id: true,
};

export const EMPLOYEE_FRAGMENT_TEAM_PAGE = {
  id: true,
  displayFullName: true,
  workEmail: true,
  hiddenAt: true,
  hrisEmployeeNumber: true,
  payLocation: true,
  totalUnitsGranted: true,
  totalUnvestedUnits: true,
  totalVestedUnits: true,
  totalSoldDonatedTransferredUnits: true,
  employmentSet: {
    payRateInCents: true,
    jobTitle: true,
    effectiveDate: true,
    payCurrency: true,
    payPeriod: true,
    annualizedPayRate: true,
    mergeId: true,
    __typename: true,
  },
  currentEmployment: {
    jobTitle: true,
    annualizedPayRate: true,
    payRateInCents: true,
    payCurrency: true,
    effectiveDate: true,
  },
  manager: {
    id: true,
    displayFullName: true,
  },
  startDate: true,
  stakeholder: {
    uuid: true,
    securitySet: {
      uuid: true,
      issuedQuantity: true,
      certificateIdentifier: true,
      shareClass: true,
      awardType: true,
      issueDate: true,
      sharePriceCents: true,
      hasEarlyExercise: true,
      isExercisable: true,
      isVestable: true,
      exercisePriceCents: true,
      vestingStartDate: true,
      acceptanceStatus: true,
      status: true,
      securityType: true,
      vestingSchedule: {
        uuid: true,
        name: true,
        description: true,
        vestingDuration: true,
        vestingFrequency: true,
        hasCliff: true,
        events: true,
        __typename: true,
      },
      vestingEvents: {
        vestDate: true,
        issuedQuantity: true,
        canceledQuantity: true,
        soldQuantity: true,
        donatedTransferredQuantity: true,
        __typename: true,
      },
      createdAt: true,
      __typename: true,
    },
  },
  workLocation: { name: true },
  role: {
    jobTrack: true,
    levelNum: true,
    title: true,
    jobFamily: true,
    lowerBoundAnnualSalaryCents: true,
    upperBoundAnnualSalaryCents: true,
    salaryCurrencyCode: true,
    id: true,
  },
  tier: true,
  performanceRatings: {
    performanceRatingScheme: { scaleDescription: { mappings: { rating: true, description: true } } },
    id: true,
    rating: true,
    effectiveDate: true,
  },
  importSource: { processedAt: true },
  totalRewardsStatements: {
    id: true,
    createdAt: true,
    isVisible: true,
  },
  employeeCommissions: EMPLOYEE_VARIABLE_COMMISSIONS,
  totalCompTarget: {
    target: true,
    currencyCode: true,
  },
  equityTarget: {
    target: true,
    currencyCode: true,
  },
  additionalCalculatedData: {
    equityCurrentUnits: true,
    equityCurrentValue: true,
    equityBandCurrencyCode: true,
  },
  employeeVariableCompAndBonuses: {
    variableCompAndBonusCurrentYearAnnualizedRate: true,
    variableCompAndBonusCurrencyCode: true,
    lastVariableCompBonusAmountCents: true,
    lastVariableCompBonusCurrency: true,
    lastVariableCompBonusEffectiveDate: true,
  },
  ceoMinusOneEmployeeName: true,
  ceoMinusTwoEmployeeName: true,
  lastRoleChangeDate: true,
};

export const TOTAL_REWARDS_EMPLOYEE_FRAGMENT = {
  id: true,
  displayFullName: true,
  hiddenAt: true,
  manager: {
    displayFullName: true,
  },
  role: {
    jobFamily: true,
  },
  totalRewardsStatements: {
    id: true,
    createdAt: true,
    isVisible: true,
    employeeLastViewedAt: true,
    averageSessionLengthSeconds: true,
    totalEmployeeViewCount: true,
  },
};

export const TEAM_PAGE_FRAGMENT = {
  ...EMPLOYEE_FRAGMENT_TEAM_PAGE,
  location: GEOLOCATION_FRAGMENT,
};

export const getMeQuery = {
  __name: 'getMe',
  me: {
    ...PROFILE_FRAGMENT,
    impersonatableUserIds: {
      targetUserProfile: {
        id: true,
        email: true,
        fullName: true,
        employee: {
          id: true,
          role: {
            id: true,
            title: true,
            jobFamily: true,
          },
        },
      },
      grantedAt: true,
    },
    hasAccessToApplication: true,
  },
};

export const getOriginalUserQuery = {
  __name: 'getOriginalUser',
  originalUser: PROFILE_FRAGMENT,
};

export const shallowOrganizationQuery = {
  __name: 'shallowOrganizationQuery',
  organization: SHALLOW_ORGANIZATION_FRAGMENT,
};

export const getCompensationChangeScenarioListQuery = {
  __name: 'getCompensationChangeScenarioList',
  compensationScenarios: SHALLOW_COMPENSATION_CHANGE_SCENARIO_FRAGMENT,
};

export const getShallowJobFamilyListQuery = {
  __name: 'getShallowJobFamilyList',
  jobFamilies: JOB_FAMILY_WITHOUT_DRAFTS_FRAGMENT,
};

export const getShallowJobFamilyListV2Query = {
  __name: 'getShallowJobFamilyListV2',
  jobFamiliesV2: JOB_FAMILY_WITHOUT_DRAFTS_FRAGMENT,
};

export const getOrganizationBenchmarkingImportsQuery = {
  __name: 'getOrganizationBenchmarkingImports',
  organization: {
    benchmarkingImports: BENCHMARK_IMPORT_FRAGMENT,
  },
};

export const teamPageEmployeesQuery: {
  __name: string;
  // types cannot be inferred correctly in this case
  employees: [{ filters: EmployeeFilter }, EmployeeTypeRequest];
} = {
  __name: 'teamPageEmployees',
  employees: [{ filters: { mustBeActive: true } }, TEAM_PAGE_FRAGMENT],
};

export const teamPageHiddenEmployeesQuery: {
  __name: string;
  // types cannot be inferred correctly in this case
  hiddenEmployees: EmployeeTypeRequest;
} = {
  __name: 'teamPageHiddenEmployees',
  hiddenEmployees: EMPLOYEE_FRAGMENT_TEAM_PAGE,
};

// TODO: add assertions for all the queries
export const apiCheckedQueries = [
  {
    query: getMeQuery,
  },
  {
    query: shallowOrganizationQuery,
    assertion: {
      source: 'JSON_BODY',
      order: 0,
      comparison: 'EQUALS',
      property: '$.data.organization.uuid',
      target: '2ccf617c-c4a7-4282-afba-8b608ee7fd20',
      regex: null,
    },
  },
  {
    query: getCompensationChangeScenarioListQuery,
  },
  {
    query: getShallowJobFamilyListQuery,
  },
  {
    query: getOrganizationBenchmarkingImportsQuery,
  },
];

export const apiV2CheckedQueries = [teamPageEmployeesQuery];
