import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { forwardRef, RefObject } from 'react';

export type ButtonBaseProps = AriaButtonProps<'button'> & {
  className?: string;
  title?: string;
  form?: string;
};

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(({ children, className, title, form, ...rest }, ref) => {
  const { buttonProps } = useButton(rest, ref as RefObject<HTMLButtonElement>);

  return (
    <button className={className} ref={ref} title={title} form={form} {...buttonProps}>
      {children}
    </button>
  );
});

ButtonBase.displayName = 'ButtonBase';
