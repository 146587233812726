import { FieldsSelection } from '@genql/runtime';
import { Client, ProfileGroupType, ProfileType as GeneratedProfileType } from 'services/generated';
import { getMeQuery, getOriginalUserQuery, PROFILE_LIST_FRAGMENT } from 'services/queries';
import { ArrayElement } from 'services/types';

export const getBasicProfileList = async (client: Client) => {
  const response = await client.query({
    __name: 'getBasicProfileList',
    profiles: [
      {
        filters: {
          // these are the people who can log in
          mustHaveAuthUser: true,
          // these people who can log in don't necessarily have to be 'employees' like consultants or us
          mustHaveEmployee: false,
        },
      },
      {
        id: true,
        email: true,
        fullName: true,
        group: true,
      },
    ],
  });
  return response.profiles;
};

// fetches profile of current user
export const getCurrentProfile = async (client: Client) => {
  const profile = await client.query(getMeQuery);

  return profile?.me;
};

// fetches profile of impersonator
export const getOriginalProfile = async (client: Client) => {
  const profile = await client.query(getOriginalUserQuery);

  return profile?.originalUser;
};

export type ProfileListType = FieldsSelection<GeneratedProfileType, typeof PROFILE_LIST_FRAGMENT>[];
export type ProfileType = ArrayElement<ProfileListType>;
export type CurrentProfileType = Awaited<ReturnType<typeof getCurrentProfile>>;
export type OrgUserProfileType = {
  id: number;
  fullName?: string;
  email: string;
  title?: string;
  employee?: {
    id: string;
    role?: {
      id: number;
      title?: string;
      jobFamily?: string;
    };
  };
  group?: ProfileGroupType;
  objectPermissions?: {
    name: string;
    codename: string;
    contentType: string;
    objectId: string | number;
  }[];
  hasManagerViewEnabled?: boolean;
  employees?: {
    id: number;
    displayFullName?: string;
    isShared: boolean;
  }[];
};

export type OrgUserProfileListType = OrgUserProfileType[];

export type ManagerViewDataType = {
  profileId: number;
  hasManagerViewEnabled: boolean;
  lastActiveAt?: number;
  lastSignInAt?: number;
  manager: {
    id: number;
    displayFullName: string;
    workEmail: string;
    role: {
      jobFamily: string;
      title: string;
    };
    currentJobTitle: string;
  };
  employees: {
    id: number;
    displayFullName?: string;
    isShared: boolean;
  };
};
